//------------------------------------
// #PRODUCT DETAIL
//------------------------------------

.c-product-detail {
    @include mobile-gutter();
    @include rem(margin-bottom, 15px);
}

.c-product-detail__price,
.c-product-detail__stock {
    @include rem(margin-bottom, 15px);
}

.c-product-detail__price {
    @include font-size($font-size-gamma);

    font-weight: bold;
}

.c-product-detail__stock {
    @include font-size($font-size-zeta);

    text-align: right;
    color: $color-brand-secondary;
    color: var(--color-brand-secondary);

    .unavailable {
        color: $color-red;
        color: var(--color-red);
    }
}

.c-product-detail__attributes {
    @include rem(margin-bottom, $base-spacing-unit-small);

    .c-product-attribute {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .c-product-attribute__label {
        @include rem(margin-right, $base-spacing-unit-small);

        flex-shrink: 0;
    }
}

.c-product-detail__table-container {
    @include rem(padding, 36px 0 12px);

    text-align: center;

    h2 {
        @include font-size($font-size-gamma);
    }

    table {
        @include rem(margin-top, 30px);

        table-layout: fixed;

        tr {
            td {
                padding: 0;
                text-align: center;
                border-right: 1px solid $color-ui-grey-4;
                border-right: 1px solid var(--color-ui-grey-4);
                font-weight: bold;

                &:last-of-type {
                    border-right: none;
                }
            }

            &:first-of-type {
                td {
                    font-weight: normal;
                    color: $color-ui-grey-3;
                    color: var(--color-ui-grey-3);
                }
            }
        }
    }

    p {
        @include font-size($font-size-epsilon);
        @include rem(margin-top, 36px);

        a {
            color: $color-blue;
            color: var(--color-blue);
            text-decoration: underline;
        }
    }

    @include mq($from: desktop) {
        @include rem(padding-bottom, 42px);

        table {
            @include rem(margin-top, 42px);
        }

        p {
            @include rem(margin-top, 18px);
        }
    }
}
