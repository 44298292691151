//------------------------------------
// #EMBEDS
//------------------------------------

// Responsively displays iframes, used for 3rd party embeds

.c-embed-container {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%; // Retain 16:9 aspect ratio
    position: relative;

    iframe,
    object,
    embed {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
