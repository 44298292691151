//------------------------------------
// #HELPERS
//------------------------------------

// For full width elements useful to make inline images responsive
.u-expand {
    max-width: 100% !important;
    width: 100% !important;
}

// Layout
// Add/remove floats
.u-float-right {
    float: right !important;
}

.u-float-left {
    float: left !important;
}

.u-float-none {
    float: none !important;
}

// Clearfix
.u-clearfix {
    @include clearfix();
}

.u-clear-right {
    clear: right;
}

.u-clear-left {
    clear: left;
}

// Spacing
// Above
.u-margin-above {
    @include rem(margin-top, spacer(), true);
}

.u-margin-above-1\/3 {
    @include rem(margin-top, spacer(0.3333333333333), true);
}

.u-margin-above-2 {
    @include rem(margin-top, spacer(2), true);
}

// Below
.u-margin-below {
    @include rem(margin-bottom, spacer(), true);
}

.u-margin-below-2 {
    @include rem(margin-bottom, spacer(2), true);
}

// Left
.u-margin-left {
    @include rem(margin-left, spacer(), true);
}

// Right
.u-margin-right {
    @include rem(margin-right, spacer(), true);
}

// Left
.u-padding-left {
    @include rem(padding-left, spacer(), true);
}

.u-padding-left-2 {
    @include rem(padding-left, spacer(2), true);
}

// Right
.u-padding-right {
    @include rem(padding-right, spacer(), true);
}

// Bottom
.u-padding-bottom {
    @include rem(padding-bottom, spacer(), true);
}

// Top
.u-padding-top {
    @include rem(padding-top, spacer(), true);
}

// Styling
.u-display-hide {
    display: none !important;
}

.u-border {
    border: solid 1px $color-border !important;
    border: solid 1px var(--color-border) !important;
}

.u-border-right {
    border-right: solid 1px $color-border !important;
    border-right: solid 1px var(--color-border) !important;
}

.u-border-left {
    border-left: solid 1px $color-border !important;
    border-left: solid 1px var(--color-border) !important;
}

.u-border-top {
    border-top: solid 1px $color-border !important;
    border-top: solid 1px var(color-border) !important;
}

.u-border-bottom {
    border-bottom: solid 1px $color-border !important;
    border-bottom: solid 1px var(color-border) !important;
}

// Align text
.u-left-align-text {
    text-align: left;
}

.u-center-align-text {
    text-align: center;
}

.u-right-align-text {
    text-align: right;
}

// Flex box helpers
.u-flex-grow {
    // sass-lint:disable no-misspelled-properties
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.u-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.u-visually-hidden {
    @include visually-hidden();
}

.u-mobile-gutter {
    @include mobile-gutter();

    // Ensure other gutter helpers aren't applied
    .c-actions__toolbar,
    .c-form--mobile-gutter {
        padding-left: 0;
        padding-right: 0;
    }
}
