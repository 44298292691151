//------------------------------------
// #PAYMENT ICONS
//------------------------------------

.c-payment-method-icons {
    @include rem(padding-top, 20px);
    @include rem(padding-bottom, 20px);
    order: 2;

    @include mq($from: desktop) {
        text-align: right;
        order: 3;
    }
}
