//------------------------------------
// #LINKS
//------------------------------------

.c-link {
    text-decoration: none;
    @include font-weight(bold);
    @include font-size(16px);
    @include link-color($color-ui-grey-2, $color-brand-secondary);

    &:hover,
    &:focus {
        text-decoration: none;
    }

    // .c-link__view-saved
    &__view-saved {
        @include link-color($color-basket-view-saved, $color-basket-view-saved-hover);

        .c-icon--arrow-right-sm {
            width: 15px;
            height: 10px;

            @include rem(margin-left, 4px);
        }
    }
}

.c-link--remove {
    @include rem(font-size, 13px);
    @include link-color($color-remove-link, $color-remove-link-hover);

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
