//------------------------------------
// #CART PRODUCT
//------------------------------------

.c-cart-product {
    @include rem(padding, 15px);

    position: relative;
    border-bottom: 1px solid $color-ui-grey-4;
    border-bottom: 1px solid var(--color-ui-grey-4);

    @include mq($from: desktop) {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
        border-bottom: none;
    }
}

.c-cart-product__title {
    @include font-size($base-font-size);
    @include rem(margin-bottom, 10px);

    font-weight: normal;

    @include mq($from: desktop) {
        @include font-size(16px);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;

        &,
        a {
            color: $color-ui-grey-2;
            color: var(--color-ui-grey-2);
        }
    }
}

.c-cart-product__options {
    @include font-size($font-size-zeta);

    font-weight: normal;
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);

    dl {
        @include rem(margin-bottom, 10px);

        > dt {
            &::after {
                content: ':';
            }
        }

        > dd {
            @include rem(padding-left, 5px);

            margin-left: 0;
        }

        @include mq($until: tablet) {
            display: flex;
            flex-wrap: wrap;

            > dt {
                flex: 0 0 33%;
            }

            > dd {
                flex: 0 0 66%;
            }
        }

        @include mq($from: tablet) {
            > dt,
            > dd {
                display: inline-block;
            }

            > dt ~ dt::before {
                content: '\00a0\00b7\00a0\00a0';
            }
        }
    }
}

.c-cart-product__qty {
    @include font-size($font-size-zeta);
}

.c-cart-product__pricing {
    color: $color-brand-primary;
    color: var(--color-brand-primary);
    font-weight: bold;
    text-align: right;

    @include mq($from: desktop) {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }
}

.c-cart-product__price {
    color: $color-brand-primary;
    color: var(--color-brand-primary);
}

.c-cart-product__actions {
    clear: both;
    @include rem(padding-top, 10px);

    textarea {
        min-height: initial;
    }

    .c-button--remove {
        position: absolute;
        top: 15px;
        right: 0;
        padding: 0;
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
        font-size: 35px;
        font-weight: normal;
        line-height: 1;
        transform: translateY(-5px);
    }
}

.c-cart-product__comment {
    @include rem(margin-bottom, 10px);
}

.c-cart-product--wishlist {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid $color-ui-grey-4;
    border-bottom: 1px solid var(--color-ui-grey-4);

    .c-cart-product__pricing {
        color: $color-black;
        color: var(--color-black);
        text-align: left;
    }

    .o-media__img {
        max-width: 50px;
    }
}

.c-cart-product--compact {
    @include rem(padding-right, 25px);

    border-bottom: none;

    .c-cart-product__title {
        @include font-size(14px);

        white-space: normal;
        text-overflow: initial;
    }

    .c-cart-product__pricing {
        @include font-size(14px);

        text-align: left;
        color: $color-brand-primary;
        color: var(--color-brand-primary);
    }

    .c-cart-product__actions {
        @include rem(padding-left, 61px); // Offset by thumbnail width and margin
    }
}

.c-cart-product--compare {
    @include rem(margin-bottom, 10px);
    @include rem(padding, 25px 0 0);

    .c-cart-product__title {
        @include font-size(14px);

        white-space: normal;
        text-overflow: initial;
    }

    .c-cart-product__pricing {
        text-align: left;
        color: $color-brand-primary;
        color: var(--color-brand-primary);
    }

    .c-cart-product__actions {
        > form {
            display: flex;
            justify-content: space-between;
        }

        .c-product-social-link--wishlist {
            margin: 0 auto;
        }

        .c-button--remove {
            top: 0;
        }
    }
}

.c-cart-product__meta {
    display: flex;
    align-items: center;
}

.c-cart-product__delete {
    color: $color-font;
}
