//------------------------------------
// #QUICKVIEW
//------------------------------------

.fotorama--fullscreen {
    z-index: 1002 !important;
}
.fotorama__stage .fotorama__img {
    width: 100%;
    height: 100%;
}

.c-quick-view--wrapper {
    &.modal-popup .modal-inner-wrap {
        @include mq($from: tablet) {
            @include rem(max-width, 960px);
        }

        .c-pdp__title {
            @include font-size(22px, 1.4);
        }

        .c-product__price {
            @include font-size(22px);
        }
    }

    .c-tocart__actions {
        flex-flow: column;
        align-items: flex-start;

        .c-pdp__basket-btn {
            @include rem(margin-bottom, 15px);

            @include mq($from: tablet) {
                width: 100%;
            }

            @include mq($from: desktop) {
                @include rem(max-width, 300px);
            }
        }
    }

    .action-close {
        outline: 0;
    }

    // Ensure the out of stock form stacks in a modal
    .s-form__stock-notification {
        display: block;

        .c-input-wrap {
            @include rem(margin-bottom, 15px);
        }

        .c-btn--submit {
            @include rem(margin-bottom, 15px);

            min-width: inherit;
            margin-right: 0;
        }
    }
}

// Quickview link
.c-quickview__link {
    text-decoration: none;

    @include rem(font-size, 15px);
    @include link-color($color-ui-grey-2, $color-brand-secondary);

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.c-quickview__icon {
    @include rem(margin-right, 3px);
    @include rem(top, 2px);
    @include rem(width, 16px);
    @include rem(height, 16px);

    position: relative;
}

.c-quick-view--wrapper.modal-popup {
    .action-close:before {
        color: $color-quickview-close-button;
    }

    .modal-inner-wrap {
        @include rem(min-height, 430px);

        overflow: auto;

        .modal-content,
        .c-quick-view--content {
            height: 100%;
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            padding-top: 0 !important; // Override base

            > div {
                // Ampersand Quick View creates it's widget without an element, style the default
                height: 100%;
            }
        }
    }

    .c-quick-view--content {
        float: left;
        width: 100%;
        overflow-y: auto;

        @include mq($from: tablet) {
            overflow-y: initial;
        }

        .c-pdp__main {
            @include mq($from: tablet) {
                @include rem(padding-left, spacer(.5));

                height: 100%;
                overflow-y: auto;
            }
        }

        .c-pdp__title {
            @include rem(font-size, 16px);
        }

        .c-tocart__actions {
            align-items: normal;
            flex-direction: column;
        }

        .c-pdp__basket-btn,
        .c-input-combined__input,
        .c-btn {
            @include rem(margin-bottom, 15px);

            width: 100%;
        }

        .c-pdp__media {
            @include rem(margin-bottom, spacer());

            @include mq($from: tablet) {
                @include rem(padding-right, spacer(.5));

                margin-bottom: 0;

                &,
                .fotorama,
                .fotorama__wrap,
                .o-media
                 {
                    height: 100%;
                }
            }

            min-height: 1px; // Force layout to take up defined width when no content is present

            .c-product-badge__wrapper {
                top: 0;
                left: 0;
            }
        }

        .c-qty__recommended {
            display: flex;
            align-items: center;
            line-height: 1.2;

            .c-icon {
                flex-shrink: 0;
            }

            .c-tooltip {
                @include rem(margin-right, 5px);

                margin-left: 0;
            }
        }
    }
}
