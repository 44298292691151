//------------------------------------
// #BASKET EXTEND
//------------------------------------

.c-basket__header {
    @include mq($from: desktop) {
        @include rem(margin-bottom, 25px);
    }
}

.c-basket__header-cell {
    @include font-size(18px);

    color: $color-black;

    &:last-child {
        padding-right: 0;
    }
}

.c-basket__products {
    .c-basket__product {
        @include rem(padding, 25px 0 40px);
        border-bottom: 1px solid $color-basket-border;
    }
}

.c-basket__product-details {
    display: flex;

    .c-basket__product-thumbnail {
        @include rem(margin-right, 20px);

        display: inline-block;

        @include mq($until: desktop) {
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.c-basket__product-content {
    line-height: 1;

    @include mq($until: desktop) {
        .c-basket__product-name,
        .c-basket__product-name--link {
            @include font-size(11px, 1);
        }
    }

    .c-basket__product-name {
        color: $color-black;

        &--link {
            @extend .c-link;
            @include link-color($color-black, $color-brand-secondary);
        }
    }

    .c-basket__product-sku {
        @include rem(margin-bottom, 5px);

        display: inline-block;

        @include mq($until: desktop) {
            @include rem(font-size, 11px);

            .c-pdp__stock span {
                @include rem(font-size, 11px);
            }
        }
    }

    .c-basket__product-flag p {
        margin: 0;
    }
}

.c-basket__product-controls {
    .c-wishlist__link {
        @include rem(margin-top, 5px);
        @include rem(font-size, 12px);
        @include font-weight(semi-bold);

        .c-wishlist__icon {
            @include rem(margin-right, 10px);

            width: 19px;
            height: 19px;
        }

        @include mq($until: desktop) {
            span {
                display: inline-block;
            }
        }
    }
}

.c-basket__product-price,
.c-basket__product-subtotal {
    @include font-size(18px);
    @include font-weight(semi-bold);

    text-align: right;
    color: $color-black;
    font-family: $font-family-default;

    .price-including-tax::after {
        content: " " attr(data-label);
        left: 0;
        text-align: right;
        text-transform: uppercase;

        @include rem(font-size, 11px);

        @include mq($from: tablet) {
            content: none;
        }
    }
}

.c-basket__product-price {
    @include mq($until: tablet) {
        line-height: 1;
    }
}

.c-basket__product-subtotal {
    text-align: right;
}

.c-basket__product-image {
    flex-shrink: 0;
}

.c-basket__product-qty {
    .c-qty__control,
    .c-qty__actions-toolbar {
        display: inline-flex;
        vertical-align: top;

        .c-qty__input {
            @include font-weight(bold);
        }

        .c-qty__button,
        .c-qty__input {
            width: 30px;
            height: 30px;
            text-align: center;
            padding: 0;
            border: 0;

            @include mq($until: desktop) {
                width: 25px;
                height: 25px;
            }
        }

        .c-qty__button {
            @include font-size(14px);

            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-white;

            @include mq($from: desktop) {
                @include font-size(20px, 1);
            }

            &:disabled {
                opacity: .5;

                .c-qty__button-icon {
                    cursor: not-allowed;
                }
            }
        }

        .c-qty__button-icon {
            @include rem(width, 30px);
            @include rem(height, 30px);

            display: block;
            cursor: pointer;
        }

        .c-cart__action-delete {
            @include rem(font-size, 12px);
            @include rem(margin-left, 10px);
            @include font-weight(semi-bold);
            @include link-color($color-basket-delete-link, $color-basket-delete-link-hover);

            text-decoration: underline;

            @include mq($until: desktop) {
                @include rem(margin-right, 20px);

                margin-left: 0;
            }
        }
    }

    .c-qty__actions-toolbar {
        height: 30px;
        align-items: center;

        @include mq($until: desktop) {
            float: left;
            height: 25px;

            span {
                display: inline-block;
            }
        }
    }
}

.c-cart__header {
    @include rem(margin-bottom, 20px);

    @include mq($from: desktop) {
        margin-bottom: 0;
    }

    h1 {
        @include rem(font-size, 18px);
        @include rem(margin-bottom, 5px);

        @include mq($from: desktop) {
            @include rem(font-size, 24px);
        }
    }

    p {
        margin-bottom: 0;
    }
}

.c-basket__sub-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    @include mq($from: tablet) {
        text-align: right;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .c-link__continue-shopping {
        @include rem(font-size, 14px);
        @include link-color($color-black, $color-brand-secondary);

        width: 100%;
        order: 3;
        display: none;

        @include mq($from: desktop) {
            @include rem(margin-right, 20px);

            order: 1;
            width: auto;
            display: block;
        }

        .c-icon {
            height: 11px;
            transform: rotate(180deg);
        }
    }

    .c-payment-method-icons {
        display: none;

        @include mq($from: tablet) {
            display: block;
        }
    }

    .c-button--checkout,
    .c-btn--checkout {
        @include rem(padding-top, 9px);
        @include rem(padding-bottom, 9px);
        @include rem(font-size, 12px);

        width: 100%;
        order: 2;

        @include mq($from: tablet) {
            width: auto;
        }

        @include mq($from: desktop) {
            @include rem(padding-top, 15px);
            @include rem(padding-bottom, 15px);
            @include rem(font-size, 18px);
        }
    }

    &--bottom {
        .c-link__continue-shopping {
            display: block;
        }

        .c-payment-method-icons {
            display: block;
            text-align: center;

            @include mq($from: tablet) {
                text-align: right;
            }
        }
    }
}

.c-basket__totals-container {
    @include rem(padding-top, 15px);

    .block.shipping.active .title {
        display: none;
    }

    .cart-totals {
        float: right;

        .table-caption {
            display: none;
        }

        tbody tr {
            border-bottom: none;
        }

        tbody th,
        tbody td {
            text-align: right;
            color: black;
            width: 30%;

            @include font-weight(normal);
            @include rem(padding-left, 20px);
            @include rem(font-size, 13px);

            @include mq($from: tablet) {
                @include font-size(18px);
                @include rem(padding, 5px 0 5px 40px);

                width: auto;
            }

            &.c-checkout__delivery-message {
                @include font-size(12px, 1.4);

                width: 300px;
                padding: 0;
                color: $color-body;
                text-align: right;
            }
        }

        tr.grand.totals.excl,
        tr.totals-tax {
            display: none;
        }

        .c-totals__shipping {
            .label {
                display: none;
            }
        }

        .c-totals__discount {
            th, td {
                @include rem(padding-bottom, 15px);
            }
        }

        .c-totals__grand-totals {
            border-top: none;

            @include mq($from: tablet) {
                border-top: 1px solid $color-basket-border;
            }

            th, td {
                @include rem(padding-top, 15px);
            }

            .c-totals__grand-totals__title {
                @include rem(font-size, 24px);
                @include rem(padding-top, 25px);

                @include mq($until: desktop) {
                    @include rem(font-size, 14px);
                }
            }

            .c-totals__grand-totals__amount {
                @include rem(font-size, 13px);
                @include rem(padding-top, 12px);

                vertical-align: bottom;

                @include mq($from: tablet) {
                    @include rem(font-size, 30px);
                }
            }
        }
    }

    .c-cart-summary__shipping {
        padding-bottom: 0;
    }

    .c-basket__shipping-estimate {
        display: none;
    }

    .c-cart-summary__totals {
        .c-totals__grand-totals__title,
        .c-totals__grand-totals__amount {
            border-top: none;

            @include mq($from: tablet) {
                border-top: 1px solid $color-basket-border;
            }
        }

        .totals.grand > td {
            @include rem(padding-left, 40px);
        }
    }
}

.c-basket__shipping-rate-form {
    @include rem(margin-bottom, 10px);

    .item-title {
        display: none;
    }

    .c-basket__shipping-methods {
        text-align: right;
        margin: 0;
    }

    .c-basket__shipping-options {
        @include rem(margin, 0 0 $spacing-unit);

        background-color: $color-radio-background;

        .field.choice.item {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .c-basket__shipping-method-label {
        @include rem(padding, 5px 10px);
        flex-grow: 1;

        .c-price__shipping {
            display: none;
        }
    }

    .c-basket__shipping-method-radio {
        &:checked + label {
            @include font-weight(bold);
        }
    }
}

.c-basket__global-actions {
    @include rem(padding-bottom, 24px);

    border-bottom: 1px solid $color-basket-border;

    .c-wishlist__save-all span {
        display: block;
    }
}

.c-basket__content-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.c-basket-promotion__container {
    @include rem(margin-bottom, 20px);

    order: 1;

    .c-basket-promotion__link {
        display: block;
    }

    .c-basket-promotion__image {
        width: 100%;
        height: auto;
        max-width: none;
    }

    @include mq($from: tablet) {
        @include rem(margin-top, 20px);

        order: 2;
    }
}

.c-basket__quick-add {
    order: 2;

    @include mq($from: tablet) {
        order: 1;
    }
}

.c-basket__form {
    position: relative;
}

.c-basket__mobile {
    border-bottom: 1px solid $color-basket-border;

    .data.table.totals {
        margin: 0;
    }

    .totals.sub,
    .totals-tax,
    .c-totals__grand-totals.excl,
    .c-checkout__delivery-types-message__wrapper {
        display: none;
    }

    .c-totals__grand-totals.grand.totals.incl {
        @include rem(font-size, 15px);
    }

    .c-totals__grand-totals__title {
        text-align: right;
    }

    .c-cart-summary__totals .totals.grand > td,
    .c-cart-summary__totals .totals.grand > th {
        width: 30%;
        @include rem(padding, 10px 0);
    }

    @include mq($from: tablet) {
        display: none;
    }
}
