//------------------------------------
// #INPUT
//------------------------------------

.c-form__control {
    @include rem(padding, 10px 14px);

    width: 100%;
    border: $color-input-border 2px solid;
    border: var(--color-input-border) 2px solid;

    &:focus {
        border-color: $color-input-focus;
        border-color: var(--color-input-focus);
    }

    &.mage-error {
        border-color: $color-input-validation-error !important;
        border-color: var(--color-input-validation-error) !important;
    }
}

.c-input-wrap__label {
    @include rem(margin, 0 0 5px);
    @include rem(padding-left, 7px);
    
    display: inline-block;
}

.c-input-wrap {
    @include rem(margin, 0 0 $spacing-unit);
    
    position: relative;
}

.c-input-wrap--no-margin {
    margin: 0;
}

// Right aligned Icon
.c-input-wrap--icon-right {
    .c-input-wrap__icon {
        right: 0;
        left: inherit;
    }

    .c-form__control {
        @include rem(padding-left, 10px);
        @include rem(padding-right, 38px);
    }
}

.c-input-wrap__icon {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 38px;
    width: 38px;
    background-size: 20px;
}

.c-input--with-icon {
    @include rem(padding-left, 38px);
    
    right: 0;
}

.c-input--thin-border {
    border-width: 1px;
}

.c-input--centred {
    text-align: center;
}

// Error messages that appear under invalid inputs
div.mage-error {
    font-weight: normal;
    color: $color-validation-error;
    color: var(--color-validation-error);
}

.c-input-wrap--group ._error {
    height: 0;
}

.c-input-wrap--group ._error + .c-input-wrap .c-form__control {
    border-color: $color-input-validation-error !important;
    border-color: var(--color-input-validation-error) !important;
}

._error {
    .c-form__control {
        border-color: $color-alert-error !important;
        border-color: var(--color-alert-error) !important;
    }
}

._success {
    .c-form__control {
        border-color: $color-alert-success !important;
        border-color: var(--color-alert-success) !important;
    }
}
