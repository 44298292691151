//------------------------------------
// #CHECKOUT CLICK AND COLLECT
//------------------------------------

.c-checkout__click-and-collect {
    &__map {
        height: 240px;
        width: 100%;
        @include rem(margin-bottom, 15px);
        display: none;

        @include mq($from: tablet) {
            height: 520px;
            display: block;
        }
    }

    &__location {
        overflow: hidden;
        @include rem(margin-bottom, 35px);

        &__title {
            @extend .c-checkout__step__heading;

            margin-bottom: 0;
        }

        .u-go--back {
            text-decoration: underline;
            @include rem(margin-bottom, 10px);
            display: inline-block;
        }

        &__address {
            @include font-size(15px);
            font-family: $font-family-secondary;
            @include rem(margin-bottom, 15px);
        }

        .c-btn {
            width: 100%;
            min-width: auto;
        }

        address {
            font-style: normal;
            margin-bottom: 0;
        }
    }

    &__opening-hours {
        @include rem(margin, 18px 0 30px);

        &__title {
            @include rem(margin-bottom, 5px);
        }
    }

    @include mq($until: tablet) {
        @include rem(margin-top, -10px);
    }
}

// Always display map when store is selected
.c-checkout__step__collection--selected-store .c-checkout__click-and-collect__map {
    display: block;
}
