//------------------------------------
// #MENU DRAWER
//------------------------------------

.s-menu-drawer {
    .c-drawer__head {
        color: $color-black;
        position: relative;
        z-index: zIndex('mobile-menu-header');

        li {
            @extend .o-list-inline__item;
        }

        .c-drawer__head-inner {
            @include rem(padding, 0 15px);
        }

        .c-drawer__head-links {
            @include rem(margin-bottom, 10px);
        }
    }

    .c-drawer__close {
        text-align: right;
        height: 100%;

        .c-icon {
            @include rem(margin-left, 5px);
            @include rem(height, 11px);
            @include rem(width, 11px);
            @include rem(top, 1px);

            position: relative;
        }
    }

    .c-drawer__inner {
        padding-top: 0;
    }

    .c-drawer__links > ul {
        flex-direction: column;
    }
}
