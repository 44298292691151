//------------------------------------
// #FACETS
//------------------------------------

.c-facets {
    @include rem(margin-top, 18px);

    display: block;

    .ui-slider-handle {
        background-color: $color-blue;
        background-color: var(--color-blue);
    }

    .c-slider {
        max-width: calc(100% - 32px);
    }
}

.js-facet-toggle-content {
    display: none;

    &.active {
        display: block;
    }

    @include mq($from: tablet) {
        display: block;
    }
}

.c-facets__heading {
    @include font-size($font-size-delta);
    @include rem(margin-bottom, 35px);

    @include mq($until: 'tablet') {
        @include rem(padding, 0 15px);
    }
}

.c-facets__block {
    user-select: none; // Prevent selection of whole block when aggressively expanding

    & + .c-facets__block {
        @include rem(margin-top, 20px);
    }

    ol, ul {
        @include rem(padding-left, 15px);
        @include rem(padding-right, 15px);

        list-style: none;
        margin: 0;
    }

    @include mq($from: desktop) {
        & + .c-facets__block {
            @include rem(margin-top, 60px);
        }
    }
}

.c-facets__title {
    @include font-size($font-size-delta);
    @include rem(padding, 15px);
    @include rem(margin-bottom, 22px);

    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-ui-grey-3;
    border-bottom: 1px solid var(--color-ui-grey-3);
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);

    // .c-facets__title-icon
    &-icon {
        @include rem(width, 22px);
        @include rem(height, 22px);
        @include rem(background-size, 22px);

        margin-left: auto;
        background-image: url(../images/icons/svg/plus.svg);
        background-repeat: no-repeat;
    }
}

.c-facets__block.active .c-facets__title .c-facets__title-icon {
    background-image: url(../images/icons/svg/minus.svg);
}

// Flip the icons for React based facets as this results in less conditional rendering
.c-facets__block--react {
    .c-facets__title-icon {
        .c-icon--minus {
            display: block;
        }

        .c-icon--plus {
            display: none;
        }
    }

    &.c-facets__block--hidden {
        .c-facets__body {
            display: none;
        }

        .c-facets__title-icon {
            .c-icon--minus {
                display: none;
            }
    
            .c-icon--plus {
                display: block;
            }
        }
    }
}

.c-facets__link {
    color: $color-ui-grey-1;
    color: var(--color-ui-grey-1);

    &:visited,
    &:active {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
    }

    .c-facets__link-icon {
        @include rem(font-size, $font-size-delta);
        @include rem(margin-right, 5px);

        line-height: 1;
        display: inline-block;
        font-weight: bold;
    }
}

.c-facets__count {
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);
}

.c-facets__group {
    .filter-current {
        h3 {
            @include rem(padding, 0 15px);

            font-size: $font-size-delta;
        }

        ol {
            @include rem(padding-left, 15px);

            margin-left: 0;
            list-style: none;

            li {
                a {
                    display: inline-block;
                    line-height: 1;
                    font-size: $font-size-beta;
                    transform: translateY(4px);

                    @include hocus() {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .filter-actions {
        @include rem(padding, 0 15px);
        @include rem(margin-bottom, 35px);

        a {
            color: $color-blue;
            color: var(--color-blue);
        }
    }
}

.c-facet {
    @include rem(padding, 2px 0);

    display: flex;
    align-items: center;

    input {
        @include rem(margin-right, 8px);

        flex-shrink: 0;
    }

    &,
    &:visited,
    &:active {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
    }

    @include hocus() {
        text-decoration: none;
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }

    .count {
        @include rem(margin-left, 5px);

        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }
}

.c-facet__actions {
    @include rem(padding, 15px);

    a {
        cursor: pointer;
    }
}

.c-facet__toggle {
    @include rem(padding, 7px 10px);
}
