//------------------------------------
// #FORMS
//------------------------------------

// Basic form styles

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    // min-width is applied to override a Chrome user-agent style o
    // "min-width: -webkit-min-content;" which makes it stretch to the width of
    // its contents instead of staying within the parent width.
    min-width: 0;
}

legend {
    @include font-weight(bold);
    @include rem(margin-bottom, 12px);
}

label {
    display: block;
}

input,
select,
textarea {
    @include rem(padding, $base-spacing-unit-small/2 $base-spacing-unit-small);
    
    background: $color-white;
    background: var(--color-white);
    border: 2px solid $color-ui-grey-5;
    border: 2px solid var(--color-ui-grey-5);
    border-radius: $base-border-radius;
    display: block;
    width: 100%;

    &:focus {
        border: 2px solid $color-input-focus;
        border: 2px solid var(--color-input-focus);
        outline: none;
    }
}

input {
    &[type=submit],
    &[type=reset],
    &[type=button],
    &[type=checkbox],
    &[type=radio] {
        display: inline;
        width: auto;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[disabled] {
        background: $color-input-disabled;
        background: var(--color-input-disabled);
        cursor: not-allowed;
    }
}

textarea {
    min-height: 20ch;
    resize: vertical; // Not both, or horizontal as they’ll overflow the containers
}

select {
    appearance: none;

    &[disabled] {
        background: $color-input-disabled;
        background: var(--color-input-disabled);
        cursor: not-allowed;
    }
}
