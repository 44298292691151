//------------------------------------
// #REACT SEARCH
//------------------------------------

.c-search--react {
    @include mq($until: tablet) {
        &.c-search--is-open {
            @include rem(top, 60px);

            display: block;
            position: absolute;
            left: 0;
            right: 0;

            .c-form__group--combined {
                border-left: 0;
                border-right: 0;
            }
        }
    }
}

.c-search__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
