//------------------------------------
// #POST
//------------------------------------

// Wrap in template retriever class to override WordPress base theme
.c-template-retriever {
    .c-post__meta,
    .c-post__footer {
        background-color: $color-brand-secondary;
    }

    .c-post__meta {
        background-color: $color-brand-primary;
    }
}
