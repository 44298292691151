//------------------------------------
// #PAGINATION
//------------------------------------

// Wrap in template retriever class to override WordPress base theme
.c-template-retriever {
    .c-pagination {
        .next,
        .prev {
            color: rgba(0, 0, 0, 0); // Ensure we don't display button text from base theme (prevents template override)

            &,
            &::before {
                display: inline-block;
                width: 40px;
                height: 40px;
            }

            &::before {
                content: ' ';
                display: inline-block;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 16px;
            }

            @include mq($from: desktop) {
                background-color: $color-off-white;
            }
        }

        .prev {
            @include rem(margin-right, 8px);

            &::before {
                // Extend here to prevent unneeded template override within WordPress
                @extend .c-icon--arrow-left-sm;
            }
        }

        .next {
            @include rem(margin-left, 8px);

            &::before {
                // Extend here to prevent unneeded template override within WordPress
                @extend .c-icon--arrow-right-sm;
            }
        }

        .current {
            background-color: $color-brand-primary;
        }
    }
}
