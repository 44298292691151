//------------------------------------
// #STORES
//------------------------------------

.c-stores {
    .c-individual-store {
        padding: 30px 0;
        border-top: 1px solid $color-store-border;
    }
    
    .c-store__content-wrapper {
        @include rem(margin-bottom, 20px);

        @include mq($from: desktop) {
            margin: 0;
        }
    }

    .c-store__title {
        margin: 0;

        @include mq($from: desktop) {
            @include rem(margin-bottom, 10px);
        }
    }

    .c-store__content {
        margin: 0;
    }

    address {
        font-style: normal;
    }

    .c-store__map {
        height: 200px;

        @include rem(margin-top, 20px);

        @include mq($from: tablet) {
            height: 350px;
        }

        @include mq($from: desktop) {
            margin-top: 0;
        }
    }
}
