//------------------------------------
// #HEADER
//------------------------------------

.c-header {
    .c-search {
        @include font-size($font-size-delta);

        width: 100%;
        position: relative;

        .c-form__control {
            border-radius: 0;

            &:focus {
                border: none;
            }
        }

        .c-form__group {
            margin-bottom: 0;
        }

        .c-button--search {
            border-width: 1px;
            transition: unset;
            background-color: $color-white;
            background-color: var(--color-white);
            margin-left: 0;

            svg {
                @include rem(margin-top, 3px);
                @include rem(height, 20px);
                @include rem(width, 20px);

                fill: $color-brand-primary;
                fill: var(--color-brand-primary);
            }
        }
    }

    @include mq($until: tablet) {
        @include rem(padding, 12px 10px);

        .o-region__inner {
            width: 100%;
        }

        .c-search {
            display: none;

            .c-button--search {
                svg {
                    @include rem(margin-top, 5px);
                }
            }
        }

        &.c-search--is-open {
            @include rem(margin-bottom, 48px);

            .c-search {
                @include rem(margin-top, 53px);

                display: block;
                position: absolute;
                left: 0;
                right: 0;

                .c-form__group--combined {
                    border-left: 0;
                    border-right: 0;
                }
            }
        }
    }

    @include mq($until: desktop) {
        border-bottom: 1px solid $color-ui-grey-3;
        border-bottom: 1px solid var(--color-ui-grey-3);

        &.js-is-sticky {
            position: fixed;
            top: 0;
            width: 100%;
            background-color: $color-white;
            background-color: var(--color-white);
            z-index: zIndex('sticky-header');
        }
    }
}

.c-header__main {
    display: flex;
    align-items: center;

    @include mq($from: tablet) {
        @include rem(margin-bottom, 25px);
    }
}

.c-header__minicart,
.c-header__search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.c-header__logo {
    @include rem(height, 50px);

    @include mq($from: desktop) {
        @include rem(height, 80px);
    }

    display: flex;
    align-items: center;

    img {
        display: block;
        max-height: 100%;
        max-width: 100%;
    }
}

.c-header__icons {
    display: flex;
    justify-content: flex-end;
}

.c-search__toggle {
    @include rem(width, 35px);
    @include rem(height, 35px);

    padding: 0;
    fill: $color-brand-primary;
    fill: var(--color-brand-primary);
}
