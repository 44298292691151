//------------------------------------
// #CHECKOUT
//------------------------------------

.page-layout-checkout .c-header {
    display: none;
}

// Checkout header
.c-checkout__header {
    @include rem(padding, 12px 0);

    border-bottom: 1px solid $color-ui-grey-4;
    border-bottom: 1px solid var(--color-ui-grey-4);

    @include mq($from: desktop) {
        @include rem(padding, 40px 0);
    }
}

.c-checkout__header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c-checkout__sidebar {
    @include mq($from: desktop) {
        @include rem(margin-top, 57px);
        @include rem(margin-bottom, 30px);
    }
}

.c-checkout__step-title {
    @include rem(font-size, $font-size-delta);
    @include mobile-gutter();

    font-weight: normal;

    @include mq($from: tablet) {
        @include rem(font-size, $font-size-beta);
    }
}

// Checkout estimation
.c-checkout-estimation {
    @include mobile-gutter();
    @include rem(padding-top, 20px);
    @include rem(padding-bottom, 20px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);
    cursor: pointer;

    @include mq($from: tablet) {
        @include rem(padding-left, 35px);
        @include rem(padding-right, 35px);
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-checkout-estimation__title,
.c-checkout-estimation__total {
    @include font-size($font-size-delta);
}

.c-checkout-estimation__title {
    @include rem(margin-bottom, 10px);

    font-weight: normal;
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);
}

.c-checkout-estimation__total {
    color: $color-brand-primary;
    color: var(--color-brand-primary);
    font-weight: bold;
}

.c-checkout-estimation__quantity {
    @include rem(width, 45px);
    @include rem(height, 45px);
    @include rem(line-height, 45px);

    background-color: $color-brand-primary;
    background-color: var(--color-brand-primary);
    color: $color-white;
    color: var(--color-white);
    border-radius: 50%;
    text-align: center;
    font-size: $font-size-epsilon;
    position: relative;

    &::after {
        @include rem(background-size, 45px);
        @include rem(height, 45px);
        @include rem(width, 45px);
        @include rem(left, -50px);

        content: ' ';
        background-image: url('../images/icons/svg/shopping-bag.svg');
        display: block;
        position: absolute;
        top: 0;
    }
}

// Checkout summary
@include mq($until: desktop) {
    .c-summary {
        display: flex;
        flex-direction: column;
        transition: transform .5s ease;
        transform: translate3d(100%, 0, 0);
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 80%;
        z-index: zIndex('checkout-summary');

        &.is-active {
            transform: translate3d(0, 0, 0);
        }
    }

    .c-summary__overlay {
        background: $color-black;
        background: var(--color-black);
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        z-index: zIndex('checkout-summary') - 1;
        transition: opacity .5s ease;
    }

    .c-summary.is-active + .c-summary__overlay {
        pointer-events: auto;
        opacity: .5;
    }
}

.c-summary {
    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);

    @include mq($from: tablet) {
        width: 45%;
    }

    @include mq($from: desktop) {
        width: 100%;
    }
}

.c-summary__header {
    @include rem(padding, 20px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.c-summary__basket-count {
    flex-basis: 100%;
}

.c-summary__close {
    @include rem(height, 20px);
    @include rem(width, 20px);

    background-color: transparent;
    border: none;
    line-height: 1;
    color: $color-white;
    color: var(--color-white);
    padding: 0;

    .c-icon {
        width: 100%;
        height: 100%;

        float: left; // Fix button focus highlight stretching beyond button height
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-summary__title {
    @include rem(margin-bottom, 5px);
}

.c-summary__items {
    background-color: $color-white;
    background-color: var(--color-white);
    border-top: 1px solid $color-ui-grey-2;
    border-top: 1px solid var(--color-ui-grey-2);
    border-bottom: 1px solid $color-ui-grey-2;
    border-bottom: 1px solid var(--color-ui-grey-2);

    @include mq($until: desktop) {
        overflow-y: auto;
        flex: 1 1 0%;
    }

    .o-list-stacked {
        margin-bottom: 0;
    }
}

.c-summary__product-details {
    position: relative;

    .subtotal {
        position: absolute;
        right: 0;
    }
}

.c-summary__totals {
    @include rem(padding, 20px);

    width: auto;
    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);

    table {
        margin-bottom: 0;

        > tbody > tr > th {
            font-weight: normal;
        }

        .amount {
            text-align: right;
            vertical-align: middle;
        }
    }

    .c-button {
        line-height: inherit;
    }
}

// Checkout payment
.c-checkout__step-content {
    @include rem(padding, 15px);

    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);

    .c-button {
        @include rem(margin, 12px 0);

        width: 100%;
    }

    @include mq($from: tablet) {
        @include rem(padding, 35px);

        .c-button {
            width: auto;
        }
    }
}

.c-checkout__payment-method {
    @include rem(padding, 30px);
    @include rem(margin-bottom, 15px);

    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);

    &-content {
        display: none;

        .c-checkout__messages {
            @include rem(margin-bottom, 24px);

            width: auto;
        }
    }

    &--active {
        .c-checkout__payment-method-content {
            @include rem(padding-top, 30px);

            display: block;
        }
    }
}

.c-checkout__billing-address {
    @include rem(margin-bottom, 36px);
}

.c-checkout__billing-address-select {
    @include rem(margin-bottom, 24px);
}

// Checkout shipping
.c-checkout__shipping-information {
    @include rem(padding, 20px);

    &-title {
        @include font-size(16px);
        @include rem(margin-bottom, 10px);
    }

    &-content {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }

    @include mq($until: desktop) {
        display: none;
    }

    @include mq($from: tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-checkout__ship-to,
.c-checkout__ship-via {
    position: relative;
}

.c-checkout__shipping-information-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .c-button {
        line-height: 1;
        padding: 0;
    }

    .c-icon {
        @include rem(width, 20px);
        @include rem(height, 20px);
    }
}

.c-checkout__ship-via {
    @include rem(margin-top, 45px);
}

// Checkout progress
.c-checkout-progress {
    @include rem(margin-bottom, $base-spacing-unit);
    @include mobile-gutter();

    display: flex;
    position: relative;
    justify-content: space-between;
}

.c-checkout-progress__item {
    display: flex;
    position: relative;
    text-align: center;
    flex-flow: column;
    align-items: center;
    z-index: zIndex('progress-item');

    &.c-checkout-progress__item--active .c-checkout-progress__item-number {
        background-color: $color-brand-secondary;
        background-color: var(--color-brand-secondary);
    }

    &.c-checkout-progress__item--complete .c-checkout-progress__item-number {
        background-color: $color-brand-primary;
        background-color: var(--color-brand-primary);
    }
}

.c-checkout-progress__item-number {
    @include rem(height, 20px);
    @include rem(width, 20px);
    @include rem(margin, 0 0 $base-spacing-unit-small / 2);
    @include rem(font-size, 10px);

    background-color: $color-ui-grey-4;
    background-color: var(--color-ui-grey-4);
    border-radius: 50%;
    color: $color-white;
    color: var(--color-white);
    display: block;
    position: relative;
    user-select: none;
    line-height: 2;

    @include mq($from: tablet) {
        @include rem(height, 30px);
        @include rem(width, 30px);
        @include rem(font-size, $font-size-epsilon);

        line-height: 2.2;
    }
}

.c-checkout-progress__item-text {
    @include font-size(11px);
    @include rem(margin-bottom, 10px);

    font-weight: bold;
    opacity: .4;
    text-transform: none;

    @include mq($from: tablet) {
        @include font-size(13px);
    }
}

.c-checkout-progress__item--active,
.c-checkout-progress__item--complete {
    .c-checkout-progress__item-number,
    .c-checkout-progress__item-text {
        font-weight: bold;
        opacity: 1;
    }
}

.c-checkout-progress__item--complete {
    cursor: pointer;
}

// Shipping address items
.c-shipping-address-item {
    @include rem(padding, 10px);

    position: relative;
}

.c-shipping-address-item__overlay {
    @include rem(border-radius, 5px);

    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
        border-color: $color-input-border;
        border-color: var(--color-input-border);
    }

    .c-shipping-address-item__edit-button {
        @include rem(border-bottom-right-radius, 4px);
        @include rem(border-bottom-left-radius, 4px);

        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;

        @include mq($from: tablet) {
            @include rem(border-bottom-right-radius, 4px);
            @include rem(border-bottom-left-radius, 0);
        }
    }
}

.c-shipping-address-item--selected .c-shipping-address-item__overlay {
    border-color: $color-input-border;
    border-color: var(--color-input-border);
    background-color: rgba($color-white, .5);
    background-color: rgba(var(--color-white), .5);
}

.c-shipping-address-item__spacer {
    @include rem(padding-top, 30px);

    width: 100%;
    display: inline-block;

    @include mq($from: tablet) {
        padding-top: 0;
    }
}

.c-shipping-address-item__icon {
    @include rem(width, 50px);
    @include rem(height, 50px);

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.c-checkout__success {
    @include mobile-gutter();
}

// Checkout misc
.c-checkout__signin {
    > .c-button {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
        display: flex;
        align-items: center;
        padding-right: 0;

        .c-icon {
            @include rem(width, 35px);
            @include rem(height, 35px);
            @include rem(margin-right, 5px);
        }
    }
}

.c-checkout__signin-wrapper {
    z-index: zIndex('checkout-signin');
}

.checkout-index-index {
    .c-minicart__button,
    .c-minicart__footer {
        visibility: hidden;
    }
}

.checkout-messages-wrapper .messages {
    width: 100%;

    @include mq($from: desktop) {
        .message {
            margin-bottom: 0;
        }
    }
}
