//------------------------------------
// #WIDTHS
//------------------------------------

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include widths(3 4, -sm);
@mixin widths($widths-columns, $widths-breakpoint: null) {
    // Loop through the number of columns for each denominator of our fractions.
    @each $widths-denominator in $widths-columns {
        // Begin creating a numberator for our fraction up until we hit the
        // denominator.
        @for $widths-numerator from 1 through $widths-denominator {
            // Build a class in the format `.u-3/4` or `.u-3-of-4`.
            .u-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint},
            .u-#{$widths-numerator}-of-#{$widths-denominator}#{$widths-breakpoint} {
                // sass-lint:disable no-important
                width: decimal-round(($widths-numerator / $widths-denominator) * 100%, 4) !important;
            }

            // Add ability to "nudge" column layouts
            // Build a class in the format `.u-nudge-right-3`.
            .u-nudge-right-#{$widths-numerator}#{$widths-breakpoint} {
                // sass-lint:disable no-important
                margin-left: ($widths-numerator / $widths-denominator) * 100% !important;
            }

            // Build a class in the format `.u-nudge-left-3`.
            .u-nudge-left-#{$widths-numerator}#{$widths-breakpoint} {
                // sass-lint:disable no-important
                margin-right: ($widths-numerator / $widths-denominator) * 100% !important;
            }
        }
    }
}

// A series of width helper classes that you can use to size things like grid
// systems. Classes can take a fraction-like format (e.g. `.u-2/3`) or a spoken-
// word format (e.g. `.u-2-of-3`). Use these in your markup:
//
// <div class="u-7/12">
@include widths($base-column-count);

@each $alias, $value in $mq-breakpoints {
    @include mq($from: $value) {
        @include widths($base-column-count, \@#{$alias});
        @include widths($base-column-count, --from-#{$alias});
    }
}
