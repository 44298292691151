//------------------------------------
// #WIDGET
//------------------------------------

.c-template-retriever {
    .c-widget__title {
        @include rem(font-size, 17px);
        @include rem(margin-bottom, 7px);
    }

    .c-widget.widget_categories {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            &.children {
                @include rem(margin-left, 20px);
            }
        }

        a {
            @include rem(font-size, $font-size-zeta);
            @include link-color($color-ui-grey-2, $color-brand-secondary);

            text-decoration: none;
            font-family: $font-family-secondary;

            &::before {
                @extend .c-icon--chevron-right;
                @include rem(margin-right, 5px);

                content: '';
                display: inline-block;
                max-width: 100%;
            }
        }
    }
}
