//------------------------------------
// #STAR RATING
//------------------------------------

.c-star-rating {
    display: flex;
    align-items: center;
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);

    .c-star-rating__background {
        color: $color-star-unselected;
        color: var(--color-star-unselected);
        position: relative;
        width: calc(5em + .8em); // Width of 5 stars including the letter-spacing
        letter-spacing: .2em;
    }

    .c-star-rating__foreground {
        color: $color-star-selected;
        color: var(--color-star-selected);
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    .c-star-rating__background::before,
    .c-star-rating__foreground::before {
        content: '\2605\2605\2605\2605\2605';
    }

    .c-star-rating__review-count {
        @include font-size($font-size-zeta);
        @include rem(margin-left, 10px);
    }
}

.c-star-select {
    position: relative;
    font-size: 30px;
    letter-spacing: .2em;
    height: 30px;
    line-height: 1;
    color: $color-star-unselected;
    color: var(--color-star-unselected);

    input {
        display: none;
    }

    &::before {
        position: absolute;
        content: '\2605\2605\2605\2605\2605';
    }

    .c-star__rating--1,
    .c-star__rating--2,
    .c-star__rating--3,
    .c-star__rating--4,
    .c-star__rating--5 {
        position: absolute;
        left: 0;
        z-index: 1;

        span {
            @extend .u-visually-hidden;
        }

        &:checked {
            background-color: $color-red;
            background-color: var(--color-red);
        }
    }

    .c-star__rating--1 {
        z-index: 6;
    }

    .c-star__rating--2 {
        z-index: 5;
    }

    .c-star__rating--3 {
        z-index: 4;
    }

    .c-star__rating--4 {
        z-index: 3;
    }

    .c-star__rating--5 {
        z-index: 2;
    }

    .c-star__rating--1::before {
        content: '\2605';
    }

    .c-star__rating--2::before {
        content: '\2605\2605';
    }

    .c-star__rating--3::before {
        content: '\2605\2605\2605';
    }

    .c-star__rating--4::before {
        content: '\2605\2605\2605\2605';
    }

    .c-star__rating--5::before {
        content: '\2605\2605\2605\2605\2605';
    }

    .c-star__rating {
        color: $color-star-selected;
        color: var(--color-star-selected);
        cursor: pointer;
    }

    .c-star__rating::before {
        opacity: 0;
    }

    .c-star__rating:hover::before,
    input:checked + .c-star__rating::before {
        opacity: 1;
    }
}
