//------------------------------------
// #DROPDOWN
//------------------------------------

$dropdown-max-height: 280px;

.c-dropdown {
    position: relative;
    width: 100%;
    z-index: zIndex('dropdown');
    font-family: $font-family-secondary;

    &:focus {
        border: none;
    }

    // State overrides
    &.is-open {
        z-index: zIndex('dropdown-open');

        .c-dropdown__list {
            display: block;
        }
        .c-dropdown-btn {
            outline: none;
            border: 1px solid $color-brand-secondary;
            border-bottom: 1px solid transparent; // Prevent button text shifting when error border is added

            .c-icon {
                transform: rotate(180deg);
            }
        }
    }

    &.has-error {
        .c-dropdown-btn,
        .c-dropdown__list {
            border: 1px solid $color-validation-error;
        }

        &.is-open {
            .c-dropdown-btn {
                border-bottom: 1px solid transparent;
            }
        }
    }
}

.c-dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include rem(padding, 3px 13px);
    width: 100%;
    text-align: left;
    background-color: $color-off-white;
    color: $color-ui-grey-2;
    border: 1px solid $color-off-white;
    border-bottom: 1px solid transparent;
    min-height: 40px;

    &:focus {
        outline: none;
    }

    .c-icon {
        transition: 0.5s ease-in-out;
        float: right;
        width: 12px;
        height: 8px;
    }
}

.c-dropdown__list {
    display: none;
    width: 100%;
    position: absolute;
    list-style: none;
    margin-left: 0;
    top: 100%;
    border: 1px solid $color-brand-secondary;
    max-height: $dropdown-max-height;
    overflow-y: scroll;
}

.c-dropdown__item {
    background-color: $color-off-white;
    color: $color-ui-grey-2;
    border-bottom: 1px solid lighten($color-ui-grey-4, 10%);

    &:last-child {
        border: none;
    }

    & > a {
        @include rem(padding, 5px 10px);
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            background-color: $color-brand-secondary;
        }

        @include link-color($color-ui-grey-2, $color-white);
    }
}
