//------------------------------------
// #TOPMENU EXTEND
//------------------------------------

.c-topmenu--mobile {
    @include rem(padding, 0 15px);

    margin: 0;
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    background-color: $color-white;
    z-index: zIndex('mobile-menu-links');

    ul {
        margin: 0;
        list-style: none;
    }

    a {
        @include rem(padding, 12px 8px);
        @include rem(margin-bottom, 2px);
        @include font-weight(bold);

        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: $color-white;
    }

    // Top level categories
    > ul > li > a {
        background-color: $color-brand-primary;

        &:hover,
        &:focus {
            background-color: darken($color-brand-primary, 10%);
        }

        &.c-topmenu__home {
            background-color: transparent;
            color: $color-black;

            &:hover,
            &:focus {
                background-color: $color-mono-b;
            }

            &::after {
                display: none;
            }
        }
    }

    // Subcatgories
    .c-topmenu__dropdown {
        display: none;

        ul > li > a {
            background-color: $color-main-nav-bg;

            &:hover,
            &:focus {
                background-color: $color-main-nav-bg-hover;
            }
        }

        ul > .c-topmenu__parent-link > a {
            background-color: $color-ui-grey-2;

            &:hover,
            &:focus {
                background-color: darken($color-ui-grey-2, 20%);
            }
        }
    }

    .is-open + .c-topmenu__dropdown {
        display: block;
    }

    .c-topmenu__dropdown .c-topmenu__dropdown a {
        background-color: lighten($color-ui-grey-2, 10%);

        &:hover,
        &:focus {
            background-color: lighten($color-ui-grey-2, 20%);
        }
    }

    .c-topmenu__categories {
        border-top: 1px solid $color-black;
        padding-top: 0;
        overflow-x: hidden;
        overflow-y: auto;

        @include mq($until: desktop) {
            width: 100% !important; // Override important in base theme
            position: relative;
            top: auto;
            left: auto;
        }

        li.level0::after {
            // Reset default after element
            content: none;
        }

        li.level0 > a::after {
            @extend .c-icon--white-down-arrow-icon;

            content: '';
            display: block;
            position: absolute;
            right: 12px;
            transition: .25s;
            height: 9px;
            width: 12px;
            top: 20px;
        }

        li.level0 > a.is-open::after {
            transform: rotate(180deg);
        }

        > li {
            padding-left: 0;
            padding-right: 0;
            border-bottom: 0;

            > a {
                @include link-color($color-white, $color-white);

                &.c-topmenu__home {
                    @include link-color($color-black, $color-black);
                }
            }
        }

        .c-topmenu__dropdown {
            li.level1 > a {
                margin-top: 0;
            }
        }
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-topmenu--desktop {
    @include rem(margin-top, 20px);

    display: none;
    position: relative;
    background-color: $color-main-nav-bg;

    @include mq($from: desktop) {
        display: block;
    }

    > ul,
    .c-topmenu__category > .c-topmenu__dropdown > ul {
        width: map-get($mq-breakpoints, desktop);

        @include mq($from: wide) {
            width: map-get($mq-breakpoints, wide);
        }
    }

    ul {
        margin: 0 auto;
        list-style: none;
    }

    a {
        display: block;
        color: $color-white;
        text-align: center;
        text-decoration: none;
        line-height: 1.3;

        &:visited {
            color: $color-white;
        }

        &:hover,
        &:focus {
            color: $color-brand-secondary;
        }
    }

    // Categories
    > ul {
        display: flex;

        > li,
        > li > a,
        > li > a > span {
            display: flex;
            align-items: stretch;
        }

        > li > a > span {
            @include rem(padding, 0 15px);

            border-left: 1px solid $color-ui-grey-2;
            align-items: center;
        }

        > li:first-of-type > a > span {
            border-left: none;
        }

        > li > a {
            @include rem(padding, 12px 0);
        }

        > li:first-child > a,
        > li:last-child > a {
            @include rem(padding, 12px 0);

            text-align: center;
        }

        > li.is-active,
        > li:hover {
            background-color: $color-main-nav-bg-hover;

            > a {
                color: $color-brand-secondary;
            }

            > a > span {
                border-color: $color-main-nav-bg-hover;
            }

            + li > a > span {
                border-color: transparent;
            }

            .c-topmenu__dropdown {
                display: block;
            }
        }
    }

    .c-topmenu__category > .c-topmenu__dropdown {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        z-index: 9999;

        background-color: $color-main-nav-bg-hover;

        a {
            @include rem(padding, 5px 0);
            display: inline-block;
            text-align: left;
        }

        > ul {
            @include rem(padding, 5px 20px);

            display: flex;
            flex-wrap: wrap;
        }

        > ul > li {
            @include rem(padding-left, 20px);
            @include rem(margin-bottom, 20px);

            flex-basis: 20%;
            max-width: 20%; // IE

            > .c-topmenu__dropdown--has-columns {
                column-count: 2;
            }

            > a {
                color: $color-brand-secondary;
                @include font-weight(bold);

                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }

        .c-topmenu__parent-link {
            flex-basis: 100%;
            max-width: 100%; // IE

            > a {
                display: flex;
                align-items: center;

                color: $color-white;

                &:visited {
                    color: $color-white;
                }

                &:hover,
                &:focus {
                    color: $color-brand-secondary;
                }

                &::after {
                    @include rem(margin-left, 10px);

                    content: ' ';
                    display: inline-block;
                    @extend .c-icon--arrow-right;
                    background-repeat: no-repeat;
                    height: 10px;
                    width: 15px;
                }
            }
        }
    }
}

.c-topmenu__custom-item {
    ~ li {
        display: none;
    }

    .c-topmenu__custom-link {
        color: $color-brand-secondary;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: $color-white;
        }
    }
}

.c-topmenu__category a:visited:hover {
    color: $color-brand-secondary;
}
