//------------------------------------
// #TYPOGRAPHY
//------------------------------------

// A series of helper classes to use arbitrarily to force font styling

// Font sizes
.u-font-size-alpha {
    @include font-size($font-size-alpha, $important: true);
}

.u-font-size-beta {
    @include font-size($font-size-beta, $important: true);
}

.u-font-size-gamma {
    @include font-size($font-size-gamma, $important: true);
}

.u-font-size-delta {
    @include font-size($font-size-delta, $important: true);
}

.u-font-size-epsilon {
    @include font-size($font-size-epsilon, $important: true);
}

.u-font-size-zeta {
    @include font-size($font-size-zeta, $important: true);
}

.u-font-size-eta {
    @include font-size($font-size-eta, $important: true);
}

// Font weights
.u-font-weight-bold {
    @include font-weight('bold');
}

// Alignment
.u-text-left {
    text-align: left !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-center {
    text-align: center !important;
}
