//------------------------------------
// #LAYOUT
//------------------------------------

// Layout containers
.o-layout {
    @include clearfix(important);
    @include rem(margin-left, -$spacing-unit);

    list-style: none;
    padding: 0;
}

.o-layout__item {
    @include rem(padding-left, $spacing-unit);

    float: left;
    width: 100%;
    vertical-align: top;
}

// Collapse the margins so items sit right up next to each other
.o-layout--collapsed {
    margin-left: 0;

    > .o-layout__item {
        padding-left: 0;
    }
}

// Add some margin to the bottom of the row
.o-layout--spaced {
    @include rem(margin-bottom, 10px);
}

// Modifier so items fill their parent container
.o-layout--fill-height {
    height: 100%;

    > .o-layout__item {
        height: 100%;
    }
}

// Modifier for different layout gutter widths
.o-layout--small {
    @include rem(margin-left, -$spacing-unit-small);

    > .o-layout__item {
        @include rem(padding-left, $spacing-unit-small);
    }
}

.o-layout--large {
    @include rem(margin-left, -$spacing-unit-large);

    > .o-layout__item {
        @include rem(padding-left, $spacing-unit-large);
    }
}

// Region containers
.o-region {
    width: 100%;
}

.o-region__inner {
    margin: 0 auto;
    width: 90%;

    // tablet sizes
    @include mq($from: tablet, $until: desktop) {
        width: map-get($mq-breakpoints, tablet);
    }

    // desktop sizes
    @include mq($from: desktop, $until: wide) {
        width: map-get($mq-breakpoints, desktop);
    }

    // wide sizes
    @include mq($from: wide) {
        width: map-get($mq-breakpoints, wide);
    }
}
