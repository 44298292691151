//------------------------------------
// #PAGINATION
//------------------------------------

.c-pagination {
    margin: 0;
    text-align: center;

    @include mq($from: tablet) {
        text-align: left;
    }

    .label {
        display: none;
    }
}

.c-pagination__link,
.c-pagination__item--current,
.c-pagination__item--dots {
    @include rem(font-size, 14px);
    @include rem(width, 35px);
    @include rem(height, 35px);

    display: inline-block;
    line-height: 2.5;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: $color-black;
    color: var(--color-black);
}

.c-pagination__item--dots {
    cursor: auto;
}

.c-pagination__item {
    display: inline-block;
}

.c-pagination__item--current {
    background-color: $color-brand-primary;
    background-color: var(--color-brand-primary);
    color: $color-white;
    color: var(--color-white);
}

.c-pagination__item--previous,
.c-pagination__item--next,
.c-pagination__item--right,
.c-pagination__item--left {
    .c-pagination__link {
        @include rem(top, 7px);

        position: relative;
        width: auto;
        height: auto;

        &::after {
            @include rem(height, 25px);
            @include rem(width, 25px);

            content: '';
            display: block;
        }
    }
}

.c-pagination__item--left a:after,
.c-pagination__item--previous a:after {
    background-image: url('../images/icons/svg/arrow-left-filled.svg');
}

.c-pagination__item--right a:after,
.c-pagination__item--next a:after {
    background-image: url('../images/icons/svg/arrow-right-filled.svg');
}

.toolbar.review-toolbar {
    > .c-pagination {
        @include rem(padding-bottom, $base-spacing-unit);
    }
}
