//------------------------------------
// #FOOTER COPYRIGHT
//------------------------------------

.c-footer__copyright {
    @include rem(margin-top, 20px);

    background-color: $color-footer-copyright-bg-mobile;
    background-color: var(--color-footer-copyright-bg-mobile);
    color: $color-footer-copyright-text-mobile;
    color: var(--color-footer-copyright-text-mobile);

    p {
        @include font-size($font-size-zeta);
        @include rem(padding, 20px 0);

        margin-bottom: 0;
    }

    @include mq($from: tablet) {
        background-color: $color-footer-copyright-bg;
        background-color: var(--color-footer-copyright-bg);
        color: $color-footer-copyright-text;
        color: var(--color-footer-copyright-text);
        text-align: center;

        p {
            @include rem(padding, 10px 0);
        }
    }
}
