//------------------------------------
// #PRODUCT FORM
//------------------------------------

.s-product__form {
    .c-input-wrap {
        @include rem(margin, 0 0 5px);
    }

    .c-input-qty__label {
        @include rem(margin-right, 10px);
        @include font-weight(bold);

        color: $color-mono-d;
    }

    .c-input__qty {
        width: 40px !important;
        height: 35px !important;
        font-weight: bold;
        display: inline-block;
    }

    .c-btn {
        @include rem(margin, 10px 0);
    }

    .c-btn--product {
        @include rem(margin-top, 15px);

        min-width: 100% !important;
    }

    @include mq($until: tablet) {
        .c-input-qty__label {
            display: none;
        }

        .c-input-wrap {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }

        .c-btn--product {
            height: 35px;
            flex-grow: 1;
            margin-top: 0;
            min-width: initial !important;
        }
    }
}
