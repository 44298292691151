//------------------------------------
// #REACT LISTINGS SIDEBAR
//------------------------------------

.c-listings__sidebar {
    @include mq($until: tablet) {
        @include rem(padding-bottom, 101px);
        @include rem(padding-right, $base-spacing-unit);

        height: 100%;
        width: 80%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: zIndex('react-plp-sidebar');
        background-color: $color-plp-sidebar-mob-bg;
        background-color: var(--color-plp-sidebar-mob-bg);
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        transition: transform .5s ease;
        transform: translate3d(-100%, 0, 0);
    }
}

.c-listings__sidebar-underlay {
    background: $color-black;
    background: var(--color-black);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: zIndex('react-plp-sidebar') - 1;
    transition: opacity .5s ease;

    @include mq($from: tablet) {
        display: none;
    }
}

// Mobile, full screen side bar
.c-listings__sidebar--open {
    @include mq($until: tablet) {
        transform: translate3d(0, 0, 0);
    }
}

.c-listings__sidebar--open + .c-listings__sidebar-underlay {
    pointer-events: auto;
    opacity: .5;
}

.c-listings__sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($from: tablet) {
        display: none;
    }

    h3 {
        margin-bottom: 0;
    }

    .c-qty__plus {
        @include rem(font-size, 48px);

        display: inline-block;
        transform: rotate(45deg);
        color: $color-plp-sidebar-mob-plus-icon;
        color: var(--color-plp-sidebar-mob-plus-icon);
    }
}

.c-listings__close-filters {
    padding: 0;
}
