//------------------------------------
// #CONTACT PAGE
//------------------------------------

.c-contact-page__information {
    @include rem(margin-bottom, 30px);

    h3 {
        margin: 0;
    }

    a {
        text-decoration: none;
        display: block;

        @include rem(font-size, 13px);
        @include font-weight(bold);
        @include link-color($color-contact-page-link, $color-cyan);

        @include mq($from: desktop) {
            @include rem(font-size, 17px);
        }
    }

    p {
        line-height: 1.3;

        @include rem(font-size, 12px);
        @include rem(margin-top, 10px);

        @include mq($from: desktop) {
            @include rem(font-size, 14px);
        }
    }

    p strong {
        color: $color-contact-page-strong-font;
    }

    .c-contact-page__cms-block-wrapper {
        display: flex;
        flex-direction: row;

        @include mq($from: desktop) {
            flex-direction: column;
        }
    }

    .c-contact-page__cms-content {
        @include rem(margin-left, 10px);

        @include mq($from: desktop) {
            margin: 0;
        }
    }

    .c-icon--phone-icon,
    .c-icon--email-icon {
        width: 20px;
        height: 20px;
        display: block;

        @include rem(margin-bottom, 10px);

        @include mq($from: desktop) {
            width: 30px;
            height: 30px;
        }
    }
}
