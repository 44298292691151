//------------------------------------
// #ACTIONS
//------------------------------------

.c-actions__toolbar {
    @include rem(padding-top, 15px);
    @include mobile-gutter();

    > .c-button {
        @include rem(margin-bottom, 10px);

        width: 100%;

        @include mq($from: desktop) {
            width: auto;
        }
    }
}
