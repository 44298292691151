//------------------------------------
// #FOOTER COPYRIGHT
//------------------------------------

.c-footer__copyright {
    order: 6;
    margin-top: 0;

    p {
        @include rem(font-size, $font-size-zeta);
        @include rem(margin-bottom, 24px);

        padding: 0;
    }

    @include mq($from: tablet) {
        @include rem(margin-top, 20px);
        @include rem(padding-top, 20px);

        text-align: left;
        padding-left: 0;
        border-top: 1px solid $color-ui-grey-2;
        order: 5;
    }
}
