//------------------------------------
// #FORMS
//------------------------------------

%c-form__group--required {
    > label > span:first-of-type,
    > legend {
        &::after {
            @include rem(margin-left, 3px);

            content: '*';
        }
    }
}

.c-form__group {
    @include rem(margin-bottom, $base-spacing-unit);

    &--required,
    &._required {
        @extend %c-form__group--required;
    }
}

.c-checkout__step-content,
.c-new-shipping-address {
    fieldset.required {
        @extend %c-form__group--required;
    }
}

.c-form__notification {
    @include rem(margin-top, 5px);
}

.c-form__notification--error {
    color: $color-alert-error;
    color: var(--color-alert-error);
}

.c-form__label {
    @include rem(margin-bottom, 8px);

    display: block;
    font-weight: normal;
    color: $color-label;
    color: var(--color-label);

    > span {
        @include rem(margin-bottom, 8px);

        display: inline-block;

        &.c-form__select {
            display: block; // Ensure we can wrap select HTML within a <span> inside a label
        }
    }
}

.c-form__control,
.c-form__control.c-form__control,
.c-form__select select {
    @include rem(padding, 10px);
    @include rem(margin-bottom, 5px);

    width: 100%;
    border: 1px solid $color-input-border;
    border: 1px solid var(--color-input-border);
    border-radius: 5px;
    font-weight: normal;
    color: $color-input;
    color: var(--color-input);

    &:focus {
        border: 1px solid $color-input-border-focus;
        border: 1px solid var(--color-input-border-focus);
    }

    &.mage-error {
        border-color: $color-input-validation-error;
        border-color: var(--color-input-validation-error);
    }

    &::placeholder {
        color: $color-input-placeholder;
        color: var(--color-input-placeholder);
    }
}

.c-form__checkbox,
.c-form__radio {
    @include rem(font-size, 16px);

    cursor: pointer;
    padding-left: 1.2em;
    position: relative;
    color: $color-label;
    color: var(--color-label);

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:checked {
            & ~ .c-indicator {
                background-color: $color-white;
                background-color: var(--color-white);
                color: $color-white;
                color: var(--color-white);

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: $color-black;
                    background-color: var(--color-black);
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    height: 0.5em;
                    width: 0.5em;
                }
            }
        }

        &[disabled] {
            ~ .c-indicator {
                background-color: $color-input-disabled;
                background-color: var(--color-input-disabled);
                border-color: $color-input-disabled;
                border-color: var(--color-input-disabled);
                color: $color-white;
                color: var(--color-white);
            }
        }
    }

    .c-indicator {
        background-color: $color-white;
        background-color: var(--color-white);
        border: 2px solid $color-black;
        border: 2px solid var(--color-black);
        position: absolute;
        left: 0;
        top: 0.2em;
        user-select: none;
        height: 1em;
        width: 1em;
    }
}

.c-form__checkbox {
    .c-indicator {
        @include rem(border-radius, 2px);
    }
}

.c-form__radio {
    .c-indicator {
        border-radius: 100%;
    }

    input:focus,
    input:checked,
    input:active {
        & ~ .c-indicator:before {
            border-radius: 100%;
        }
    }
}

.c-form__radio--only-option {
    padding-left: 0;
}

@include mq($from: tablet) {
    .c-form__radiogroup--inline,
    .c-form__checkboxgroup--inline {
        display: flex;
        align-items: center;

        .c-form__control {
            @include rem(margin-right, 30px);

            width: auto;
        }
    }
}

.c-form__select {
    position: relative;

    &::after {
        @include rem(width, 30px);
        @include rem(height, 30px);
        @include rem(right, 3px);
        @include rem(top, 6px);

        content: '';
        background-image: url('../images/icons/svg/arrow-expand-down.svg');
        display: block;
        position: absolute;
        pointer-events: none;
    }

    .c-form__control,
    select {
        @include rem(padding-right, 30px);

        width: 100%;

        &::-ms-expand {
            display: none;
        }
    }
}

.c-textarea--noresize {
    resize: none;
}

.c-form__group--inline {
    .c-form__label,
    .c-form__select,
    .c-form__control,
    &.c-form__select select,
    .c-form__label > span.c-form__select {
        display: inline-block;
    }
}

.c-form__group--combined {
    display: flex;
    align-items: stretch;
    border: 1px solid $color-input-border;
    border: 1px solid var(--color-input-border);
    background-color: $color-input-bg;
    background-color: var(--color-input-bg);

    .c-form__label {
        flex-grow: 1;
        margin-bottom: 0;
    }

    .c-form__control,
    .c-form__select select {
        border: none;
        margin-bottom: 0;
        background-color: transparent;

        &:focus {
            border: none;
        }
    }

    .c-button {
        height: auto;
        flex-shrink: 0;
    }

    .active & {
        border-color: $color-input-border-focus;
        border-color: var(--color-input-border-focus);
    }
}

.c-form__group--qty {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include mq($from: tablet) {
        justify-content: normal;
    }

    .c-form__label {
        @include rem(margin-right, 10px);

        font-weight: normal;
        margin-bottom: 0;
    }

    .c-qty__button {
        @include rem(margin, 0 5px);
        @include rem(width, 32px);
        @include rem(height, 32px);

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        padding: 0;
        background-image: none; // FF
        background-color: $color-white;
        background-color: var(--color-white);
        border: 1px solid $color-ui-grey-2;
        border: 1px solid var(--color-ui-grey-2);
        border-radius: 50%;
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
        line-height: 1;

        @include hocus() {
            color: $color-ui-grey-1;
            color: var(--color-ui-grey-1);
            border-color: $color-ui-grey-1;
            border-color: var(--color-ui-grey-1);
        }

        &[disabled] {
            opacity: .6;
            pointer-events: none;
        }
    }

    .c-qty__minus,
    .c-qty__plus {
        @include rem(width, 10px);
        @include rem(height, 10px);
    }

    .c-form__control {
        @include rem(width, 32px);

        border: none;
        padding: 0;
        text-align: center;
        margin-bottom: 0;
    }
}

.c-form--cart {
    @include rem(margin-bottom, 30px);
}

.c-form--shipping-methods {
    .c-actions__toolbar > .c-button {
        @include mq($from: desktop) {
            width: 100%;
        }
    }
}

.c-form__help-text {
    font-weight: normal;
}

.c-form__group--shipping-method {
    .c-form__radio {
        @include font-size($font-size-delta);
        @include rem(padding, 40px 30px 40px 65px);

        display: flex;
        background-color: $color-ui-grey-5;
        background-color: var(--color-ui-grey-5);
        height: auto;

        .c-indicator {
            @include rem(left, 30px);
        }
    }

    .c-form__labels {
        display: flex;
        flex: 1 0 100%;

        > .c-form__labels--carrier,
        > .c-form__labels--method {
            flex: 2 1 0;
        }

        > .c-form__labels--price {
            @include rem(padding-right, 6px);

            flex: 1 1 0;
        }
    }

    @include mq($until: tablet) {
        margin-bottom: 0;

        &:last-child {
            @include rem(margin-bottom, 30px);
        }

        .c-form__radio {
            @include rem(padding, 15px 15px 15px 50px);

            .c-indicator {
                @include rem(left, 15px);
                transform: translateY(-2px);
            }
        }

        .c-form__labels--carrier {
            display: none;
        }
    }
}

.c-form__group--newsletter {
    position: relative;
    border: none;
    flex-wrap: wrap;

    &::before {
        @include rem(margin-left, 10px);
        @include rem(width, 18px);
        @include rem(height, 18px);
        @include rem(top, 10px);

        display: block;
        content: ' ';
        position: absolute;
        background-image: url('../images/icons/svg/email-grey.svg');
    }

    .c-button {
        text-transform: uppercase;
    }

    .c-form__control {
        @include rem(padding-left, 35px);

        border-top: 1px $color-ui-grey-3 solid;
        border-top: 1px var(--color-ui-grey-3) solid;
        border-left: 1px $color-ui-grey-3 solid;
        border-left: 1px var(--color-ui-grey-3) solid;
        border-bottom: 1px $color-ui-grey-3 solid;
        border-bottom: 1px var(--color-ui-grey-3) solid;
        border-radius: 0;
        width: auto;
        flex-grow: 1;

        &:focus {
            border-top: 1px $color-ui-grey-3 solid;
            border-top: 1px var(--color-ui-grey-3) solid;
            border-left: 1px $color-ui-grey-3 solid;
            border-left: 1px var(--color-ui-grey-3) solid;
            border-bottom: 1px $color-ui-grey-3 solid;
            border-bottom: 1px var(--color-ui-grey-3) solid;
        }
    }

    div.mage-error {
        @include rem(padding-top, 5px);

        width: 100%;
        order: 1;
        background-color: $color-ui-grey-5;
        background-color: var(--color-ui-grey-5);
    }
}

.c-form__group--no-label {
    @include rem(margin-top, $base-spacing-unit);
}

.c-form__group--captcha {
    border: 0;
}

.c-form__note {
    @include rem(margin-bottom, $base-spacing-unit);
}

// Helper modifier for applying mobile gutter easily to forms
.c-form--mobile-gutter {
    > .message,
    > fieldset {
        @include mobile-gutter();

        .c-actions__toolbar {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Unique forms
.c-form--checkout-login {
    @include rem(margin-bottom, 30px);

    color: $color-label;
    color: var(--color-label);

    .c-button {
        @include rem(margin-right, 15px);
    }
}
