//------------------------------------
// #PARAGRAPHS EXTEND
//------------------------------------

p {
    @include font-size(14px);

    font-family: $font-family-secondary;
    letter-spacing: normal;

    &:empty {
        margin: 0; // Sometimes CMS content can add extra empty paragraphs which cause whitespace
    }
}
