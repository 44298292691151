//------------------------------------
// #REVIEWS
//------------------------------------

.c-reviews {
    list-style: none;
    margin-left: 0;
}

.c-review {
    @include rem(margin-bottom, 30px);

    border-bottom: 1px solid $color-ui-grey-3;
    border-bottom: 1px solid var(--color-ui-grey-3);
}

.c-review__ratings {
    @include rem(margin-bottom, 30px);

    .c-star-rating {
        @include font-size($font-size-zeta);
        @include rem(margin-bottom, 5px);

        > span {
            width: 60px;
        }
    }
}

.c-review__content {
    @include rem(margin-bottom, 15px);
}

.c-review__details {
    @include rem(margin-bottom, 30px);

    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);

    > span + span {
        &::before {
            content: '\00a0\00b7\00a0';
        }
    }
}

.c-review__author,
.c-review__timestamp {
    color: $color-black;
    color: var(--color-black);
}

.c-review__add {
    @include font-size($font-size-zeta);

    form > fieldset > legend > span {
        font-weight: normal;
    }
}

.c-review__add-legend {
    @include font-size($base-font-size);
    @include rem(margin-bottom, 15px);
}

.c-review__add-product {
    font-weight: bold;
}
