//------------------------------------
// #GROUPED PRODUCTS
//------------------------------------

.c-grouped__products-wrapper {
    hr:last-of-type {
        display: none;
    }
}

.c-grouped__product-item {
    display: flex;
}

.c-grouped__product-details {
    display: inline;
    align-items: center;
    max-width: 75%;
}

.c-grouped__product-item {
    justify-content: space-between;
    @include rem(padding-bottom, 10px);

    .c-grouped__product-title {
        display: inline;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0;
        @include font-size(14px);
    }

    .c-price-box {
        display: inline-block;
        @include rem(margin-left, 5px);
        margin-bottom: 0;

        p {
            font-weight: bold;
            color: #000000;
            margin-bottom: 0;
            @include font-size(14px);
        }
    }

    .c-input-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 0 !important;

        .c-input-qty__label {
            @include rem(margin-right, 8px);
            margin-bottom: 0;
        }
    }
}

// where did this label come from? 
.c-product__price--excl:after,
.c-product__price--incl:after {
    content: " " attr(data-label);
    left: 0;
    @include rem(font-size, 14px);
}

.c-grouped-price__wrapper {
    margin: 30px 0;
}
