.toolbar {
    .o-layout {
        &.filter-wrapper {
            @include mq($until: tablet) {
            margin-left: 0 !important;
            }
        }

        .o-layout-item-container {
            clear: left;
        }
    }

    div.o-layout__item {
        @include mq($until: tablet) {
            padding-left: 0.5rem !important;
        }
    }
}


