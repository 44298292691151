//------------------------------------
// #COMPARE
//------------------------------------

.c-compare {
    .c-compare__table-wrapper {
        overflow-x: auto;
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 0;

        &,
        p {
            @include font-size($font-size-epsilon);
        }

        > tbody > tr {
            > th,
            > td {
                @include rem(padding, 15px);
                @include rem(width, 180px);
            }

            &:nth-of-type(1) {
                border-bottom: 1px solid $color-ui-grey-3;
                border-bottom: 1px solid var(--color-ui-grey-3);
            }

            &:nth-of-type(2) {
                > th,
                > td {
                    @include rem(padding-top, 20px);
                }
            }
        }
    }
}
