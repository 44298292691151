//------------------------------------
// #HEADINGS
//------------------------------------

// Simple H1–6 styles, bound to element selectors only.

h1,
h2,
h3,
h4,
h5,
h6,
.e-heading {
    font-weight: normal;
}

h1,
.e-heading--h1 {
    @include font-size(
        map-get($heading-size-alpha, size),
        map-get($heading-size-alpha, line-height)
    );
}

h2,
.e-heading--h2 {
    @include font-size(
        map-get($heading-size-beta, size),
        map-get($heading-size-beta, line-height)
    );
}

h3,
.e-heading--h3 {
    @include font-size(
        map-get($heading-size-gamma, size),
        map-get($heading-size-gamma, line-height)
    );
}

h4,
.e-heading--h4 {
    @include font-size(
        map-get($heading-size-delta, size),
        map-get($heading-size-delta, line-height)
    );
}

h5,
.e-heading--h5 {
    @include font-size(
        map-get($heading-size-epsilon, size),
        map-get($heading-size-epsilon, line-height)
    );
}

h6,
.e-heading--h6 {
    @include font-size(
        map-get($heading-size-zeta, size),
        map-get($heading-size-zeta, line-height)
    );
}
