//------------------------------------
// #CART
//------------------------------------

.c-cart {
    > thead {
        color: $color-ui-grey-2;
    }

    > thead > tr,
    > tbody > tr {
        border-bottom: 1px solid $color-ui-grey-4;
        border-bottom: 1px solid var(--color-ui-grey-4);
    }

    > tbody > tr > td {
        @include rem(padding, 10px 0);
    }

    .c-cart-product {
        padding: 0;
        border-bottom: 0;
    }

    @include mq($from: desktop) {
        > thead > tr,
        > tbody > tr {
            border-bottom-color: $color-ui-grey-2;
            border-bottom-color: var(--color-ui-grey-2);
        }
    }
}

.c-cart__actions {
    padding-top: 0;

    > .c-button {
        &:last-child {
            margin-bottom: 0;
        }

        @include mq($from: tablet) {
            &:last-child {
                @include rem(margin-bottom, 10px);
            }

            width: auto;
        }
    }
}

.c-cart-summary {
    @include rem(padding, 30px);

    display: none;
    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);

    @include mq($from: desktop) {
        display: block;
    }
}

.c-cart-summary__title {
    @include font-size($font-size-gamma);
    @include rem(margin-bottom, 20px);
    @include rem(padding-bottom, 20px);

    font-weight: bold;
    border-bottom: 1px solid $color-ui-grey-4;
    border-bottom: 1px solid var(--color-ui-grey-4);
}

.c-cart-summary__shipping {
    @include rem(padding-bottom, 20px);
    @include rem(margin-bottom, 20px);

    border-bottom: 1px solid $color-ui-grey-4;
    border-bottom: 1px solid var(--color-ui-grey-4);

    &-title {
        cursor: pointer;
    }

    p {
        @include font-size(14px);
    }

    &.active {
        padding-bottom: 0;

        .c-cart-summary__shipping-title {
            @include rem(margin-bottom, 20px);
        }
    }
}

.c-cart-summary__totals {
    td {
        text-align: right;
    }

    th {
        font-weight: normal;
    }

    table > tbody > tr:nth-last-child(2) {
        > th,
        > td {
            @include rem(padding-bottom, 20px);
        }
    }

    .totals-tax {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }

    .totals.grand {
        @include font-size($font-size-delta);

        color: $color-black;
        color: var(--color-black);
        border-top: 1px solid $color-ui-grey-4;
        border-top: 1px solid var(--color-ui-grey-4);

        > th,
        > td {
            @include rem(padding, 20px 0);
        }
    }
}

.c-cart-summary__actions {
    button,
    a {
        @include rem(margin-bottom, 15px);
    }

    &:last-child {
        margin-bottom: 0;
    }

    .action.multicheckout {
        color: $color-brand-primary;
        color: var(--color-brand-primary);
    }
}

.c-cart__checkout {
    @include mobile-gutter();
    @include rem(padding-top, 15px);
    @include rem(padding-bottom, 15px);
}

.c-cart-product__img img {
    display: block;
}
