//------------------------------------
// #DASHBOARD ORDERS
//------------------------------------

.c-recent-orders {
    flex-grow: 1;

    .c-recent-orders__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-dashboard-title-border-bottom;

        @include rem(padding-bottom, 5px);
        @include rem(margin-bottom, 30px);
    }

    .c-recent-orders__title,
    .c-dashboard-link--recent-orders {
        margin: 0;
    }
}

.c-order-action__link {
    display: block;
}

.c-dashboard-link--back-to-orders {
    text-decoration: none;

    @include link-color($color-back-to-orders-link-primary, $color-brand-secondary);
    @include font-weight(bold);

    &:hover {
        text-decoration: none;
    }

    .c-icon--arrow-left-sm {
        width: 15px;
        height: 10px;

        @include rem(margin-right, 5px);
    }
}

.c-order-detail__title-container {
    .c-order-detail__title {
        margin: 0;
    }

    .c-order-status {
        @include font-weight(bold);
    }
}

.c-order-actions {
    @include mq($from: desktop) {
        text-align: right;
    }
}
