//------------------------------------
// #FOOTER STATIC
//------------------------------------

.c-footer-static__wrapper {
    background-color: $color-off-white;
    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);

    .c-icon--brand-minispares,
    .c-icon--brand-mini {
        height: 100px;
        width: 100px;
    }

    .c-icon--ellipses {
        height: 100px;
        width: 150px;
        background-position: center;
    }
}

.c-footer-static__block {
    @include mq($until: tablet) {
        &:not(:last-child) {
            @include rem(margin-bottom, 20px);
        }
    }
}

.c-footer-static__header {
    @include rem(margin-bottom, 50px);
}

.c-footer-static__sub-header {
    @include rem(font-size, 16px);
    @include rem(margin-top, 15px);
    @include rem(margin-bottom, 15px);

    @include mq($from: tablet) {
        @include rem(font-size, 18px);
    }
}

.c-footer-static__cta {
    @extend .u-heading-delta;

    @include rem(margin-top, 15px);
}

.c-footer-static__cta-link {
    text-decoration: none;
    @include rem(margin-right, 2px);
    @include link-color($color-black, $color-black);
}

.c-footer-static__text {
    font-size: $font-size-zeta;
    line-height: 1.25;
}
