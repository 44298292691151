//------------------------------------
// #REACT PAGINATION
//------------------------------------

.c-pagination {
    @include rem(margin-bottom, $base-spacing-unit);

    @include mq($from: tablet) {
        margin-bottom: 0;
    }
}
