//------------------------------------
// #HEADER DROPDOWN
//------------------------------------

.c-header-dropdown {
    display: inline-block;
    position: relative;
    font-family: $font-family-secondary;
    z-index: zIndex('header-dropdown');

    // These are nested to fix specificity issue caused by .s-header-links
    .c-header-dropdown__label {
        @include rem(margin-left, 5px);

        display: inline-block;
        cursor: pointer;
        color: $color-black;
        font-family: $font-family-secondary;

        .c-icon {
            vertical-align: middle;
            width: 9px;
            height: 9px;
        }
    }

    .c-header-dropdown__options {
        display: none;
        width: 150px;
        position: absolute;
        top: 160%;
        left: 0;
        background-color: $color-blue;
        border: 2px solid transparent;
        text-align: left;
        z-index: 101;
        list-style-type: none;

        > li {
            padding: 0;
            display: list-item;
            width: 100%;
        }

        > li + li {
            border-top: 1px solid $color-ui-grey-2;
        }

        > li > a {
            display: block;
            @include rem(padding-left, 15px);
            @include rem(padding-top, 5px);
            @include rem(padding-bottom, 5px);
            font-weight: normal;

            &,
            &:visited {
                color: $color-white;
            }

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: none;
            }
        }

        &::after {
            bottom: 100%;
            left: 20%;
            border: solid 7px transparent;
            border-bottom-color: $color-blue;
            content: " ";
            height: 0;
            width: 0;
            @include rem(margin-left, -7px);
            position: absolute;
            pointer-events: none;
        }
    }
}

.c-header-dropdown--mobile {
    margin-right: 0;

    .c-header-dropdown__options {
        left: 0;
        margin: 0;
    }
}

.c-header-dropdown--from-right {
    .c-header-dropdown__options {
        left: unset;
        right: 0;
        text-align: right;

        > li > a {
            @include rem(padding-right, 15px);
        }

        &::after {
            left: unset;
            right: 20%;
        }
    }

    .mage-dropdown-dialog {
        position: relative;
    }
}

.c-header-dropdown--wide .c-header-dropdown__options {
    width: 160px;
}

.c-header-dropdown--currency {
    > div {
        position: relative;
    }

    @include mq($from: wide) {
        @include rem(margin-right, 65px);
    }

    .c-header-dropdown__label {
        @include mq($from: wide) {
            @include rem(margin-right, 60px);
        }
    }
}

// Deep selector to override defaults
.c-drawer__head {
    .c-header-dropdown--currency.c-header-dropdown--mobile {
        .c-header-dropdown__options > li {
            margin-left: 0;
        }
    }
}

.c-header-dropdown__options.active {
    display: block !important;
}
