//------------------------------------
// #CHECKOUT EXTEND
//------------------------------------

@import 'checkout/checkout.includes';
@import 'checkout/checkout.step';
@import 'checkout/checkout.sidebar';
@import 'checkout/checkout.payment-option';
@import 'checkout/checkout.delivery-types';
@import 'checkout/checkout.click-and-collect';
@import 'checkout/checkout.progress-bar';
@import 'checkout/checkout.step-heading';
@import 'checkout/checkout.cms';
@import 'checkout/checkout.payment-method';



.c-checkout__billing-address {
    @include rem(margin-bottom, 36px);

    h3 {
        @extend %label;
    }

    &__details {
        @include rem(margin-top, 20px);

        font-family: $font-family-secondary;
        line-height: 1.4;
    }
    @include mq($until: desktop) {
        .c-btn--tertiary {
            @include rem(margin-top, 12px);
        }
    }
}

.c-checkout__order-number {
    display: block;
    color: $color-black;
    @include font-weight(bold);
    @include font-size(18px);
}

.c-button--checkout,
.c-btn--checkout {
    @include font-size(18px);
    font-weight: bold;
}

.c-checkout__advanced .c-checkout__advanced--actions .c-btn {
    @include rem(margin, 30px 0);
}

.c-checkout__success {
    @include mq($until: tablet) {
        @include rem(padding, 0px 17.6px);
    }
}
