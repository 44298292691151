//------------------------------------
// #HEADER SEARCH EXTEND
//------------------------------------

.s-header-search__submit {
    @include mq($until: tablet) {
        width: 40%;

        & > span {
            display: none;
        }
    }
}
