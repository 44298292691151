//------------------------------------
// #RELATED ITEMS
//------------------------------------

.c-related-items {
    @include rem(padding-top, 40px);

    @include mq($until: tablet) {
        display: none;
    }

    @include mq($from: tablet, $until: desktop) {
        @include full-width(tablet);
    }
}

.c-related-items__title {
    @include font-size($font-size-delta);
    @include rem(margin-bottom, 40px);

    text-align: center;
}
