//------------------------------------
// #PLP EXTEND
//------------------------------------

.c-plp__wrapper {
    @include rem(margin-bottom, 40px);

    .c-product__price {
        text-transform: uppercase;
    }

    .c-input-wrap {
        margin-bottom: 0;

        @include mq($from: tablet) {
            @include rem(margin-bottom, 15px);
        }

        .c-input__qty {
            padding: 0;
            text-align: center;
            display: inline-block;
            height: 36px;

            @include font-weight(bold);
            @include rem(font-size, 16px);
        }
    }

    .c-pdp__stock {
        margin-left: 0;
    }

    .c-btn {
        min-width: unset;
        @include rem(font-size, 12px);

        @include mq($from: tablet) {
            @include rem(font-size, 16px);
        }

        .c-product-list__item--grid & {
            min-width: unset;
        }
    }

    .c-icon--tick-sm-icon {
        width: 15px;
        height: 11px;
        margin-left: 0;
    }

    .c-quickview__link {
        @include font-weight(normal);
    }
}
