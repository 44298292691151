//------------------------------------
// #LOADING MASK
//------------------------------------

// Magento styles
.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: zIndex('loading-mask');
    background: rgba(255, 255, 255, 0.5);
}

.loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: zIndex('loading-mask-image');
}

.loading-mask .loader > .image {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: zIndex('loading-mask-image');
    background-repeat: no-repeat;
    background-position: center center;
}

.loading-mask .loader > p {
    display: none
}

body > .loading-mask {
    z-index: zIndex('body-loading-mask');
}

._block-content-loading {
    position: relative
}
