//------------------------------------
// #CHECKBOX OPTION EXTEND
//------------------------------------

.c-checkbox-wrap {
    @include rem(margin, 0 0 $spacing-unit-small);

    position: relative;

    input[type=checkbox],
    .c-checkbox-option__input[type=checkbox] {
        position: absolute;
        opacity: 0;
        z-index: zIndex('checkbox-input');

        &:checked ~ .c-checkbox-option__checkbox {
            background: $color-checkbox-checked-bg;
            border: none;
        }

        &:checked ~ .c-checkbox-option__checkbox:after {
            display: block;
        }
    }

    label,
    .c-checkbox-option__label {
        @include font-size(14px);
        @include rem(margin-right, 5px);

        font-family: $font-family-secondary;
        display: inline-block;
        position: relative;
        @include rem(padding-left, 26px);
        cursor: pointer;
        color: $color-ui-grey-2;
    }

    .c-checkbox-option__checkbox {
        @include rem(margin-right, 15px);

        position: absolute;
        left: 0;
        top: 4px;
        height: 16px;
        width: 16px;
        background-color: $color-off-white;
        border-style: solid;
        border-color: $color-off-white;
        border-width: 2px;
        border-radius: $checkbox-radius;

        &:after {
            @extend .c-icon--tick-sm-icon--white;
            @include rem(margin, 4px 3px 3px);

            background-repeat: no-repeat;
            content: '';
            width: 10px;
            height: 10px;
            line-height: 20px;
        }
    }

    @include mq($from: desktop) {
        @include rem(margin-bottom, $spacing-unit);
    }
}

.c-checkbox-option__count {
    text-decoration: none;
    color: $color-ui-grey-2;
}

.c-checkbox-wrap--remember-me {
    label {
        display: inline-block;
    }

    .c-tooltip {
        margin-left: 0;
    }
}
