//------------------------------------
// #CHECKOUT PAYMENT METHOD
//------------------------------------

.payment-group {
    width: 100%;
}

.c-checkout__payment-method {
    padding: 0;
    margin: 0;
    background-color: $color-white;
    width: 100%;
}

.c-checkout__payment-method__note {
    @include rem(margin-top, 30px);
}

.c-checkout__realex-payment {
    @include rem(margin-bottom, 12px);
}

// @see https://stackoverflow.com/a/23083463/1373658
.c-checkout__realex-payment-iframe {
    @include rem(width, 1px);

    min-width: 100%;
}

.payment-method {
    &._active {
        width: 100%;
    }
}

