//------------------------------------
// #GALLERY EXTEND
//------------------------------------

.fotorama__dot {
    background: $color-fotorama-dot-bg !important;
    border-color: $color-fotorama-dot-bg !important;
}

.fotorama__wrap .fotorama__stage,
.fotorama__wrap .fotorama__stage .fotorama__grab  {
        cursor: zoom-in;
}
