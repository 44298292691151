//------------------------------------
// #PRODUCT ADD FORM
//------------------------------------

.c-product-add-form {
    @include rem(padding, 15px $base-layout-gutter);

    border-top: 1px solid $color-ui-grey-3;
    border-top: 1px solid var(--color-ui-grey-3);

    @include mq($from: tablet) {
        border-top: none;
        padding-left: 0;
        padding-right: 0;
    }
}
