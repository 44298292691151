//------------------------------------
// #TABLES
//------------------------------------

.c-dashboard-table {
    width: 100%;

    @include font-size(11px, 1.5);

    @include mq($from: desktop) {
        @include font-size(12px, 2);
    }

    thead tr,
    tbody tr {
        border-bottom: none;
    }

    // quote subtotals uses nested tables
    table {
        @include rem(margin-bottom, 10px);
    }

    .c-dashboard-table__th {
        @include mq($from: desktop) {
            white-space: nowrap;
        }
    }

    .c-dashboard-table__th,
    .c-dashboard-table__td,
    .c-dashboard__tr .amount {
        padding-left: 0;
    }

    .c-dashboard-table__th,
    .c-dashboard-table__td--label {
        color: $color-dashboard-th;
    }

    .c-dashboard-table__th.qty,
    .c-dashboard-table__td.qty {
        @include mq($from: desktop) {
            text-align: center;
        }
    }

    .c-dashboard-table__td {
        @include mq($from: desktop) {
            @include rem(padding, 0 5px);
            @include rem(height, 40px);

            vertical-align: middle;

            &.stock {
                text-align: center;
            }

            &.item a,
            &.delete a {
                @include rem(font-size, 12px);
            }

            .c-input-wrap,
            .c-input__qty {
                margin: 0;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        .c-icon {
            @include rem(width, 12px);
            @include rem(height, 12px);
        }
    }

    .c-dashboard-table__td:last-of-type {
        text-align: left;

        &::before {
            content: attr(data-th);
            display: inline-block;
        }

        @include mq($from: desktop) {
            text-align: right;

            &::before {
                content: '';
                display: none;
            }
        }
    }

    .c-qty__status {
        display: inline-block;

        @include mq($from: desktop) {
            display: block;
        }
    }

    .c-dashboard-table__td--label {
        @include mq($from: desktop) {
            text-align: right;

            @include rem(padding-right, 20px);
        }
    }

    .c-dashboard-table__tr--grand_total {
        color: $color-order-detail-grand-total;

        @include font-weight(bold);

        .amount {
            @include font-size(22px, 1.1);
        }
    }
}

.c-dashboard-table--quotes {
    th,
    td {
        margin: 0;
        padding: 0;
    }

    td {
        @include mq($from: desktop) {
            text-align: right;
        }
    }
}

.c-dashboard-table--payment-method {
    margin-left: 0;
}
