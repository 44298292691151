//------------------------------------
// #DASHBOARD TOOLBAR
//------------------------------------

.c-quotes-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($from: desktop) {
        justify-content: space-between;
    }

    .c-toolbar__amount {
        color: $color-toolbar-amount;
        margin: 0;

        @include font-weight(bold);
    }

    .c-toolbar__control {
        margin-right: initial;
    }
}
