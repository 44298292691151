//------------------------------------
// #GLOBAL
//------------------------------------

// Column settings
$base-column-count: 12;

// Base typographical styles and baseline grid.
$base-font-size:   14px;
$base-line-height: 22px;

// Font Sizes
$font-size-alpha:   32px;
$font-size-beta:    26px;
$font-size-gamma:   20px;
$font-size-delta:   18px;
$font-size-epsilon: 14px;
$font-size-zeta:    12px;
$font-size-eta:     9px;

// Heading Sizes
$heading-size-alpha:   (size: $font-size-alpha, line-height: auto) !default;
$heading-size-beta:    (size: $font-size-beta, line-height: auto) !default;
$heading-size-gamma:   (size: $font-size-gamma, line-height: auto) !default;
$heading-size-delta:   (size: $font-size-delta, line-height: auto) !default;
$heading-size-epsilon: (size: $font-size-epsilon, line-height: auto) !default;
$heading-size-zeta:    (size: $font-size-zeta, line-height: auto) !default;

$is-heading-uppercase: true !default;
$is-menu-uppercase: $is-heading-uppercase;

// Standardise some UI treatments.
$base-border-radius: 0;
$base-transition:    200ms;

// Layout gutter
$base-layout-gutter: 18px;

// Mobile full width padding
$mobile-full-width-gutter: $base-layout-gutter;

// A standard margin
$spacer: 30px;

// Checkbox radius
$checkbox-radius: 0 !default;

// Message border radius
$message-border-radius: $base-border-radius;

// Grid settings
$grid-column-count:    $base-column-count;
$grid-gutter:          $base-layout-gutter;
$grid-default-columns: (
		mobile: 3,
		tablet: 4,
		desktop: 5,
		wide: 6
);

// Do not modify: reassigning variables for use throughout the project.
$base-spacing-unit:       $base-line-height;
$base-spacing-unit-small: round($base-spacing-unit / 2);
$base-spacing-unit-large: $base-spacing-unit * 2;

// SASS-MQ Breakpoint configuration
$mq-breakpoints: (
		mobile: 320px,
		tablet: 740px,
		desktop: 980px,
		wide: 1300px,
);

// A map of z layers, used by the @function zIndex.
// All z-index values for the Base Theme should be set here.
//
// To add new z-indexes in your theme, in your `_settings.global.extend.scss` file, do:
//
// $z-layers: map-merge($z-layers, (
//   'my-new-layer':            5000
// ));
//
// Caveat: This will only work if you don't have a nested map.
// If you do, you need another solution.
$z-layers: (
		'topmenu':              9999,
		'body-loading-mask':    9999,
		'modal':                5000,
		'dropdown':             4000,
		'modal-inner-wrap':     1000,
		'modal-overlay':         999,
		'checkout-summary':      500,
		'react-suggestions':     101,
		'react-plp-sidebar':     101,
		'minicart':              100,
		'loading-mask':          100,
		'loading-mask-image':    100,
		'sticky-header':         100,
		'sticky-facet':           50,
		'checkout-signin':		   2,
		'tooltips':                1,
		'progress-item':           1,
		'progress-bar':            0,
		'checkbox-input':         -1,
		'bottomless-pit':     -10000
);

// React search suggestions
// Calculate the height of the suggestions container based on some known quantities.
// Setting `line-height: 1` ensures the text will be 18px (`$react-search-suggestions-top-bottom-padding`) high.
// Add the padding of 7px to the top and bottom we can presume each suggestion will be 32px in height.
// Therefore 10 x 32 = 320
$react-search-suggestions-font-size:          $font-size-delta !default;
$react-search-suggestions-top-bottom-padding: 7px !default;
$react-search-suggestions-breakpoint:         10 !default;
$react-search-suggestions-height: ($react-search-suggestions-font-size + ($react-search-suggestions-top-bottom-padding * 2)) * $react-search-suggestions-breakpoint;

// Tooltips settings
$width-arrow :          20px;
$width-half-arrow:      $width-arrow / 2;
$width-border:          1px;
$width-tooltips:        210px;
$width-tooltips-bubble: 14px;
