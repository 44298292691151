//------------------------------------
// #TOP MENU
//------------------------------------

.c-topmenu {
    @include rem(margin-top, 18px);

    background-color: $color-brand-primary;
    background-color: var(--color-brand-primary);

    @include mq($until: 'desktop') {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: zIndex('topmenu');
        margin-top: 0;
        height: 100vh;
    }

    &.is-open {
        // scss-lint:disable no-important
        display: block !important; // Override u-display-hide--from-mobile
    }
}

.c-topmenu__toggle {
    display: block;
    height: 40px;
    width: 40px;
    padding: 0;
}

.c-topmenu__categories {
    @extend .o-region__inner;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include mq($until: 'desktop') {
        @include rem(padding-bottom, 20px);
        @include rem(padding-top, 20px);

        display: block;
        width: 80% !important;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-white;
        background-color: var(--color-white);
        overflow: scroll;
    }

    &:focus {
        outline: none;
    }

    li.level0::after {
        @include mq($until: 'desktop') {
            content: '';
            background-image: url('../images/icons/svg/arrow-expand-down.svg');
            background-repeat: no-repeat;
            display: block;
            height: 28px;
            width: 28px;
            position: absolute;
            right: 12px;
            top: 8px;
            transition: .25s;
        }
    }

    li.level0.is-open::after {
        @include mq($until: 'desktop') {
            transform: rotate(180deg);
        }
    }

    & > li {
        flex-grow: 1;

        @include mq($until: 'desktop') {
            @include rem(padding-left, 20px);
            @include rem(padding-right, 20px);

            display: block;
            position: relative;
            border-bottom: 1px solid $color-ui-grey-2;
            border-bottom: 1px solid var(--color-ui-grey-2);
        }

        & > a {
            @include link-color($color-black, $color-black);

            text-decoration: none;

            @include mq($from: 'desktop') {
                @include link-color($color-white, $color-white);

                text-align: center;
            }
        }

        @include mq($from: 'desktop') {
            &:first-child > a {
                padding-left: 0;
                text-align: left;
            }

            &:last-child > a {
                padding-right: 0;
                text-align: right;
            }
        }
    }
}

.c-topmenu__category {
    &,
    ul {
        margin: 0;
        list-style: none;
    }

    a {
        @include link-color($color-category-item, $color-category-item-hover);
        @include rem(padding, 10px 0);

        display: inline-block;
        width: 100%;
        text-align: left;
        text-decoration: none;
    }

    ul.level0.c-topmenu__dropdown {
        display: none;
        background: $color-white;
        background: var(--color-white);
        overflow: hidden;
        z-index: zIndex('topmenu');

        @extend .o-region__inner;

        @include mq($until: 'tablet') {
            width: 100%;
        }

        @include mq($from: 'desktop') {
            @include rem(padding, 20px 8px);

            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            box-shadow: 0 26px 28px -9px rgba(0, 0, 0, 0.1);
        }
    }

    ul.level1.c-topmenu__dropdown {
        @include mq($until: 'desktop') {
            @include rem(margin-left, 15px);

            margin-top: 0;
        }
    }

    li.level1 {
        @extend .o-layout__item;

        float: left;

        @include mq($until: 'desktop') {
            padding-left: 0;
        }

        @include mq($from: 'desktop') {
            @include width(2, 12);
        }

        & > a {
            @include font-weight('bold');
            @include rem(margin-top, 15px);
        }
    }
}
