//------------------------------------
// #CHECKOUT INCLUDES
//------------------------------------

%price {
    @include font-size(14px);
    @include font-weight(normal);

    color: $color-body;
}

%heading {
    @include font-size(16px, 2);
    @include font-weight(bold);

    @include mq($from: tablet) {
        @include font-size(18px, 2);
    }

    color: $color-black;
    border-bottom: 1px solid $color-ui-grey-4;
    @include rem(margin-bottom, 30px);
}

%label {
    @include font-weight(bold);
    @include font-size(16px);

    color: $color-black;

    @include mq($from: tablet) {
        @include font-size(18px);
    }
}
