//------------------------------------
// #MINICART
//------------------------------------

.c-minicart {
    position: relative;

    .c-minicart__icon,
    .c-minicart__text {
        @include rem(width, 35px);
        @include rem(height, 35px);

        display: inline-block;
    }

    .c-minicart__text {
        @include rem(padding-top, 7px);

        color: $color-white;
        color: var(--color-white);
        background-color: $color-brand-primary;
        background-color: var(--color-brand-primary);
        border-radius: 100%;
        text-align: center;
        position: relative;
    }

    .c-minicart__icon {
        @include rem(margin-right, 10px);
    }

    .c-minicart__overlay-bg {
        background: $color-black;
        background: var(--color-black);
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        z-index: zIndex('minicart') - 1;
        transition: opacity .5s ease;
    }

    &.is-active {
        .c-minicart__overlay-bg {
            pointer-events: auto;
            opacity: .5;
        }

        .c-minicart__button {
            position: relative; // Position relative for z-index only
            z-index: zIndex('minicart');
        }

        .c-minicart__drop-down {
            @include mq($from: desktop) {
                opacity: 1;
            }
        }
    }
}

.c-minicart__drop-down {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    background: $color-white;
    background: var(--color-white);
    z-index: zIndex('minicart');
    transform: translate3d(100%, 0, 0);
    transition: transform .5s ease;
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);

    .is-active & {
        transform: translate3d(0, 0, 0);
    }

    @include mq($from: tablet) {
        width: 45%;
    }

    @include mq($from: desktop) {
        @include rem(width, 420px);

        opacity: 0;
        position: absolute;
        bottom: auto;
        transform: translate3d(0, 0, 0);
        transition: opacity .5s ease;
    }
}

.c-minicart__button {
    position: relative;
    display: flex;
    align-items: center;
}

.c-minicart__content-wrapper {
    height: 100%;

    @include mq($from: desktop) {
        height: auto;
    }
}

// Minicart content
.c-minicart__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mq($from: desktop) {
        height: auto;
    }
}

// Minicart header
.c-minicart__header {
    @include rem(padding, 20px 15px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $color-ui-grey-4;
    border-bottom: 1px solid var(--color-ui-grey-4);

    .c-minicart__basket-count {
        display: none;
    }

    @include mq($from: desktop) {
        @include rem(padding, 15px);

        border-bottom: none;

        .c-minicart__subtotal,
        .c-minicart__title {
            display: none;
        }

        .c-minicart__basket-count {
            display: block;
        }
    }
}

.c-minicart__title {
    margin-bottom: 0;
    font-weight: normal;
}

.c-minicart__subtotal {
    @include rem(margin-top, 15px);

    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $color-brand-primary;
    color: var(--color-brand-primary);

    .c-minicart__subtotal-label {
        @include font-size($font-size-zeta);
    }

    .c-minicart__subtotal-prices {
        @include font-size($font-size-delta);
    }

    @include mq($from: desktop) {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }
}

.c-minicart__close {
    @include rem(height, 20px);
    @include rem(width, 20px);

    background-color: transparent;
    border: none;
    line-height: 1;
    color: $color-black;
    color: var(--color-black);
    padding: 0;

    .c-icon {
        width: 100%;
        height: 100%;

        float: left; // Fix button focus highlight stretching beyond button height
    }

    @include mq($from: desktop) {
        @include rem(height, 35px);
        @include rem(width, 35px);
        @include rem(padding, 10px);

        order: 1;
        background-color: $color-brand-primary;
        background-color: var(--color-brand-primary);
        color: $color-white;
        color: var(--color-white);
    }
}

// Minicart products
.c-minicart__products {
    overflow-y: auto;

    > ol {
        margin-bottom: 0;
    }

    @include mq($until: desktop) {
        flex: 1 1 0%;
    }

    @include mq($from: desktop) {
        @include rem(max-height, 420px);
    }
}

// Minicart footer
.c-minicart__footer {
    @include rem(padding, 15px);

    border-top: 1px solid $color-ui-grey-4;
    border-top: 1px solid var(--color-ui-grey-4);
    margin-top: auto;

    .c-minicart__basket-count {
        @include rem(margin-bottom, 15px);
    }

    .c-minicart__subtotal {
        @include rem(margin-bottom, 15px);

        margin-top: 0;
    }

    .c-minicart__checkout-button {
        @include font-size($base-font-size);
        @include rem(padding, 15px);
    }

    @include mq($from: desktop) {
        @include rem(padding, 15px);

        border-top: none;

        .c-minicart__basket-count {
            display: none;
        }

        .c-minicart__subtotal {
            @include rem(margin-top, 30px);
        }
    }
}
