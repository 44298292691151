//------------------------------------
// #FORM EXTEND
//------------------------------------

input {
    &[type=email],
    &[type=number],
    &[type=password],
    &[type=search],
    &[type=tel],
    &[type=text],
    &[type=url] {
        height: 40px;
    }
}

select {
    height: 40px;
}

legend {
    @extend .c-input-wrap__label;
}

button {
    cursor: pointer;
}
