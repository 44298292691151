//------------------------------------
// #AVATAR
//------------------------------------

.c-avatar {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);
    background-size: cover;
    background-position: center;
}

.c-avatar__text {
    text-align: center;
    line-height: 48px;
    color: $color-ui-grey-1;
    color: var(--color-ui-grey-1);
}
