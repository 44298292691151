//------------------------------------
// #IMAGES
//------------------------------------

// Basic image styles

img {
    height: auto;
    max-width: 100%;
}
