//------------------------------------
// #DASHBOARD
//------------------------------------

@import "dashboard/dashboard.dashboard-navigation";
@import "dashboard/dashboard.dashboard-orders";
@import "dashboard/dashboard.dashboard-quotes";
@import "dashboard/dashboard.tables";
@import "dashboard/dashboard.toolbar";

.c-dashboard__item {
    font-family: $font-family-default;

    @include rem(margin-bottom, 10px);

    @include mq($from: desktop) {
        @include rem(margin-bottom, 60px);
    }

    .c-dashboard-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        // .c-dashboard-content--row
        &--row {
            flex-flow: row wrap;
        }

        @include rem(margin-top, 20px);

        @include mq($from: desktop) {
            margin-top: 0;
        }
    }

    .c-dashboard__actions {
        margin-top: auto;
    }
}

.c-dashboard__title {
    @include rem(padding-bottom, 5px);
    @include rem(margin-bottom, 20px);
    @include rem(font-size, 15px);

    border-bottom: 1px solid $color-dashboard-title-border-bottom;

    @include mq($from: desktop) {
        @include rem(margin-bottom, 40px);
        @include rem(font-size, 18px);

    }
}

.c-dashboard__subtitle {
    @include rem(margin-bottom, 5px);

    @include rem(font-size, 14px);

    @include mq($from: desktop) {
        @include rem(font-size, 15px);

    }
}

.c-dashboard__title,
.c-dashboard__subtitle {
    letter-spacing: -.4px;
}

.c-dashboard-link {
    @include font-size(13px);
    @include link-color($color-brand-secondary, $color-ui-grey-2);
    @include rem(margin-right, 30px);

    .c-icon--arrow-left-sm {
        width: 15px;
        height: 8px;
        @include rem(margin-right, 5px);
    }
}

.c-customer__information,
.c-dashboard__address {
    @include font-size(12px, 1.4);

    @include mq($from: desktop) {
        @include font-size(14px, 1.4);
    }
}

.c-dashboard__address {
    font-style: normal;
    font-family: $font-family-secondary;
}
