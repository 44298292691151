//------------------------------------
// #MESSAGES
//------------------------------------

.c-messages {
    @include mobile-gutter();
}

.c-messages__message {
    @include rem(padding, 10px);
    @include rem(font-size, $font-size-delta);
    @include rem(margin-bottom, $spacing-unit);
    @include rem(border-radius, $message-border-radius);

    border: 1px solid;
    position: relative;
    float: left;
    width: 100%;

    p {
        margin-bottom: 0;
    }
}

.c-messages__message--success {
    color: $color-alert-success-text;
    color: var(--color-alert-success-text);
    background-color: $color-alert-success;
    background-color: var(--color-alert-success);
}

.c-messages__message--notice {
    color: $color-alert-notice-text;
    color: var(--color-alert-notice-text);
    background-color: $color-alert-notice;
    background-color: var(--color-alert-notice);
}

.c-messages__message--warning {
    color: $color-alert-warning-text;
    color: var(--color-alert-warning-text);
    background-color: $color-alert-warning;
    background-color: var(--color-alert-warning);
}

.c-messages__message--error,
.c-messages__message--alert {
    color: $color-alert-error-text;
    color: var(--color-alert-error-text);
    background-color: $color-alert-error;
    background-color: var(--color-alert-error);
}

.message-error {
    @extend .c-messages__message;
    @extend .c-messages__message--error;
}

.message-alert {
    @extend .c-messages__message;
    @extend .c-messages__message--alert;
}

.message-warning {
    @extend .c-messages__message;
    @extend .c-messages__message--warning;
}

.message-success {
    @extend .c-messages__message;
    @extend .c-messages__message--success;
}
