//------------------------------------
// #AKM2 VIEW SWITCHER
//------------------------------------

.c-akm2-view-switcher {
    display: none; // Control display with utility classes
}

.c-akm2-view-switcher__content {
    display: flex;
    align-items: center;

    @include mq($from: tablet) {
        text-decoration: none;
    }
}

.c-akm2-view-switcher__header {
    @include mq($from: tablet) {
        @include rem(margin-right, 10px);
    }
}

.c-akm2-view-switcher__heading {
    @include font-size(12px);
    @include font-weight(normal);

    margin-bottom: 0;
    color: $color-view-switcher-mobile-link;

    @include mq($from: tablet) {
        @include font-size(18px);
        @include font-weight(bold);

        color: $color-view-switcher-desktop-link;
    }
}

.c-akm2-view-switcher__subtext {
    display: none;
    text-align: right;
    color: $color-view-switcher-subtext;

    @include mq($from: tablet) {
        display: block;
    }
}

.c-akm2-view-switcher__image {
    display: none;
    border: 2px solid $color-view-switcher-image-border;

    img {
        display: block;
        width: 48px;
        height: 48px;
    }

    @include mq($from: tablet) {
        display: block;
    }
}
