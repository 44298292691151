//------------------------------------
// #HOMEPAGE
//------------------------------------

.cms-home .c-page__content {
    margin-top: 0;

    @include mq($until: tablet) {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }

    > .messages {
        @include mq($until: tablet) {
            margin-right: 0;
            margin-left: 0;
            width: 100%;
        }

        .messages {
            @include rem(margin-bottom, 10px);

            width: 100%;
        }
    }
}
