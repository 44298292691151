//------------------------------------
// #CHECKOUT STEP HEADING
//------------------------------------

.c-step-heading {
    @include rem(margin-top, 35px);
    @include rem(margin-bottom, 25px);
}

.c-step-heading__item {
    display: flex;
    position: relative;
    z-index: zIndex('progress-item');
    align-items: center;
}

.c-step-heading__title {
    @include font-size(24px);

    margin-bottom: 0;
}

.c-step-heading__step-number {
    @include font-weight(bold);
    @include font-size($font-size-epsilon, $progress-icon-dimension);
    @include rem(margin-right, 13px);

    background-color: $color-ui-grey-4;
    border-radius: 50%;
    color: $color-white;
    text-align: center;
    display: block;
    height: $progress-icon-dimension;
    width: $progress-icon-dimension;

    &--1 {
        background-color: $color-yellow;
    }

    &--2 {
        background-color: $color-brand-secondary;
    }

    &--3 {
        background-color: $color-red;
    }
}
