//------------------------------------
// #LINKS
//------------------------------------

a {
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);
    text-decoration: none;

    @include hocus() {
        text-decoration: underline;
    }

    &:visited,
    &:active {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }
}
