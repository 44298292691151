//------------------------------------
// #PAGE
//------------------------------------

body {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .o-header {
        flex-shrink: 0;
    }

    .o-wrapper {
        flex: 1 0 auto;
    }

    .o-footer {
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: auto;
    }
}
