//------------------------------------
// #REACT LISTINGS ITEM COUNT
//------------------------------------

.c-listings__item-count-wrapper {
    @include rem(margin-top, $base-spacing-unit);

    @include mq($from: tablet) {
        margin-top: 0;
    }
}
