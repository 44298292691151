//------------------------------------
// #STATIC CHECKOUT
//------------------------------------

.c-static-checkout {
    &__address-new,
    &__address-saved {
        display: none;

        &.selected-item {
            display: block;

            @include mq($from: 'tablet') {
                display: flex;
            }
        }
    }

    &__error-container {
        flex: 1;
        flex-basis: 100%;
    }
}
