//------------------------------------
// #BASKET
//------------------------------------

.c-basket {
    @include rem(margin-bottom, 15px);

    @include mq($from: tablet) {
        @include rem(margin-bottom, 30px);
    }
}

.c-basket__header {
    display: none;

    @include mq($from: desktop) {
        display: block;
    }
}

.c-basket__header-cell {
    @include rem(padding-bottom, 5px);

    display: inline-block;
    font-weight: bold;
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);
}

.c-basket__products {
    @include rem(margin-bottom, 15px);

    list-style: none;
    margin-left: 0;
}

.c-basket__subtotal {
    @include rem(font-size, $font-size-zeta);

    text-align: right;

    .c-basket__subtotal-value {
        @include rem(font-size, $font-size-delta);
        @include rem(margin-left, 10px);

        color: $color-brand-primary;
        color: var(--color-brand-primary);
        font-weight: bold;
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-basket-totals {
    @include mobile-gutter();

    @include mq($from: desktop) {
        display: none;
    }

    .c-cart-summary__totals {
        table {
            @include rem(margin-bottom, 10px);
        }

        th,
        td {
            vertical-align: baseline;
        }

        th {
            @include font-size($font-size-zeta);

            text-align: right;
        }

        td {
            @include font-size($font-size-delta);
            @include rem(padding-left, 10px);

            width: 1%;
            white-space: nowrap;
            color: $color-brand-primary;
            color: var(--color-brand-primary);
            font-weight: bold;
        }

        .totals.grand {
            border-top: none;

            > th,
            > td {
                @include rem(padding, 5px 0 5px 10px);
            }
        }

        table > tbody > tr:nth-last-child(2) {
            > th,
            > td {
                @include rem(padding-bottom, 5px);
            }
        }
    }
}
