//------------------------------------
// #DATA LIST
//------------------------------------

.o-list-data {
    text-align: right;
    width: 265px;
}

.o-list-data__title {
    float: left;
}
