//------------------------------------
// #ACTIONS EXTEND
//------------------------------------

.c-actions__toolbar {
    a {
        @include rem(font-size, $font-size-epsilon);
    }

    @include mq($until: tablet) {
        position: relative;
        top: 200px;
    }

    @media (max-width: 374px) {
        top: 250px;
    }
}

// Set top to 0 when the body has the checkout-payment class
body.checkout-payment .c-actions__toolbar {
    @include mq($until: tablet) {
        top: 0;
    }

    @media (max-width: 374px) {
        top: 0;
    }
}
