//------------------------------------
// #COMPARE SIDEBAR
//------------------------------------

.c-compare--sidebar {
    @include rem(margin-bottom, 30px);

    .c-compare__title {
        @include rem(margin-bottom, 10px);
    }

    .c-compare__count {
        @include font-size($font-size-zeta);
        @include rem(margin-left, 10px);

        display: inline-block;
    }

    .c-compare__remove {
        @include rem(margin-right, 5px);

        font-size: 35px;
        font-weight: normal;
        transform: translateY(7px);
        height: 20px;
        line-height: 20px;
        text-decoration: none;
    }

    ol {
        list-style: none;
        margin-left: 0;
    }

    ol > li {
        @include rem(margin-bottom, 5px);

        display: flex;
        align-items: baseline;
    }
}
