//------------------------------------
// #LIST-JUSTIFIED
//------------------------------------

.o-list-justified {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: justify;
    text-justify: distribute-all-lines;
    // Distribute items in IE
    font-size: 0;
    // Remove physical spaces between items */
    overflow: hidden;

    &::after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}

.o-list-justified__item {
    @include font-size($base-font-size, $base-line-height);

    display: inline-block;
}
