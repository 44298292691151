//------------------------------------
// #BADGES
//------------------------------------

.c-badge {
    display: flex;
    align-items: center;
    width: 75px;
    height: 75px;
    border-radius: 100%;
}

.c-badge--primary {
    background-color: $color-brand-primary;
    background-color: var(--color-brand-primary);
}

.c-badge--secondary {
    background-color: $color-brand-secondary;
    background-color: var(--color-brand-secondary);
}

.c-badge__text {
    @include rem(font-size, 14px);

    color: $color-white;
    color: var(--color-white);
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
}
