//------------------------------------
// #PAGE
//------------------------------------

body.account {
    @include mq($until: desktop) {
        .c-page__content {
            @include rem(margin-top, 30px);
        }
    }
}

.c-page__title {
    @include font-size(20px);

    @include mq($from: tablet) {
        @include font-size($font-size-gamma);
    }
}

.mobile-dropdown-active {
    @include mq($until: desktop) {
        overflow: visible !important;
    }
}
