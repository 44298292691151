//------------------------------------
// #PDP
//------------------------------------

.c-pdp__media {
    position: relative;

    @include mq($until: tablet) {
        @include rem(margin-bottom, spacer());
    }
}

.c-pdp__upper-content {
    @include mq($from: tablet) {
        @include rem(margin-bottom, 20px);
    }
}

.c-pdp__title {
    @include font-size(24px);
    line-height: 30px;
    text-transform: none;
    @include rem(margin-bottom, 10px);
}

.c-pdp__stock-status {
    display: flex;
}

.c-pdp__stock {
    display: inline-block;
    order: 1;
    @include rem(margin-left, 10px);
    font-weight: bold;
    @include font-size(12px);
}

.c-pdp__stock--available {
    color: $color-brand-secondary;
}

.c-pdp__stock--unavailable {
    color: $color-brand-primary;
}

.c-pdp__stock--limited {
    color: $color-brand-primary;
}

.c-pdp__stock--special {
    color: $color-brand-primary;
}

.c-pdp__stock-icon {
    @include rem(width, 12px);
    @include rem(height, 10px);
    @include rem(margin-right, 2px);
}

.c-pdp__qty {
    @include rem(margin, 20px 0);
}

.c-pdp__price {

    .c-price-box {
        min-height: auto;
        @include rem(margin-top, 15px);
    }

    .c-product-price__price {
        @include font-size(24px);
    }

}

.c-pdp__basket-btn {
    @include rem(margin-right, 10px);
    @include rem(height, 40px);

    min-width: auto;
    width: auto;
    font-size: $base-font-size;

    @include mq($from: tablet) {
        width: 50%;
    }

    @include mq($from: desktop) {
        @include rem(margin-right, 15px);

        font-size: $font-size-epsilon;
    }

}

// PDP Main Content

//Secondary Content
.c-pdp__secondary-content {
    .c-pdp__review-container {
        @include rem(margin-bottom, 20px);

        @include mq($until: tablet) {
            display: none;
        }
    }
}

// PDP Tabs
.c-pdp__tabs {
    @include mq($from: tablet) {
        display: flex;
        flex-wrap: wrap;
    }

    .c-product-reviews-mobile {
        @include mq($from: tablet) {
            display: none;
        }
    }
}

.c-pdp__tab-title {
    @include rem(font-size, 18px);
    @include rem(border-bottom, 1px solid $color-mono-c);
    @include rem(padding-top, 10px);
    @include rem(padding-bottom, 10px);
    @include link-color($color-black, $color-brand-secondary);

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-black;
    text-decoration: none;
    width: 100%;

    &::after {
        @extend .c-icon--secondary-down-arrow-icon;

        @include rem(width, 15px);
        @include rem(height, 10px);
        @include rem(margin-left, 5px);

        content: ' ';
        display: inline-block;
        flex-shrink: 0;
    }

    @include hocus() {
        text-decoration: none;
    }

    @include mq($from: tablet) {
        width: auto;
        padding: 0;
        border-bottom: none;
        @include link-color($color-ui-grey-4, $color-black);

        &::after {
            display: none;
        }

        @include hocus() {
            @include rem(border-bottom, 3px solid $color-brand-secondary);
            @include rem(padding-bottom, 2px);
        }
    }
}

.c-pdp__tab {
    @include hocus() {
        outline: none;
    }

    @include mq($from: tablet) {
        @include rem(padding, 10px 20px 10px 0);
    }

    &.active {
        .c-pdp__tab-title {
            color: $color-black;

            @include mq($from: tablet) {
                @include rem(border-bottom, 4px solid $color-brand-secondary);
                @include rem(padding-bottom, 3px);
            }

            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.c-pdp-tabs__content {
    @include rem(padding-top, $base-spacing-unit-small);
    @include rem(padding-bottom, $base-spacing-unit-small);

    @include mq($from: tablet) {
        @include rem(padding, $base-spacing-unit 0);

        border-bottom: none;
        order: 1;
        width: 100%;
    }
    @include mq($from: desktop) {
        padding: 0;
    }
    p {
        @include rem(font-size, 14px);
        @include rem(margin-bottom, 10px);
        font-family: $font-family-secondary;
    }
}

.c-pdp {
    a {
        @include font-size(12px);
    }

    .c-wishlist__link {
        margin-bottom: 0;
    }
}

.c-pdp__pdf-link {
    @include rem(font-size, 18px);

    text-decoration: none;

    &-item {
        vertical-align: middle;
    }

    .c-icon--download-icon {
        @include rem(width, 22px);
        @include rem(height, 22px);

        display: inline-block;
    }
}

.c-pdp_float-right {
    @include mq($from: desktop) {
        float: right;
    }
}
