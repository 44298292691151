//------------------------------------
// #HORIZONTAL RULE
//------------------------------------

hr,
.c-horizontal-rule {
    @include rem(margin-bottom, 10px);

    background: none;
    margin-top: 0;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
