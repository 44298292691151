//------------------------------------
// #DASHBOARD QUOTES
//------------------------------------

.c-quote-view-links {
    align-self: flex-end;
}

.c-quote-information {
    flex-basis: auto;
    flex-grow: 1;
    margin: 0;

    .c-quote-information__label {
        display: block;
        color: $color-dashboard-th;

        @include font-weight(bold);
    }
}

.c-quote-view__buttons {
    .c-btn {
        @include rem(margin-bottom, 10px);

        @include mq($from: desktop) {
            margin-bottom: 0;
        }
    }
}
