//------------------------------------
// #FACETCRUMB
//------------------------------------

.c-facetcrumb {
    @include rem(padding, 2px 15px);

    background-color: $color-brand-primary;
    background-color: var(--color-brand-primary);
    border-radius: 12px;
    display: inline-block;

    &, a {
        color: $color-white;
        color: var(--color-white);
    }
}

.c-facetcrumb__remove {
    @include rem(margin-left, 2px);
    
    font-size: $font-size-gamma;
    line-height: 0;
    position: relative;
    top: 3px;

    @include hocus() {
        text-decoration: none;
    }
}
