//------------------------------------
// #PROMO FORM
//------------------------------------

.c-cart__promo-form {
    text-align: right;

    .c-cart__promo-code__title {
        @include font-weight(bold);
        @include rem(margin-top, 10px);
        @include rem(margin-bottom, 10px);
        @include rem(font-size, 16px);

        font-family: $font-family-default;
        background: $color-promo-code-btn-bg;
        border: none;
        color: $color-promo-code-font;
        padding-right: 0;

        .c-icon--chevron-down-icon {
            @include rem(margin-left, 5px);

            width: 14px;
            height: 11px;
            transition: transform 0.1s linear;
        }

        &[aria-expanded="true"] {
            .c-icon--chevron-down-icon {
                transform: rotate(180deg);
            }
        }

        @include mq($from: tablet) {
            text-align: right;
        }
    }

    label[for="coupon_code"] {
        @include visually-hidden();
    }
}
