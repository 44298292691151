//------------------------------------
// #PAGE
//------------------------------------

.c-page__content {
    @include rem(margin-bottom, 50px);
    @include rem(margin-top, 18px);

    // Override region inner width for mobile so we can have full width pages. Implementing this in the `o-region`
    // classes alters the layout for more elements than just the page so would require more work
    // TODO: Implement at `o-region` level
    width: 100%;

    // tablet sizes
    @include mq($from: tablet, $until: desktop) {
        width: map-get($mq-breakpoints, tablet);
    }

    // desktop sizes
    @include mq($from: desktop, $until: wide) {
        width: map-get($mq-breakpoints, desktop);
    }

    // wide sizes
    @include mq($from: wide) {
        width: map-get($mq-breakpoints, wide);
    }

    @include mq($from: tablet) {
        @include rem(margin-top, 30px);
    }
}

.c-page__content--footer {
    width: 100%; // Force 100% width for the footer
}

.c-page__title-wrapper {
    @include rem(margin-bottom, 18px);
    @include mobile-gutter();

    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include mq($from: tablet) {
        @include rem(margin-bottom, 20px);
    }

    .c-button {
        flex-shrink: 0;
    }
}

.c-page__title {
    @include rem(font-size, $font-size-gamma);

    margin-bottom: 0;
    line-height: 1.57; // Restore default on mobile

    @include mq($from: tablet) {
        @include font-size($font-size-beta);
    }
}

.c-page__columns--cms {
    @include mobile-gutter();
}

body.account {
    @include mq($until: desktop) {
        > .o-region {
            position: relative;
        }

        .c-page__content {
            @include rem(margin-top, 71px);
        }

        .c-messages {
            @include rem(margin-top, $base-layout-gutter);
        }
    }
}

.mobile-dropdown-active {
    @include mq($until: desktop) {
        -webkit-overflow-scrolling: touch !important;
        overflow: hidden !important;
        height: 100% !important;
        cursor: pointer;
    }
}
