//------------------------------------
// #MINISEARCH
//------------------------------------

.c-minisearch {
    display: flex;

    .c-input-wrap {
        flex-grow: 1;
        margin-bottom: 0;
    }

    .c-form__control {
        height: 35px;
        margin-bottom: 0;
    }

    .c-btn--search {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        min-width: auto;
        padding: 0;
        width: 35px;

        .c-icon {
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
        }
    }

    * {
        white-space: normal;
    }

}

.c-minisearch--desktop {
    display: none;

    @include mq($from: desktop) {
        display: flex;
    }
}

.c-minisearch--mobile {
    @include rem(padding, 12px);
    @include rem(margin-bottom, $spacing-unit / 2);

    background: $color-blue;

    .c-form__control {
        background-color: $color-white;
        margin-bottom: 0;
    }

    .c-btn--search {
        background-color: $color-blue;

        &:hover,
        &:focus {
            background-color: darken($color-blue, 10%);
        }
    }
}
