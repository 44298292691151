//------------------------------------
// #COLLAPSIBLE
//------------------------------------

.c-collapsible {
    border-top: 1px solid $color-ui-grey-2;
    border-top: 1px solid var(--color-ui-grey-2);
    border-bottom: 1px solid $color-ui-grey-2;
    border-bottom: 1px solid var(--color-ui-grey-2);

    &.active,
    &._active {
        .c-collapsible__title {
            @include rem(margin-bottom, 15px);

            border-bottom: 1px solid $color-ui-grey-4;
            border-bottom: 1px solid var(--color-ui-grey-4);

            @include mq($from: desktop) {
                border-bottom: none;
            }
        }

        .c-collapsible__title .c-icon {
            transform: rotate(180deg);
        }
    }

    @include mq($from: desktop) {
        @include rem(margin-bottom, 30px);

        border-top: none;
        border-bottom: none;
    }
}

.c-collapsible + .c-collapsible {
    border-top: none;
}

.c-collapsible__title {
    @include font-size($font-size-delta);
    @include mobile-gutter();
    @include rem(padding-top, 15px);
    @include rem(padding-bottom, 15px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    margin-bottom: 0;
    cursor: pointer;

    .c-icon {
        @include rem(width, 30px);
        @include rem(height, 30px);

        pointer-events: none;
    }
}

.c-collapsible__content {
    @include rem(margin-bottom, 30px);
    @include mobile-gutter();

    display: none; // Prevent flash of open content when loading JS

    @include mq($from: desktop) {
        margin-bottom: 0;
    }

    .c-actions__toolbar {
        padding-left: 0;
        padding-right: 0;
    }
}
