//------------------------------------
// #CHECKBOX OPTION
//------------------------------------

// Inline Option:
// A Input checkbox button and inline text element.
// Requires the label "for" attribute and input "id" attribute to match.
//
// div.c-checkbox-wrap.c-input-wrap
//   input(type='checkbox', name='checkbox-option', checked='checked').c-checkbox-option__input
//   span.c-checkbox-option__checkbox
//   label.c-checkbox-option__label I agree to this checkbox

.c-checkbox-wrap {
    @include rem(margin, 0 0 24px);
    
    position: relative;

    input[type=checkbox],
    .c-checkbox-option__input[type=checkbox] {
        position: absolute;
        opacity: 0;
        z-index: zIndex('checkbox-input');

        &:checked ~ .c-checkbox-option__checkbox {
            background: $color-checkbox-checked-bg;
            background: var(--color-checkbox-checked-bg);
            border: none;
        }

        &:checked ~ .c-checkbox-option__checkbox::after {
            display: block;
        }
    }

    label,
    .c-checkbox-option__label {
        @include font-size(14px);
        @include rem(padding-left, 30px);
        @include rem(margin-right, 30px);

        display: inline-block;
        position: relative;
        cursor: pointer;
    }

    .c-checkbox-option__checkbox {
        @include rem(margin-right, 15px);
        
        position: absolute;
        left: 0;
        top: 4px;
        height: 16px;
        width: 16px;
        background: $color-checkbox-bg;
        background: var(--color-checkbox-bg);
        border-style: solid;
        border-color: $color-checkbox-border;
        border-color: var(--color-checkbox-border);
        border-width: 2px;
        border-radius: $checkbox-radius;

        &::after {
            @include rem(margin, 3px);

            content: '';
            width: 14px;
            height: 14px;
            line-height: 20px;
        }
    }
}
