//------------------------------------
// #NAV MAIN
//------------------------------------

.c-nav-main-strip {
    @include rem(padding, 0 100px);

    background-color: $color-main-nav-bg;
    background-color: var(--color-main-nav-bg);
}

.c-nav-main {
    @include font-size(14px, 15/14);

    margin-left: 0;
    padding: 0;
    list-style: none;
    display: table;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    background-color: $color-main-nav-bg;
    background-color: var(--color-main-nav-bg);
}

.c-nav-main__item {
    display: table-cell;
}

.c-nav-main__link {
    @include rem(padding, $spacing-unit-small $spacing-unit);

    display: block;
    
    &,
    &:visited {
        color: $color-main-nav;
        color: var(--color-main-nav);
    }

    @include hocus('.is-current') {
        text-decoration: none;
    }
}
