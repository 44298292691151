//------------------------------------
// #BREADCRUMBS EXTEND
//------------------------------------

.c-breadcrumbs {
    @include rem(margin-bottom, $base-spacing-unit);

    color: $color-brand-secondary;
}

.c-breadcrumbs__item {
    &::after {
        @include font-size(11px);
        @include rem(margin-left, 4px);
        @include rem(margin-right, 4px);

        color: $color-body;
    }

    // See core base theme
    &:not(:last-of-type)::after {
        content: '\002F';
    }

    @include mq($until: tablet) {
        display: none;

        &::after {
            display: none;
        }

        &:nth-last-child(2) {
            display: inline-block;
        }

        .c-breadcrumbs__link {
            @include link-color($color-body, lighten($color-body, 10%));

            font-weight: bold;

            &:before {
                @include rem(margin-right, 5px);
                @include rem(font-size, 21px);

                display: inline-block;
                content: '\21FD';
                position: relative;
                color: $color-brand-secondary;
                top: 1px;
            }
        }
    }
}

.c-breadcrumbs__item:last-child {
    .c-breadcrumbs__link {
        @include link-color($color-body, lighten($color-body, 10%));

        font-weight: bold;
    }
}

.c-breadcrumbs__link {
    @include link-color($color-brand-secondary, darken($color-brand-secondary, 10%));
    @include rem(font-size, 12px);

    @include hocus() {
        text-decoration: none;
    }

    text-decoration: none;
}
