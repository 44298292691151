//------------------------------------
// #OBJECTS BOX EXTEND
//------------------------------------

$box: (
    small: rem($base-layout-gutter * 0.5),
    medium: rem($base-layout-gutter * 1),
    large: rem($base-layout-gutter * 2)
);

@mixin box($breakpoint: '') {
    .o-box#{$breakpoint} {
        // o-box
        padding: map-get($box, medium);

        &.o-layout__item {
            padding: map-get($box, medium) map-get($box, medium) map-get($box, medium) (rem($spacing-unit) + map-get($box, medium));
        }

        // o-box#{$breakpoint}--vertical
        &--vertical {
            padding: map-get($box, medium) 0;

            &.o-layout__item {
                padding: map-get($box, medium) 0 map-get($box, medium) rem($spacing-unit);
            }
        }

        // o-box#{$breakpoint}--horizontal
        &--horizontal {
            padding: 0 map-get($box, medium);

            &.o-layout__item {
                padding: 0 map-get($box, medium) 0 (rem($spacing-unit) + map-get($box, medium));
            }
        }

        // o-box#{$breakpoint}--small
        &--small {
            padding: map-get($box, small);

            &.o-layout__item {
                padding: map-get($box, small) map-get($box, small) map-get($box, small) (rem($spacing-unit) + map-get($box, small));
            }

            // o-box#{$breakpoint}--small-vertical
            &-vertical {
                padding: map-get($box, small) 0;

                &.o-layout__item {
                    padding: map-get($box, small) 0 map-get($box, small) rem($spacing-unit);
                }
            }

            // o-box#{$breakpoint}--small-horizontal
            &-horizontal {
                padding: 0 map-get($box, small);

                &.o-layout__item {
                    padding: 0 map-get($box, small) 0 (rem($spacing-unit) + map-get($box, small));
                }
            }
        }

        // o-box#{$breakpoint}--large
        &--large {
            padding: map-get($box, large);

            &.o-layout__item {
                padding: map-get($box, large) map-get($box, large) map-get($box, large) (rem($spacing-unit) + map-get($box, large));
            }

            // o-box#{$breakpoint}--large-vertical
            &-vertical {
                padding: map-get($box, large) 0;

                &.o-layout__item {
                    padding: map-get($box, large) 0 map-get($box, large) rem($spacing-unit);
                }
            }

            // o-box#{$breakpoint}--large-horizontal
            &-horizontal {
                padding: 0 map-get($box, large);

                &.o-layout__item {
                    padding: 0 map-get($box, large) 0 (rem($spacing-unit) + map-get($box, large));
                }
            }
        }
    }
}

@include box();

@include mq($until: tablet) {
    @include box('-mobile-only');
}

@include mq($from: tablet) {
    @include box('-tablet');
}

@include mq($from: tablet, $until: desktop) {
    @include box('-tablet-only');
}

@include mq($from: desktop) {
    @include box('-desktop');
}
