//------------------------------------
// #SHARED
//------------------------------------

// Shared declarations for certain elements.

// Always declare margins in the same direction:
// https://csswizardry.com/2012/06/single-direction-margin-declarations
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
    @include rem(margin-bottom, $base-spacing-unit);
}

ul, ol,
dd {
    @include rem(margin-left, double($base-spacing-unit));
}
