//------------------------------------
// #FOOTER
//------------------------------------

.c-footer {
    @include rem(padding-top, 45px);

    background-color: $color-footer-bg;
    background-color: var(--color-footer-bg);
    border-top: 1px solid $color-ui-grey-3;
    border-top: 1px solid var(--color-ui-grey-3);

    // .c-footer--checkout
    &--checkout {
        background-color: transparent;
        border-top: none;
        padding-top: 0;

        @include mq($from: desktop) {
            @include rem(padding-top, 45px);
        }
    }
}

.c-footer__wrapper {
    @include mq($from: tablet) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.c-footer-links {
    @include rem(margin-bottom, 10px);
    @include rem(padding, 0 10px 10px);

    border-bottom: 2px solid $color-ui-grey-4;
    border-bottom: 2px solid var(--color-ui-grey-4);

    &__title {
        @include font-size($font-size-zeta);

        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        margin-bottom: 0;

        &::after {
            @include rem(height, 14px);
            @include rem(width, 16px);
            @include rem(background-size, 35px);

            content: ' ';
            background-image: url(../images/icons/svg/arrow-expand-down.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            display: block;
            float: right;
            transform: translateY(5px);
        }

        &.ui-state-active:after {
            transform: rotate(180deg);
        }
    }

    > ul {
        @include font-size($font-size-zeta);
        @include rem(padding-top, 10px);

        list-style: none;
        margin: 0;
    }

    &--no-border {
        border-bottom: none;
    }

    @include mq($from: tablet) {
        border-bottom: none;

        &__title {
            text-transform: none;
            color: $color-ui-grey-2;
            color: var(--color-ui-grey-2);

            &::after {
                display: none;
            }
        }

        > ul {
            display: block !important;
            padding-top: 0;
        }
    }
}

.c-footer__contact-number {
    @include rem(margin-top, 18px);

    p {
        @include font-size($font-size-epsilon);

        a {
            color: $color-black;
            color: var(--color-black);
        }
    }
}
