//------------------------------------
// #PRICE
//------------------------------------

.c-price {
    &__label {
        @extend .u-visually-hidden;
    }

    &--special {
        color: $color-price-special;
        color: var(--color-price-special);
    }

    &--old {
        @include font-size($font-size-epsilon);
        @include rem(margin-left, 10px);

        color: $color-body;
        color: var(--color-body);
        display: inline-block;
        font-weight: normal;
        text-decoration: line-through;
    }
}

.price-box {
    display: flex;
    align-items: center;
}

.c-product {
    .price-box {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .c-price--special {
        @include font-size($font-size-delta);

        font-weight: bold;

        &::before {
            content: 'Now';
        }
    }

    .c-price--old {
        @include font-size($font-size-zeta);

        text-decoration: none;
        margin-left: 0;

        &::before {
            content: 'Was';
        }
    }
}
