//------------------------------------
// #TABLE
//------------------------------------

.c-table {
    width: 100%;
    @include rem(margin-bottom, 40px);
    @include font-size(12px);

    caption {
        text-align: left;
        color: $color-black;
        font-weight: bold;
        border-bottom: 1px solid $color-mono-b;
        @include rem(padding-bottom, 10px);
        @include rem(margin-bottom, 20px);
        @include font-size(16px);
    }

    > thead > tr {
        border: none;
    }

    > thead > tr > th {
        color: $color-black;
    }

    > thead,
    > tbody {
        > tr > th,
        > tr > td {
            padding-left: 0;
        }
    }

    > tbody > tr {
        border-bottom: none;
    }

    @include mq($until: desktop) {
        > thead,
        > tbody {
            > tr,
            > tr > th,
            > tr > td {
                padding: 0;
                display: block;
            }
        }

        > thead > tr {
            display: none;
        }

        > tbody > tr {
            @include rem(padding-bottom, 20px);
        }

        > tbody > tr > td[data-th]::before {
            content: attr(data-th);
            color: $color-black;
            font-weight: bold;
            @include rem(margin-right, 5px);
        }
    }
}

.c-table__col--actions {
    @include mq($from: desktop) {
        text-align: right;
    }
}
