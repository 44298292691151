//------------------------------------
// #PRODUCT TOOLBAR
//------------------------------------

.c-plp-toolbar__top {
    @include rem(margin-bottom, 15px);

    .c-mobile-sort {
        height: 36px;
    }
}

.c-plp-toolbar__top,
.c-plp-toolbar__bottom {
    .c-product-toolbar__limiter {
        text-align: right;
    }
}

.c-plp-toolbar__bottom {
    @include rem(padding-bottom, 40px);
}

.c-plp-toolbar__amount {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0;

    @include rem(margin-bottom, 10px);

    .c-plp-toolbar__count {
        @include rem(margin, 2px 0);
        @include rem(font-size, 12px);
    }

    @include mq($from: desktop) {
        display: block;
    }
}

.c-plp-toolbar__sorter {
    display: flex;
    align-items: center;

    .c-product-toolbar__label {
        display: none;

        @include mq($from: tablet) {
            display: inline-flex;

            @include rem(margin-right, 5px);
        }
    }
}

.c-product-toolbar__view {
    width: 25px;
    height: 25px;
    @include mq($until: tablet) {
        display: none;
    }
}

.c-product-toolbar__view--last {
    margin-right: 0;
}

.c-product-toolbar__select-icon {
    position: relative;
    flex-grow: 1;

    &:after {
        content: "";
        position: absolute;
        top: 15px;
        right: 10px;
        background-image: url('../icons/svg/secondary-down-arrow-icon.svg');
        background-repeat: no-repeat;
        width: 12px;
        height: 8px;
        display: inline-block;
    }

    @include mq($from: tablet) {
        display: inline-block;
        flex-grow: unset;
    }
}

.c-product-toolbar__select {
    height: 36px;
    background-color: $color-off-white;
    border: 1px solid $color-off-white;

    @include rem(font-size, 13px);
}
