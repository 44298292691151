//------------------------------------
// #QUANTITY
//------------------------------------

.c-qty {
}

.c-qty__input {
    width: 100px;
}
