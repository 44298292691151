//------------------------------------
// #REACT LISTINGS ATTRIBUTES
//------------------------------------

.c-listings__item-attribute-location--after_image {
    @include rem(padding, 5px $base-spacing-unit-small);

    background-color: $color-attribute-after-image-bg;
    background-color: var(--color-attribute-after-image-bg);
    color: $color-attribute-after-image-text;
    color: var(--color-attribute-after-image-text);
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;

    .c-listings__item-attribute-label {
        @include rem(margin-right, 5px);
    }
}
