//------------------------------------
// #CAROUSEL
//------------------------------------

.c-carousel {
    @include rem(margin-bottom, 30px);
    @include rem(padding-left, 60px);
    @include rem(padding-right, 60px);

    display: none; // Hides carousel on initial load to be displayed after slick is initialised

    &.slick-initialized {
        display: block;
    }

    .slick-track > .slick-slide {
        @include rem(padding, 0 15px);

        @include mq($from: tablet) {
            @include rem(max-width, 295px, true);
        }
    }
}

.c-button--previous,
.c-button--next {
    @include rem(height, 42px);
    @include rem(width, 42px);

    background-color: transparent;
    background-image: url('../images/icons/svg/arrow-expand-down.svg');
    background-size: 50px;
    background-position: 50%;
    display: block;

    @include hocus() {
        opacity: .5;
    }
}

.c-button--previous {
    transform: rotate(90deg);
}

.c-button--next {
    transform: rotate(-90deg);
}

// Slick Carousel - http://kenwheeler.github.io/slick/
// Styles modified for Accelerator carousel

// Slider
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.slick-track:before,
.slick-track:after {
    content: '';
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    @include rem(min-height, 1px);

    display: none;
    float: left;
    height: 100%;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

// Arrows
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
}

.slick-prev {
    left: 0;
}

[dir='rtl'] .slick-prev {
    right: 0;
    left: auto;
}

.slick-prev:before {
    content: '←';
}

[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: 0;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: 0;
}

.slick-next:before {
    content: '→';
}

[dir='rtl'] .slick-next:before {
    content: '←';
}

// Dots
.slick-dotted.slick-slider {
    @include rem(margin-bottom, 30px);
}

.slick-dots {
    @include rem(bottom, 15px);

    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    @include rem(margin, 0 5px);

    position: relative;
    display: inline-block;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    @include rem(padding, 5px);
    @include rem(width, 15px);
    @include rem(height, 15px);
    @include rem(border-radius, 5px);

    font-size: 0;
    line-height: 0;
    display: block;
    cursor: pointer;
    color: transparent;
    border: 2px solid $color-black;
    border: 2px solid var(--color-black);
    outline: none;
    background: transparent;

    @include mq($from: tablet) {
        @include rem(width, 20px);
        @include rem(height, 20px);
    }
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li.slick-active button {
    background-color: $color-black;
    background-color: var(--color-black);
}

.slick-slide {
    display: none;

    &:first-child {
        display: block;
    }
}

.slick-initialized .slick-slide {
    display: block;
}
