//------------------------------------
// #UTILITIES EXTEND
//------------------------------------

.u-fixed-height {
    height: 100%;
    overflow: hidden;
}

.u-placeholder {
    outline: 2px solid magenta;
}

// Above
.u-margin-above-1\/2 {
    @include rem(margin-top, spacer(0.5), true);
}

// Below
.u-margin-below-1\/2 {
    @include rem(margin-bottom, spacer(0.5), true);
}

.u-margin-below-0 {
    @include rem(margin-bottom, spacer(0), true);
}

// Left
.u-margin-left-small {
    @include rem(margin-left, spacer(0.3333333333333), true);
}

// For inline dividers (see styleguide)
.u-divider {
    height: 0 !important;
}

// Add a right-angled quote to links that imply movement
.u-go {
    &::after {
        content: "\00A0" "\00BB" !important;
    }
}

.u-go--back {
    &::before {
        content: "\00AB" "\00A0" !important;
    }
}

// Utility background colours
.u-bg-primary {
    background-color: $color-brand-primary !important;
}

.u-bg-secondary {
    background-color: $color-brand-secondary !important;
}

.u-bg-alternative {
    background-color: $color-ui-grey-2 !important;
}

.u-full-width {
    width: 100%;
    max-width: 100%;
}

.u-vertical-align-top {
    vertical-align: top;
}

// Flex box helpers
.u-flex-align-central {
    display: flex;
    justify-content: center;
    align-items: center;
}

.u-plain-link,
.u-plain-link:visited {
    color: $color-body !important;
    text-decoration: none;
}

.u-fluid-image {
    width: 100%;
}

.u-block-link {
    @include block-content();
}

.u-pre-wrap {
    white-space: pre-wrap;
}
