//------------------------------------
// #BUTTONS
//------------------------------------

.c-button {
    @include font-size($font-size-epsilon);
    @include rem(padding, 5px 10px);

    display: inline-block;
    text-align: center;
    border-radius: $base-border-radius;
    border: 0;
    transition: $base-transition;
    cursor: pointer;

    @include hocus() {
        text-decoration: none;
    }

    @include mq($from: desktop) {
        @include font-size($font-size-delta);
        @include rem(padding, 15px 20px);
    }
}

.c-button--primary {
    background-color: $color-btn-bg;
    background-color: var(--color-btn-bg);

    &,
    &:active,
    &:visited {
        color: $color-btn;
        color: var(--color-btn);
    }

    @include hocus() {
        color: $color-btn-hover;
        color: var(--color-btn-hover);
        background-color: $color-btn-bg-hover;
        background-color: var(--color-btn-bg-hover);
    }
}

.c-button--secondary {
    background-color: $color-btn-secondary-bg;
    background-color: var(--color-btn-secondary-bg);

    &,
    &:active,
    &:visited {
        color: $color-btn-secondary;
        color: var(--color-btn-secondary);
    }

    @include hocus() {
        color: $color-btn-secondary-hover;
        color: var(--color-btn-secondary-hover);
        background-color: $color-btn-secondary-bg-hover;
        background-color: var(--color-btn-secondary-bg-hover);
    }
}

.c-button--outline {
    background-color: transparent;
    border: 1px solid $color-btn-outline-border;
    border: 1px solid var(--color-btn-outline-border);

    &,
    &:active,
    &:visited {
        color: $color-btn-outline;
        color: var(--color-btn-outline);
    }

    @include hocus() {
        color: $color-btn-outline-hover;
        color: var(--color-btn-outline-hover);
        border-color: $color-btn-outline-border-hover;
        border-color: var(--color-btn-outline-border-hover);
    }
}

.c-button--plain {
    background-color: transparent;

    &,
    &:active,
    &:visited {
        color: $color-btn-plain;
        color: var(--color-btn-plain);
    }

    @include hocus() {
        color: $color-btn-plain-hover;
        color: var(--color-btn-plain-hover);
    }
}

.c-button[disabled],
.c-button--disabled {
    color: $color-btn-disabled;
    color: var(--color-btn-disabled);
    border-color: $color-btn-disabled-border;
    border-color: var(--color-btn-disabled-border);
    cursor: not-allowed;

    &:not(.c-button--plain):not(.c-button--outline) {
        background: $color-btn-disabled-bg;
        background: var(--color-btn-disabled-bg);
    }
}

.c-button--large {
    @include font-size($font-size-gamma);
    @include rem(padding, 15px 20px);
}

.c-button--small {
    @include font-size(14px);
    @include rem(padding, 5px 15px);

    @include mq($until: 'tablet') {
        @include font-size($font-size-zeta);
    }
}

.c-button--wide {
    width: 100%;
}

// Extends to cater for Magento's default classes
.c-button.action-primary {
    @extend .c-button--primary;
}

.c-button.action-secondary {
    @extend .c-button--secondary;
}

.c-button--icon {
    @include rem(padding, 2px);
    @include rem(height, 40px);
    @include rem(width, 40px);
}

.c-button--share {
    @include font-size($font-size-zeta);
    @include rem(padding-right, 30px);
    @include rem(background-size, 20px);

    background-image: url('../images/icons/svg/email.svg');
    background-repeat: no-repeat;
    background-position: right 50%;
    font-weight: normal;
}

// Force a mobile button to have the same font size and padding as desktop
.c-button--desktop {
    @include font-size($font-size-delta);
    @include rem(padding, 15px 20px);
}

// Force a desktop button to have the same font size and padding as mobile
.c-button--mobile {
    @include font-size($font-size-epsilon);
    @include rem(padding, 5px 10px);
}

// A slim button variant (used on basket)
.c-button--slim {
    @include rem(padding-top, 8px);
    @include rem(padding-bottom, 8px);
}

// Date picker button is generated
button.ui-datepicker-trigger {
    @extend .c-button;
    @extend .c-button--secondary;
    @extend .c-button--small;
}
