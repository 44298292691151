//------------------------------------
// #GOOGLE MAPS
//------------------------------------

.c-google-maps {
    &__location {
        overflow: hidden;
        margin-bottom: 0;

        &__title {
            @extend .c-checkout__step__heading;

            margin-bottom: 0;
        }

        address {
            font-style: normal;
            margin-bottom: 0;
        }
    }

    &__opening-hours {
        margin: 18px 0 30px;

        &__title {
            margin-bottom: 5px;
        }
    }
}
