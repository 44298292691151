//------------------------------------
// #TYPOGRAPHY
//------------------------------------

// Mixins to help generating typographical rules.

// Generate a font-size and baseline-compatible line-height.
@mixin font-size($font-size, $line-height: auto, $important: false) {
    @if ($important == true) {
        $important: !important;
    } @else {
        $important: null;
    }

    font-size: $font-size $important;
    font-size: ($font-size / $base-font-size) * 1rem $important;

    @if $line-height == auto {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size) $important;
    } @else {
        @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height $important;
        } @else if ($line-height != none and $line-height != false) {
            @warn "D’oh! ‘#{$line-height}’ is not a valid value for `line-height`.";
        }
    }
}

// Map keywords onto our numbered weights for convenience.
@mixin font-weight($weight) {
    @if ($weight == normal) {
        font-weight: 400;
    } @else if ($weight == semi-bold) {
        font-weight: 600;
    } @else if ($weight == bold) {
        font-weight: 700;
    } @else if ($weight == black) {
        font-weight: 900;
    } @else {
        @warn "#{$weight} is not a valid font-weight.";
    }
}

@mixin truncate($width: 100%) {
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
