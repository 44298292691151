//------------------------------------
// #FORMS EXTEND
//------------------------------------

.c-form__control,
.c-form__control.c-form__control {
    border-radius: 0;
}

.c-login__sign-in,
.c-login__sign-up {
    @include rem(margin-bottom, 36px);

    .c-btn {
        @include rem(margin-top, 24px);
    }

    .actions-toolbar a {
        @include rem(font-size, $font-size-epsilon);
    }
}

.c-login__sign-up {
    @include mq($until: tablet) {
        h2 {
            @include rem(padding-top, 24px);

            border-top: 1px solid $color-ui-grey-5;
        }
    }
}

.c-toolbar__control {
    display: flex;
    align-items: center;
    margin-right: auto;

    label,
    &-suffix {
        flex-shrink: 0;
    }

    label {
        @include rem(margin-right, 5px);

        @include mq($until: tablet) {
            @include visually-hidden();
        }
    }

    &-suffix {
        @include rem(margin-left, 5px);
    }

    .c-dropdown.limiter-options {
        width: 50px;
    }

    .c-dropdown-btn {
        @include rem(padding, 3px 10px);
    }
}

.c-form__radio {
    .c-form__group & {
        padding-left: 1.5em;
    }

    .c-form__group--inline & {
        margin-right: rem($base-layout-gutter);
    }

    .c-indicator {
        height: 1em;
        top: 0.3em;
        width: 1em;
    }

    input:checked {
        & ~ .c-indicator {
            &::before {
                height: 0.5em;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 0.5em;
            }
        }
    }
}

.c-form__group.required label::after {
    color: $color-alert-error;
    content: ' *';
}

.c-form__group label {
    font-weight: 600;
}

.c-form__fieldset--contact-technical-section[aria-hidden="true"] {
    display: none;
}
