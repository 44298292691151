//------------------------------------
// #LIST-STACKED EXTEND
//------------------------------------

.o-list-stacked {
    margin: 0;

    // v2.4.1 port
    li:not(:last-child) {
        @include rem(margin-bottom, 3px);
    }
}
