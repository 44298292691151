//------------------------------------
// #MINI LISTER
//------------------------------------

.c-mini-lister {
    @include rem(margin, 40px 0);

    position: relative;

    @include mq($until: mobile) {
        @include rem(margin, 20px 0);
    }

    .c-btn {
        @include rem(margin-bottom, 10px);

        width: 100%;

        @include mq($until: tablet) {
            @include rem(padding-right, 10px);
            @include rem(padding-left, 10px);
            @include font-size(14px);
        }
    }

    .minimal-price {
        margin-bottom: 0;
    }
}

.c-mini-lister__title {
    @include rem(margin, 10px 0);
    @include rem(letter-spacing, -0.5px);

    @include mq($until: tablet) {
        @include font-size(18px, 1.25);
    }

    @include mq($from: mobile) {
        @include rem(margin-bottom, 20px);

        margin-top: 0;
    }
}

.c-mini-list__item {
    display: flex;
    height: 100%;

    @include rem(margin-bottom, 20px);

    .c-mini-list__header,
    .c-mini-list__main,
    .c-mini-list__footer {
        display: flex;
    }

    .c-mini-list__footer {
        margin-top: auto;
    }

    .c-mini-list__header {
        position: relative;
    }
}

.c-mini-list__main .c-product__title {
    @include font-weight(bold);
}

.c-mini-list__image img {
    width: 100%;
    height: auto;
}

.c-mini-list__secondary-actions {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    a {
        @include rem(margin, 0 10px);
    }
}
