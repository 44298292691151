//------------------------------------
// #BOX
//------------------------------------

//  Desc: Box object to be used for padding etc
//  Use Cases:
//  - want some padding top and bottom on mobile but none on the sides = .box-mobile--small-vertical
//  - want some padding everywhere on mobile but on tablet and above just top and bottom = .box--small .box-tablet--small-vertical
//  - want padding on tablet only = .box-tablet-only--small | .box-tablet-only--medium | .box-tablet-only--large

//  Dimensions: Everything goes off the base-layout-gutter which means:
//  - small is half the gutter size
//  - medium is the gutter
//  - large is double the gutter size

//  CLASSNAMES:

//  - box
//  - box--vertical
//  - box--horizontal

//  - box-mobile-only
//  - box-mobile-only--[small or large]
//  - box-mobile-only--[small or large]-vertical
//  - box-mobile-only--[small or large]-horizontal

//  - box-tablet
//  - box-tablet--[small or large]
//  - box-tablet--[small or large]-vertical
//  - box-tablet--[small or large]-horizontal

//  - box-tablet-only
//  - box-tablet-only--[small or large]
//  - box-tablet-only--[small or large]-vertical
//  - box-tablet-only--[small or large]-horizontal

//  - box-desktop
//  - box-desktop--[small or large]
//  - box-desktop--[small or large]-vertical
//  - box-desktop--[small or large]-horizontal


$box: (
    small: rem($base-layout-gutter * 0.5),
    medium: rem($base-layout-gutter * 1),
    large: rem($base-layout-gutter * 2)
);

@mixin box($breakpoint: '') {
    .o-box#{$breakpoint} {
        // o-box
        padding: map-get($box, medium);

        // o-box#{$breakpoint}--vertical
        &--vertical {
            padding: map-get($box, medium) 0;
        }

        // o-box#{$breakpoint}--horizontal
        &--horizontal {
            padding: 0 map-get($box, medium);
        }

        // o-box#{$breakpoint}--small
        &--small {
            padding: map-get($box, small);

            // o-box#{$breakpoint}--small-vertical
            &-vertical {
                padding: map-get($box, small) 0;
            }

            // o-box#{$breakpoint}--small-horizontal
            &-horizontal {
                padding: 0 map-get($box, small);
            }
        }

        // o-box#{$breakpoint}--large
        &--large {
            padding: map-get($box, large);

            // o-box#{$breakpoint}--large-vertical
            &-vertical {
                padding: map-get($box, large) 0;
            }

            // o-box#{$breakpoint}--large-horizontal
            &-horizontal {
                padding: 0 map-get($box, large);
            }
        }
    }
}

@include box();

@include mq($until: tablet) {
    @include box('-mobile-only');
}

@include mq($from: tablet) {
    @include box('-tablet');
}

@include mq($from: tablet, $until: desktop) {
    @include box('-tablet-only');
}

@include mq($from: desktop) {
    @include box('-desktop');
}
