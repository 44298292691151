//------------------------------------
// #VAT NOTICE
//------------------------------------

.c-vat-notice {
    display: flex;
    align-items: baseline;

    &__title {
        font-weight: bold;
        margin-bottom: 0;

        &::after {
            content: ':';
        }
    }

    &__text {
        line-height: 1.3;
    }
}
