//------------------------------------
// #TOOLTIP
//------------------------------------

.c-facet__tooltip,
.c-tooltip {
    &::before {
        content: ' ';
        width: 0;
        height: 0;
        background-color: none;
        border: 8px solid transparent;
        border-top: 8px solid $color-tooltip-before-border-top;

        @include rem(margin-bottom, 2px);
    }

    &::before,
    &::after {
        position: absolute;
        transform: translateX(-50%);
        z-index: zIndex('tooltip');
    }
}

.c-tooltip {
    position: relative;
    cursor: pointer;

    &:hover,
    &:active {
        &::before,
        &::after {
            opacity: 1;
            // IE requires a text-decoration rule to be set on the psuedo element, before it can be set to "none" :shrug:
            text-decoration: underline;
            text-decoration: none;
            bottom: 100%;
        }
    }

    &:hover,
    &:focus {
        .c-facet__tooltip {
            display: block;
        }
    }

    &::before,
    &::after {
        opacity: 0;
        bottom: 0;

        @include rem(left, 8px);
    }

    &::after {
        content: attr(aria-label);
        background-color: $color-tooltip-background;
        color: $color-tooltip-font;
        font-family: $font-family-secondary;
        @include rem(padding, 4px 8px);
        width: 200px; // Fixed width is required, otherwise psuedo element can only grow to the size of its relative parent
        text-align: center;
        pointer-events: none;

        @include font-weight(normal);
        @include rem(margin-bottom, 10px);
        @include rem(font-size, 14px);

        @include mq($from: tablet) {
            width: 300px;
        }
    }

    // because the facet tooltip requires WYSIWYG content
    // .c-tooltip--facet
    &--facet {
        &:before,
        &:after {
            display: none;
        }
    }

    // .c-tooltip--help
    &--help {
        display: inline-block;
        background-color: $color-tooltip-background-secondary;
        color: $color-tooltip-font;
        width: 18px;
        height: 18px;
        text-align: center;
        border-radius: 50%;

        @include rem(margin-left, 10px);
        @include font-size(12px, 1.5);

        &:hover,
        &:active {
            background-color: darken($color-tooltip-background-secondary, 20%);
            outline: none;
        }
    }
}

.c-facet__tooltip {
    display: none;
    position: absolute;
    width: 80vw;
    max-width: 250px;
    height: auto;
    background-color: $color-tooltip-background;
    @include rem(padding, 10px 15px);
    text-align: left;
    transform: translateX(-50%);
    z-index: zIndex('tooltip');

    @include rem(left, 75px);
    @include rem(bottom, 30px);
    @include rem(margin-bottom, 2px);

    &:before,
    &:after {
        left: 23.5%;

        @include rem(bottom, -9px);
    }

    .c-tooltip__content {
        color: $color-tooltip-font;

        @include rem(margin-bottom, 10px);
        @include rem(font-size, 12px);
        @include font-weight(normal);
    }
}
