//------------------------------------
// #PROMO BLOCKS
//------------------------------------

.c-promotional-banner__container {
    width: 100%;
    margin: 0 auto;

    // tablet sizes
    @include mq($from: tablet) {
        width: map-get($mq-breakpoints, tablet);
    }
    // desktop sizes
    @include mq($from: desktop) {
        width: map-get($mq-breakpoints, desktop);
    }
    // wide sizes
    @include mq($from: wide) {
        width: map-get($mq-breakpoints, wide);
    }
}

.c-promotional-banner__link,
.c-contact-page-promo__link {
    display: block;

    .c-promotional-banner__image,
    .c-contact-page-promo__image {
        width: 100%;
        height: auto;
        max-width: auto;
    }
}
