//------------------------------------
// #TOOLTIPS
//------------------------------------

.c-tooltip {
    position: relative;
    display: inline-block;

    .c-tooltip__text {
        transform: translate(-95px, 0);
        left: 50%;
        font-weight: normal;
        position: absolute;
        z-index: zIndex('tooltips');
        visibility: hidden;
    }

    .c-tooltip__top {
        bottom: 100%;
    }

    .c-tooltip__bottom {
        top: 100%;
    }

    &:hover .c-tooltip__text{
        visibility: visible;
    }
}

.c-bubble {
    @include rem(margin, $width-tooltips-bubble auto);
    @include rem(width, $width-tooltips);
    @include rem(border-width, $width-border);
    @include rem(padding, 10px);

    background-color: $color-tooltips-hover-bg;
    background-color: var(--color-tooltips-hover-bg);
    color: $color-tooltips-hover-text;
    color: var(--color-tooltips-hover-text);
    border-color: $color-tooltips-hover-border;
    border-color: var(--color-tooltips-hover-border);
    border-style: solid;
    text-align: center;
    font-weight: normal;
    position: relative;
}

.c-bubble--top {
    &::before {
        @include tooltip-bubble;
        border-top: rem($width-half-arrow) solid $color-tooltips-hover-border;
        border-top: rem($width-half-arrow) solid var(--color-tooltips-hover-border);
        border-bottom: rem($width-half-arrow) solid transparent;
        bottom: -$width-arrow;
    }
    
    &::after {
        @include tooltip-bubble;
        border-top: rem($width-half-arrow) solid $color-tooltips-hover-bg;
        border-top: rem($width-half-arrow) solid var(--color-tooltips-hover-bg);
        border-bottom: rem($width-half-arrow) solid transparent;
        @include rem(bottom, #{$width-border - $width-arrow});
    }
}

.c-bubble--bottom {
    &::before {
        @include tooltip-bubble;
        border-top: rem($width-half-arrow) solid transparent;
        border-bottom: rem($width-half-arrow) solid $color-tooltips-hover-border;
        border-bottom: rem($width-half-arrow) solid var(--color-tooltips-hover-border);
        top: -$width-arrow;
    }
    
    &::after {
        @include tooltip-bubble;
        border-top: rem($width-half-arrow) solid transparent;
        border-bottom: rem($width-half-arrow) solid $color-tooltips-hover-bg;
        border-bottom: rem($width-half-arrow) solid var(--color-tooltips-hover-bg);
        @include rem(top, #{$width-border - $width-arrow});
    }
}
