//------------------------------------
// #CHECKOUT PROGRESS BAR
//------------------------------------

.c-progress-bar {
    display: flex;
    position: relative;
    width: 250px;
    justify-content: space-between;

    &::after {
        background: $color-ui-grey-4;
        content: '';
        height: 1px;
        position: absolute;
        top: $progress-icon-dimension / 2;
        left: 15%;
        width: 70%;
        z-index: zIndex('progress-bar');

    }

    @include mq($until: tablet) {
        width: auto;

        &::after {
            top: 10px;
        }
    }
}

.c-progress-bar__item {
    display: flex;
    position: relative;
    text-align: center;
    z-index: zIndex('progress-item');
    flex-flow: column;
    align-items: center;

    &:nth-of-type(1) {
        &.c-progress-bar__item--active,
        &.c-progress-bar__item--complete {
            .c-progress-bar__step-text {
                background-color: $color-yellow;
            }
        }
    }

    &:nth-of-type(2) {
        &.c-progress-bar__item--active,
        &.c-progress-bar__item--complete {
            .c-progress-bar__step-text {
                background-color: $color-brand-secondary;
            }
        }
    }

    &:nth-of-type(3) {
        &.c-progress-bar__item--active,
        &.c-progress-bar__item--complete {
            .c-progress-bar__step-text {
                background-color: $color-red;
            }
        }
    }
}

.c-progress-bar__step-text {
    background-color: $color-ui-grey-4;
    border-radius: 50%;
    box-shadow: 0 0 0 7px $color-white;
    color: $color-white;
    display: block;
    font-size: $font-size-epsilon;
    height: $progress-icon-dimension;
    line-height: $progress-icon-dimension;
    @include rem(margin, 0 0 $base-spacing-unit-small/2);
    position: relative;
    user-select: none;
    width: $progress-icon-dimension;

    @include mq($until: tablet) {
        width: 20px;
        height: 20px;
        line-height: 22px;
        font-size: 10px;
    }
}

.c-progress-bar__item-text {
    opacity: .4;
    text-transform: none;
    @include rem(margin-bottom, 10px);
    @include font-weight('bold');
    @include font-size(13px);

    @include mq($until: tablet) {
        @include font-size(11px);
    }
}

.c-progress-bar__item--active,
.c-progress-bar__item--complete {
    .c-progress-bar__item-text,
    .c-progress-bar__step-text {
        opacity: 1;
        @include font-weight('bold');
    }
}

.c-progress-bar__item--complete {
    cursor: pointer;
}
