//------------------------------------
// #HEADER LINKS
//------------------------------------

.s-header-links {
    @include font-size(12px);
    @include rem(padding-top, 15px);

    ul {
        @include rem(margin, 0 0 10px 0);

        text-align: right;

        & > li {
            @include rem(padding-left, 15px);
            
            list-style: none;
            display: inline-block;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                @include rem(padding-left, 5px);
            }

            & > a {
                @include link-color($color-header-links, $color-header-links)
            }
        }
    }

    .c-currency-switcher {
        float: left;
    }
}
