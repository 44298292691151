//------------------------------------
// #SLIDER
//------------------------------------

.c-slider {
    @include rem(margin-left, 28px);
    @include rem(margin-bottom, 10px);
    
    padding-left: 0;
    max-width: 218px;
}

.c-slider__count {
    font-size: $font-size-zeta;
}

.c-slider__controls {
    @include rem(margin-top, 5px);

    .c-button {
        @include rem(margin, 10px 0 24px);
    }
}

.c-slider__label + .c-slider__label {
    text-align: right;
}
