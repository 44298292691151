//------------------------------------
// #LOGIN CREATE ACCOUNT
//------------------------------------

.c-login {
    @include mobile-gutter();

    .c-actions__toolbar {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-login--modal {
    .block:first-child {
        @include rem(margin-bottom, 40px);

        @include mq($from: tablet) {
            margin-bottom: 0;
        }
    }

    .c-checkout__messages {
        width: 100%;
    }

    .c-button {
        width: 100%;
        border: none;
    }

    @include mq($from: tablet) {
        .c-button {
            width: auto;
        }
    }
}

.c-form--login {
    h3 {
        @include font-size($font-size-epsilon);
    }
}

.c-form--create-account {
    fieldset {
        @include rem(margin-top, 36px);

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.c-new-customer {
    @include rem(margin-top, 36px);

    p {
        @include font-size($font-size-epsilon);
    }

    .c-button {
        display: block;
        text-align: center;
    }

    @include mq($from: tablet) {
        @include rem(padding-left, 60px);

        margin-top: 0;

        .c-button {
            display: inline-block;
        }
    }
}
