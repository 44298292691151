//------------------------------------
// #WISHLIST EXTEND
//------------------------------------

.c-saved-items__list {
    @include rem(margin-bottom, 20px);

    @include mq($from: desktop) {
        @include rem(margin-bottom, 50px);
    }
}

.c-wishlist__link {
    @include rem(font-size, 15px);
    @include rem(margin-bottom, 5px);
    @include link-color($color-ui-grey-2, $color-brand-secondary);

    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    span {
        &:last-child {
            @include rem(margin-left, 3px);
        }
    }
}

.c-wishlist__icon {
    @include rem(margin-right, 3px);

    width: 16px;
    height: 16px;
    position: relative;
}

.c-wishlist__btn-basket {
    height: 40px;
    @include rem(margin-right, 10px);
    min-width: auto;
    width: auto;
    @include font-size($base-font-size);

    @include mq($from: tablet) {
        width: 100%;
    }

    @include mq($from: desktop) {
        @include font-size($font-size-epsilon);
        @include rem(margin-right, 15px);
    }
}
