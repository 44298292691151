//------------------------------------
// #COMMENT
//------------------------------------

// Wrap in template retriever class to override WordPress base theme
.c-template-retriever {
    .c-comment {
        &.depth-2 {
            .c-comment__footer,
            .c-comment__header {
                background-color: $color-brand-secondary;
            }
        }

        &.depth-3 {
            .c-comment__footer,
            .c-comment__header {
                background-color: lighten($color-brand-secondary, 10%);
            }
        }

        &.depth-4 {
            .c-comment__footer,
            .c-comment__header {
                background-color: lighten($color-brand-secondary, 15%);
            }
        }

        &.depth-5 {
            .c-comment__footer,
            .c-comment__header {
                background-color: lighten($color-brand-secondary, 20%);
            }
        }
    }

    .c-comment__footer,
    .c-comment__header {
        background-color: $color-brand-primary;
    }
}
