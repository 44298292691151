//------------------------------------
// #RECOMMENDED PRODUCTS
//------------------------------------

.c-recommended__items-wrapper {
    max-width: 85%;
    @include rem(margin-bottom, 40px);

    @include mq($until: mobile) {
        @include rem(margin-bottom, 20px);
    }
}
