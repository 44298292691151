//------------------------------------
// #CAROUSEL EXTEND
//------------------------------------

.c-carousel {
    @include rem(margin-bottom, 10px);

    padding-left: 0;
    padding-right: 0;
    position: relative;
    user-select: none;
    touch-action: pan-y;
    overflow: hidden;

    @include mq($from: tablet) {
        @include rem(margin-bottom, 30px);
    }

    .slick-track > .slick-slide {
        padding: 0;

        @include mq($from: tablet) {
            max-width: initial !important;
        }
    }
}

// Slider
.slick-loading .slick-list {
    background: $color-white url('../images/loader-1.gif') center center no-repeat;
}

.slick-prev,
.slick-next {
    width: 20px;
    height: 20px;
    transform: translate(0, -50%);
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    line-height: 1;
    opacity: .75;
    color: black;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    left: -25px;
}

.slick-dots {
    bottom: 30px;
    left: 50px;
    width: auto;

    @include mq($until: tablet) {
        bottom: 15px;
        left: 15px;
    }
}

.slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 8px;
    border: none;
    outline: none;
    background: $color-off-white;
    transition: background-color 0.2s ease;
    will-change: background-color;

    @include mq($from: 'tablet') {
        width: 15px;
        height: 15px;
        border-radius: 15px;
    }

    &:hover,
    &:focus {
        background-color: $color-mono-d;
        outline: none;
    }
}

.slick-carousel {
    > div {
        display: none;
    }

    > div:first-child {
        display: block;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.slick-initialized.slick-carousel {
    > div {
        display: block;
    }
}
