//------------------------------------
// #REACT LISTINGS TOOLBAR
//------------------------------------

.c-listings__toolbar--react {
    @include rem(margin-top, $base-spacing-unit);

    &.c-listings__toolbar--top {
        @include rem(margin-bottom, $base-spacing-unit);

        margin-top: 0;
    }
}

.c-listings__toolbar .js-react-sticky {
    @include mq($until: tablet) {
        @include rem(padding, 10px 0);
        background-color: $color-plp-toolbar-bg;
        background-color: var(--color-plp-toolbar-bg);
    }
}
