//------------------------------------
// #HEADER
//------------------------------------

.c-header {
    @include mq($until: tablet) {
        padding: 0;
    }

    @include mq($until: desktop) {
        @include rem(margin-bottom, 4px);
        @include rem(padding, 4px 0);

        .o-region__inner {
            width: 100%;
        }
    }
}

.c-header__logo {
    display: block;
    height: auto;

    @include mq($from: desktop) {
        display: inline-block;
    }

    @include mq($until: desktop) {
        @include rem(height, 40px);

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .s-contact-details {
        @include rem(margin-top, -6px);
    }

    img {
        display: inline-block;
    }
}

.c-header__content {
    @include mq($from: desktop) {
        display: flex;
        align-items: center;
    }

    .c-drawer__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// Overqualified selector to be more specific than s-header-links :shrug:
.c-header .c-header__links {
    background-color: $color-off-white;
    @include rem(margin-bottom, 20px);

    ul > li {
        padding: 0;
    }

    ul > li > a,
    .c-header__welcome-message > span {
        @include rem(padding-left, 15px);
    }

    .c-currency-switcher {
        @include mq($from: wide) {
            @include rem(padding-left, 15px);
        }
    }

    .c-header__welcome-message {
        font-family: $font-family-secondary;

        span {
            display: none;

            &.active {
                display: inline-block;
            }
        }
    }
}

.c-header--checkout {
    border-top: 20px solid $color-off-white;
    border-bottom: 1px solid $color-ui-grey-4;

    &__content {
        padding: 12px 0;
        display: flex;
        align-items: center;
    }

    &__logo-container {
        flex-grow: 1;

        > .c-header__logo {
            padding: 0;
            text-align: left;

            img {
                height: 100%;
            }

            @include mq($until: desktop) {
                height: 30px;
            }
        }
    }

    &__meta {
        display: flex;
        align-items: center;

        .c-btn {
            @include font-size(14px);

            display: none;
            min-width: 0;

            .c-icon {
                @include rem(margin-right, 5px);

                height: 14px;
                transform: rotate(180deg) translateY(-2px);
            }

            @include mq($from: desktop) {
                display: inline-block;
            }
        }

        p {
            @include font-size(13px);

            margin-bottom: 0;
            @include rem(margin-left, 30px);
            display: flex;
            align-items: center;
            color: $color-black;

            &::before {
                @extend .c-icon--secure-checkout;
                @include rem(margin-right, 3px);

                content: " ";
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
            }

            @include mq($from: desktop) {
                @include font-size(16px);

                &::before {
                    @include rem(margin-right, 5px);

                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

.c-header__search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-left: 1px solid $color-header-border;
    border-right: 1px solid $color-header-border;
    background-color: transparent;
    border-top: none;
    border-bottom: none;

    .c-icon--search-icon-black {
        width: 23px;
        height: 23px;
        transform: scaleX(-1);
    }
}
