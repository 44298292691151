//------------------------------------
// #HEADINGS
//------------------------------------

// Header Overrides:
// When we define a heading we also define a corresponding class to go with it.
// This allows us to apply, say, `class=alpha` to a `h3`; a double-stranded
// heading hierarchy.
//
// @example
//		h3.u-heading-alpha Large Header
//		h3.u-heading-beta Medium Header
//		h3.u-heading-gamma Small Header
//		h3.u-heading-delta Large Header
//		h3.u-heading-epsilon Medium Header
//		h3.u-heading-zeta Small Header
//

// Master heads
//
// .u-heading-giga {}
// .u-heading-mega {}

// Kilo: Same size as Alpha but not bold, used for Large non-bold headings
// .u-heading-kilo {
//     @include font-size(
//         map-get($heading-size-alpha, size),
//         map-get($heading-size-alpha, line-height)
//     );
// }

// Headings
// Alpha: Bold page headings
.u-heading-alpha {
    @include font-weight('bold');

    @include font-size(
                    map-get($heading-size-alpha, size),
                    map-get($heading-size-alpha, line-height)
    );

    @if ($is-heading-uppercase) {
        text-transform: uppercase;
    }
}

// Beta: section headings, large product titles
.u-heading-beta {
    @include font-size(
                    map-get($heading-size-beta, size),
                    map-get($heading-size-beta, line-height)
    );
}

// Gamma: component heading
.u-heading-gamma {
    @include font-size(
                    map-get($heading-size-gamma, size),
                    map-get($heading-size-gamma, line-height)
    );
}

// Delta: large product titles
.u-heading-delta {
    @include font-size(
                    map-get($heading-size-delta, size),
                    map-get($heading-size-delta, line-height)
    );
}

// Epsilon: small product titles
.u-heading-epsilon {
    @include font-size(
                    map-get($heading-size-epsilon, size),
                    map-get($heading-size-epsilon, line-height)
    );
}

// Zeta: small full caps headings (footer)
.u-heading-zeta {
    @include font-size(
                    map-get($heading-size-zeta, size),
                    map-get($heading-size-zeta, line-height)
    );
}
