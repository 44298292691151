//------------------------------------
// #FILTER EXTEND
//------------------------------------

.c-filter__slider-label {
    @include rem(font-size, 11px);
}

.c-filter__slider {
    margin: 4px 10px 0;

    @include mq($until: wide) {
        @include rem(margin, 4px 10px 0 5px);
    }

}

.c-filter__slider-actions {

    @include mq($until: tablet) {
        @include rem(padding-top, 10px);

        display: block;
        clear: both;
    }
}
