//------------------------------------
// #PLP SIDEBAR
//------------------------------------

.c-plp-sidebar__filters-wrapper {
    .c-heading {
        @include font-size(17px);
    }

    .c-plp-sidebar__filter-items {
        list-style-type: none;
        margin: 0;

        .c-plp-sidebar__link {
            text-decoration: none;
            font-family: $font-family-secondary;
            @include font-size(14px);
            @include link-color($color-ui-grey-2, $color-brand-secondary);

            span.c-icon--chevron-right {
                @include rem(margin-right, 5px);
            }
        }
    }

    // this styling targets the checkbox list that renders filter items
    .items .item a {
        text-decoration: none;

        .c-checkbox-wrap.c-input-wrap {
            @include rem(margin-bottom, 5px);

        }

        .c-checkbox-wrap.c-input-wrap .c-checkbox-option__label {
            margin-right: 0;
        }
    }
}
