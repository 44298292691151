//------------------------------------
// #MODAL
//------------------------------------

// sass-lint:disable no-important
.modal-inner-wrap {
    top: 0;
    position: fixed !important;
    z-index: zIndex('modal-inner-wrap') !important;
}

.modals-overlay {
    z-index: zIndex('modal-overlay') !important;
}

body._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.modal-slide,
.modal-popup,
.modal-custom {
    visibility: hidden;
}

.modal-slide._show,
.modal-popup._show,
.modal-custom._show {
    visibility: visible;
}

.modal-slide._show .modal-inner-wrap,
.modal-popup._show .modal-inner-wrap,
.modal-custom._show .modal-inner-wrap {
    transform: translate(0, 0);
}

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap,
.modal-custom .modal-inner-wrap {
    background-color: $color-white;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto;
}

.modal-slide {
    left: 44px;
}

.modal-slide._show .modal-inner-wrap {
    transform: translateX(0);
}

.modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    transform: translateX(100%);
    transition-duration: 0.3s;
    transition-property: transform, visibility;
    transition-timing-function: ease-in-out;
    width: auto;
}

.modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column;
}

.modal-slide._inner-scroll .modal-header,
.modal-slide._inner-scroll .modal-footer {
    flex-grow: 0;
    flex-shrink: 0;
}

.modal-slide._inner-scroll .modal-content {
    overflow-y: auto;
}

.modal-slide._inner-scroll .modal-footer {
    margin-top: auto;
}

.modal-slide .modal-header,
.modal-slide .modal-content,
.modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem;
}

.modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem;
}

.modal-popup,
.modal-custom {
    left: 0;
    overflow-y: auto;
}

.modal-popup._show .modal-inner-wrap,
.modal-custom._show .modal-inner-wrap {
    transform: translateY(0);
}

.modal-popup .modal-inner-wrap,
.modal-custom .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-200%);
    transition-duration: 0.2s;
    transition-property: transform, visibility;
    transition-timing-function: ease;
}

.modal-popup._inner-scroll,
.modal-custom._inner-scroll {
    overflow-y: visible;
}

.ie10 .modal-popup._inner-scroll,
.ie9 .modal-popup._inner-scroll,
.ie10 .modal-custom._inner-scroll,
.ie9 .modal-custom._inner-scroll {
    overflow-y: auto;
}

.modal-popup._inner-scroll .modal-inner-wrap,
.modal-custom._inner-scroll .modal-inner-wrap {
    max-height: 80%;
}

.ie10 .modal-popup._inner-scroll .modal-inner-wrap,
.ie9 .modal-popup._inner-scroll .modal-inner-wrap,
.ie10 .modal-custom._inner-scroll .modal-inner-wrap,
.ie9 .modal-custom._inner-scroll .modal-inner-wrap {
    max-height: none;
}

.modal-popup._inner-scroll .modal-content,
.modal-custom._inner-scroll .modal-content {
    overflow-y: auto;
}

.modal-popup .modal-header,
.modal-popup .modal-content,
.modal-popup .modal-footer,
.modal-custom .modal-header,
.modal-custom .modal-content,
.modal-custom .modal-footer {
    @include rem(padding-left, 30px);
    @include rem(padding-right, 30px);
}

.modal-popup .modal-header,
.modal-popup .modal-footer,
.modal-custom .modal-header,
.modal-custom .modal-footer {
    flex-grow: 0;
    flex-shrink: 0;
}

.modal-popup .modal-header,
.modal-custom .modal-header {
    @include rem(height, 65px);
    @include rem(padding-top, 18px);

    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end; // Align close to right if no title

    &.has-title {
        justify-content: space-between;
    }

    @include mq($from: tablet) {
        @include rem(padding-top, 25px);
    }
}

.modal-popup .modal-footer,
.modal-custom .modal-footer {
    @include rem(padding-bottom, 30px);
    @include rem(padding-top, 30px);

    margin-top: auto;
    display: flex;

    .c-button {
        flex-grow: 1;
        @include rem(margin-left, 15px);

        &:first-of-type {
            margin-left: 0;
        }
    }

    @include mq($until: tablet) {
        flex-wrap: wrap;

        .c-button {
            @include rem(margin-top, 12px);

            margin-left: 0;
        }
    }
}

.modal-popup .modal-footer-actions,
.modal-custom .modal-footer-actions {
    text-align: right;
}

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    padding: 0;
    position: relative;

    &::before {
        @include rem(font-size, 30px);
    }

    @include mq($from: tablet) {
        &::before {
            @include rem(font-size, 50px);
        }
    }

    .c-icon {
        @include rem(width, 25px);
        @include rem(height, 25px);
    }
}

.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
    &:last-child {
        @include visually-hidden();
    }
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: inherit;
}

.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
    color: inherit;
}

.modal-popup .modal-title,
.modal-custom .modal-title {
    @include rem(padding-bottom, 10px);
    @include font-size(18px);

    font-weight: 300;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
    padding-top: 0;
    white-space: nowrap;

    @include mq($from: tablet) {
        @include font-size(26px);
    }
}

.modal-slide .page-main-actions {
    margin-bottom: -12.9rem;
    margin-top: 2.1rem;
}

.modals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

body._has-modal-custom .modal-custom-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
}

.modal-custom.authentication-dropdown .modal-inner-wrap {
    @include rem(padding-bottom, 30px);
}

// Ensure the styling will never apply to desktop or tablet
@include mq($until: tablet) {
    // Ensure Safari body does not scroll/menu does not show or hide
    .mobile-modal-active {
        -webkit-overflow-scrolling: touch !important;
        overflow: hidden !important;
        height: 100% !important;
    }

    .c-modal.modal-popup,
    .c-modal.modal-custom {
        .modal-inner-wrap {
            margin: 0;
            max-width: none;
            top: 0;
            height: 100%;
            width: 100%;
        }

        &._inner-scroll {
            // Deeper selector to override defaults
            .modal-title {
                white-space: normal;
            }

            .modal-inner-wrap {
                max-height: none;
            }

            .modal-content {
                overflow-y: scroll;
            }
        }
    }
}
