//------------------------------------
// #GIFT CARD
//------------------------------------

.c-giftcard-select__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        @include rem(margin-right, 10px);

        color: $color-black;
        font-family: $font-family-secondary;
    }
}
