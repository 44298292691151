//------------------------------------
// #NAVIGATION SIDEBAR
//------------------------------------

.c-sidebar-nav {
    background-color: $color-ui-grey-5;
    background-color: var(--color-ui-grey-5);
    border-top: 1px solid $color-ui-grey-3;
    border-top: 1px solid var(--color-ui-grey-3);
    border-bottom: 1px solid $color-ui-grey-3;
    border-bottom: 1px solid var(--color-ui-grey-3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: zIndex('sticky-header') - 1;

    @include mq($from: desktop) {
        position: static;
        background-color: $color-ui-grey-4;
        background-color: var(--color-ui-grey-4);
        border: none;
    }
}

.c-sidebar-nav__header {
    @include font-size($font-size-epsilon);
    @include rem(padding, 12px 0);

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    cursor: pointer;
    width: 90%;

    &::after {
        content: ' ';
        display: inline-block;
        height: 30px;
        width: 30px;
        background-image: url('../images/icons/svg/arrow-expand-down.svg');
        background-repeat: no-repeat;
        background-position: right 50%;
    }

    &.active {
        &::after {
            transform: rotate(180deg);
        }
    }

    @include mq($from: tablet) {
        width: map-get($mq-breakpoints, tablet);
        padding-left: 0;
        padding-right: 0;
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-sidebar-nav__body {
    display: none;
    border-top: 1px solid $color-ui-grey-3;
    border-top: 1px solid var(--color-ui-grey-3);
    background-color: $color-ui-grey-4;
    background-color: var(--color-ui-grey-4);

    &.active {
        display: block;
    }

    @include mq($from: desktop) {
        display: block;
        border: none;
    }
}

.c-sidebar-nav__items {
    @include rem(padding, 15px 0);

    margin: 0 auto;
    width: 90%;
    list-style: none;

    > li {
        @include font-size($font-size-epsilon);
        @include rem(padding, 6px 6px 6px 35px);
        @include rem(margin, 4px 0);

        &.current {
            @include rem(padding-left, 28px);

            border-left: solid 7px $color-brand-primary;
            border-left: solid 7px var(--color-brand-primary);
        }
    }

    @include mq($from: tablet) {
        width: map-get($mq-breakpoints, tablet);
    }

    @include mq($from: desktop) {
        margin: 0;
        width: 100%;
        border-top: none;
    }
}

// Account sidebar nav
.c-account-sidebar {
    @include mq($from: desktop) {
        @include rem(margin-bottom, 30px);
    }
}
