//------------------------------------
// #WHAT'S NEW
//------------------------------------

.c-whats-new {
    @include rem(margin-bottom, 100px);
}

.c-whats-new__image {
    margin: 0 0 20px 0;
    width: 100%;
    display: block;
    img {
        width: 100%;
        display: block;
    }
}
