//------------------------------------
// #PRODUCT BADGES
//------------------------------------

.c-product-badge__wrapper {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    z-index: zIndex('product-badge');

    // this keeps the image in line with the PDP image
    .c-pdp__media & {
        @include rem(left, 124px);
    }

    .c-product-badge {
        width: 40px;

        @include mq($from: desktop) {
            width: 75px;
        }

        @include mq($from: tablet) {
            width: 54px;
        }
    }
}

.c-product-list__item-header .c-product-badge {
    @include mq($from: tablet) {
        width: 54px;
    }
}

.c-mini-list__header .c-product-badge {
    @include mq($from: tablet) {
        width: 54px;
    }
}
