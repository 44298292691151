//------------------------------------
// #BREADCRUMBS
//------------------------------------

.c-breadcrumbs {
    @include rem(margin-bottom, $base-spacing-unit);
    @include mobile-gutter();

    @include mq($from: tablet) {
        @include rem(margin-bottom, 35px);
    }
}

.c-breadcrumbs__item {
    color: $color-breadcrumb-item;
    color: var(--color-breadcrumb-item);

    &.o-list-inline__item {
        margin-left: 0;
    }

    &::after {
        @include rem(margin-left, 8px);
        @include rem(margin-right, 8px);

        color: $color-breadcrumb-item-separator;
        color: var(--color-breadcrumb-item-separator);
        display: inline-block;
    }

    &:not(:last-of-type)::after {
        content: '\003E';
    }

    &:last-child .c-breadcrumbs__link {
        color: $color-black;
        color: var(--color-black);
        font-weight: 600;
    }
}

.c-breadcrumbs__link {
    @include link-color($color-breadcrumb-link, $color-breadcrumb-link-hover);

    text-decoration: none;
}
