//------------------------------------
// #LOGOS
//------------------------------------

.c-logo__white {
    background-image: url('../images/ms-logo-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 35px;
    width: 230px;
}

.c-logo__black {
    background-image: url('../images/ms-logo-black.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 35px;
    width: 230px;
}
