//------------------------------------
// #PRODUCT ATTRIBUTE
//------------------------------------

.c-product-attribute--sku {
    @include font-size($font-size-zeta);
    @include rem(margin-bottom, 15px);
    
    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);
    text-align: right;

    .c-product-attribute__label,
    .c-product-attribute__value {
        display: inline-block;
    }
}
