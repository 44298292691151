//------------------------------------
// #ACCOUNT CREATE
//------------------------------------

.s-account-create {
    &__form__sub-heading {
        display: none;
    }

    .page-title {
        @include mq($until: desktop) {
            @include rem(margin-bottom, $spacing-unit-small);
            @include font-size(21px);
        }
    }

    .c-dropdown__list {
        top: auto;
    }

    .c-tooltip__content {
        @include rem(padding, 5px 15px);

        display: none;
        width: 250px;
    }

    .c-tooltip {
        font-family: $font-family-secondary;
        color: $color-white;

        &:hover {
            .c-tooltip__content {
                display: block;
            }
        }
    }

    .messages {
        @include rem(margin-bottom, 24px);
    }

    .c-input-wrap.country {
        .c-form__select {
            &:after {
                top: 3rem;
            }
        }
    }
}

.s-account-create__form .c-vehicle-registration {
    display: none;

    &+.c-btn--register-vehicle {
        display: inline-block;

        @include rem(margin-bottom, 15px);
    }

    .c-link--cancel-registration {
        display: none;
    }

    &.active {
        display: block;

        &+.c-btn--register-vehicle {
            display: none;
        }
    }
}
