//------------------------------------
// #TOGGLE BUTTON
//------------------------------------

.c-toggle-button-wrapper {
    display: flex;
}

.c-toggle-button {
    @include rem(padding, $base-spacing-unit-small);
    
    cursor: pointer;
    flex-grow: 1;
    position: relative;
    text-align: center;

    .c-indicator {
        background: $color-ui-grey-3;
        background: var(--color-ui-grey-3);
        height: 1px;
        position: absolute;
        bottom: -5px;
        left: 0;
        transition: height 200ms;
        width: 100%;
    }

    .c-label {
        font-weight: 300;
        color: $color-ui-grey-4;
        color: var(--color-ui-grey-4);
    }

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:focus,
        &:checked,
        &:active {
            @include font-weight('bold');

            & ~ .c-indicator {
                background-color: $color-brand-primary;
                background-color: var(--color-brand-primary);
                height: 2px;
            }

            & ~ .c-label {
                font-weight: 600;
            }
        }
    }
}
