//------------------------------------
// #ALL CATEGORIES
//------------------------------------

.c-all-categories__container {
    display: flex;
    flex-wrap: wrap;
}

.c-all-categories__page-title .page-title {
    @include font-weight(bold);
    @include rem(font-size, 24px);

    @include mq($until: tablet) {
        @include rem(font-size, 18px);
    }
}

.c-all-categories__preface p {
    @include font-size(14px, 1.3);
}

.c-all-categories__hero-link {
    display: inline-block;

    &,
    &:hover,
    &:visited {
        text-decoration: none;
    }
}

.c-all-categories__hero-image {
    @include rem(margin-bottom, 10px);

    display: block;
    width: 100%;
}

.c-all-categories__title {
    @include font-weight(bold);
    @include rem(font-size, 18px);
    @include rem(margin-bottom, 10px);
}

.c-all-categories__link {
    @include link-color($color-ui-grey-2, $color-links);

    text-decoration: none;
}

.c-all-categories__list {
    @include rem(margin-bottom, 70px);
}
