//------------------------------------
// #QUICK SEARCH
//------------------------------------

// Styling for the header search autocomplete, known as "quick search"

.c-quick-search {
    z-index: zIndex('quick-search');
    background-color: $color-white;

    // Apply borders to first generated div so an empty parent will not display residual borders
    > div {
        border: 1px solid $color-brand-secondary;
        border-top: 0;
    }

    &__row,
    .autocomplete-list-title {
        @include rem(padding, 5px);
    }

    .autocomplete-list-title {
        font-weight: bold;
    }

    // .c-quick-search__row
    &__row {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        margin-left: 0;

        &.selected {
            color: $color-white;
            background-color: $color-brand-secondary;
        }

        // .c-quick-search__row--product
        &--product {
            display: flex;

            &.selected .c-product__price {
                color: $color-white;
            }
        }
    }

    // .c-quick-search__product-details
    &__product-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    &__product-image,
    &__product-name {
        @include rem(padding-right, 10px);
    }

    // .c-quick-search__product-name
    &__product-name {
        white-space: normal;
        line-height: 1;
    }

    // c-quick-search__product-sku
    &__product-sku {
        @include rem(font-size, $font-size-theta);
        color: $color-ui-grey-4;
    }

    // .c-quick-search__product-image
    &__product-image {
        flex-shrink: 0;

        img {
            @include rem(width, 75px);
            @include rem(height, 75px);

            max-width: none;
            display: block;
        }
    }

    // .c-quick-search__product-price
    &__product-price {
        align-self: flex-end;

        .c-product__price {
            @include rem(font-size, $font-size-zeta);

            color: $color-ui-grey-2;
            margin-bottom: 0;
        }
    }
}
