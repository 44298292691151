//------------------------------------
// #MINICART EXTEND
//------------------------------------

.c-minicart-wrapper {
    display: flex;
    justify-content: center;

    @include mq($from: desktop) {
        float: right;
    }
}

.c-minicart {
    position: relative;
    top: auto;

    &.is-active {
        .c-minicart__overlay-bg {
            background: #3d3d3d;
            opacity: .6;
            filter: alpha(opacity=60);
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: zIndex('minicart') - 1;
        }
    }

    .c-btn {
        min-width: auto;
    }

    .c-qty__input {
        @include font-weight(bold);

        padding: 0;
        height: 30px;
        border: none;
        background-color: $color-transparent;
        text-align: center;
        width: 40px;
    }

    .c-qty__button {
        user-select: none;
        border: none;
        padding: 0;
        line-height: 1;
        background-color: $color-transparent;

        &:disabled {
            opacity: .5;
        }
    }
}

.c-minicart__header {
    @include rem(margin-bottom, 10px);

    padding: 0;
}

.c-minicart__btn {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq($from: desktop) {
        display: inline-block;
        position: relative; // Position relative for z-index onlY
        z-index: zIndex('minicart');
        text-decoration: none;
        @include link-color($color-minicart-btn-link, $color-minicart-btn-link);

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .c-icon--cart-mobile {
        @include rem(margin-top, 5px); // Adjustment for white space in SVG
        @include rem(width, 30px);
        @include rem(height, 30px);
    }
}

.c-minicart-btn__items {
    width: 30px;
    color: white;
    position: absolute;
    top: 7px;
    left: 28%;
    text-align: center;
    font-style: normal;

    @include font-size(16px);

    @include mq($until: desktop) {
        display: none;
        top: 1px;
        left: 19%;
        @include font-size(12px);
    }
}

.c-minicart-btn__price {
    float: left;
    @include rem(padding-top, 8px);
    @include rem(margin-right, 2px);

    &::after {
        display: none;
    }

    @include font-size(16px);
}

.c-minicart-btn__icon {
    width: 30px;
    height: 30px;
    background-size: contain;
    margin: 0;

    // styles to override from desktop
    @include mq($from: desktop) {
        position: relative;
        width: 55px;
        height: 48px;
        background-size: 55px;
    }

    // styles needed until desktop
    @include mq($until: desktop) {
        vertical-align: middle;
        @include rem(margin-right, 8px);
        @include rem(margin-left, 4px);
        @include rem(margin-top, 3px);
    }
}

.c-minicart__drop-down {
    @include rem(padding, 25px 30px);
    @include rem(font-size, 12px);

    display: none;
    position: absolute;
    right: 0;
    top: 38px;
    width: 490px;
    background-color: $color-off-white;
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, .2);
    z-index: zIndex('minicart');
    color: $color-minicart-text;

    @include mq($until: tablet) {
        @include rem(padding, 20px 15px);

        width: 300px;
        right: 10px;
    }

    @include mq($until: desktop) {
        bottom: auto;
    }

    .o-media {
        .o-media__img img {
            display: block;
        }
    }
}

.c-minicart__close {
    background-color: $color-transparent;
    line-height: 1;
    @include rem(padding, 8px);
    padding-right: 0;
}

.c-minicart__close-icon {
    width: 16px;
    height: 16px;
}

.c-minicart__count {
    color: $color-minicart-font;
    @include font-weight(bold);
}

.c-minicart__col-title {
    width: 45%;

    @include mq($until: tablet) {
        width: 100%;
    }
}

.c-minicart__col-price {
    width: 55%;
    float: right;

    @include mq($until: tablet) {
        width: 100%;
        @include rem(margin-top, 10px);
    }
}

.c-minicart__row-options {
    float: left;

    table {
        margin-bottom: 0;
    }

    td {
        padding: 0;
    }

    @include mq($until: tablet) {
        table,
        tbody,
        tr,
        td {
            display: block;
        }

        tr {
            @include rem(margin-bottom, 5px);
        }
    }
}

.c-minicart__row-options-label {
    font-weight: bold;
    color: $color-black;
}

.c-minicart__product-title {
    display: inline-block;
    line-height: 16px;
    text-decoration: none;
    @include rem(font-size, 12px);
    @include link-color($color-minicart-product-title, $color-minicart-product-title-hover);
}

.c-minicart__sku {
    @include rem(font-size, 12px);
}

.c-minicart__label {
    @include rem(font-size, 13px);
    @include rem(margin-right, 10px);

    color: $color-minicart-font;
}

.c-minicart__qty {
    @include rem(padding, 6px 10px);

    display: flex;
    align-items: center;

    @include mq($until: tablet) {
        padding-left: 0;
    }
}

.c-minicart__qty-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: block;
}

.c-minicart__price-container {
    line-height: 42px;

    .minicart-price {

        .price {
            @include rem(font-size, 14px);
            @include font-weight(bold);
        }
    }
}

.c-minicart__continue-label {
    @include rem(font-size, 14px);
    @include link-color($color-minicart-font, $color-minicart-font);

    font-weight: 800;
    font-family: $font-family-default;
    line-height: 40px;
    text-decoration: none;
    background: none;
    border: none;
    outline: none;
}

.c-minicart__continue-icon {
    width: 16px;
    height: 10px;
    @include rem(margin-right, 6px);
}

.c-minicart__basket-btn {
    @include font-size(14px);
    @include rem(padding, 10px 44px);

    font-weight: normal;
}

.c-minicart__subtotal {
    @include font-weight(bold);

    justify-content: flex-end;
    color: $color-minicart-text;

    .price {
        @include rem(font-size, 18px);
    }
}

.c-minicart__scroll {
    overflow-y: auto;

    // Remove margin from bottom, set padding so cart scroll works correctly
    .o-list-stacked li:not(:last-child) {
        @include rem(padding-bottom, 15px);

        margin-bottom: 0;
    }
}

.c-loader__in-context.loading-mask {
    position: absolute;

    .loader {
        height: 100%;

        > img {
            position: absolute;
        }
    }
}

.c-minicart__view-more {
    @include rem(padding, 10px 0);

    width: 100%;
    text-align: center;
}

.c-minicart-wrapper div.c-minicart__drop-down {
    transition: initial;
    
    @include mq($until: tablet) {
        right: 0;
    }
}
