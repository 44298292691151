//------------------------------------
// #REVIEWS EXTEND
//------------------------------------

.c-review-filter__wrapper {
    @include rem(margin, 20px 0 10px);
}

.c-review__title,
.c-review__item-title {
    @include rem(padding-bottom, 2px);

    font-weight: bold;
    color: $color-black;
    text-transform: none;
}

.c-review__title {
    @include rem(margin, 10px 0);
    @include font-size(18px);

    @include mq($until: tablet) {
        display: none;
    }
}

.c-review__items {
    @include rem(margin-bottom, 15px);

    list-style: none;
    margin: 0;
}

.c-review__item-title {
    @include rem(margin, 5px 0);
}

.c-review__item {
    @include rem(margin-bottom, 10px);

    border-bottom: 1px solid rgba($color-black, 0.3);

    .c-review-details__description,
    .c-review-details__author {
        @include font-size(14px, 1.3);

        font-family: $font-family-secondary;
        color: $color-ui-grey-2;
    }

    .c-review-details__description {
        @include rem(margin-bottom, 10px);
    }

    .c-review__item-title {
        @include rem(font-size, 14px);

        font-family: $font-family-default;
        letter-spacing: -0.5px;
    }

    .c-review-details__author {
        @include rem(margin-bottom, 15px);
    }
}

.c-reviews-filter__wrapper {
    display: flex;
    flex-flow: row;

    .c-reviews-filter__label {
        align-self: center;
        margin: 0;
        display: inline-block;
    }

    .c-reviews-filter__select {
        @include rem(margin-left, 10px);

        flex-grow: 1;
        width: auto;
        display: flex;
        position: relative;
        background-color: $color-off-white;
        border: 0;
    }
}

.c-review__votes {
    @include rem(margin-bottom, 15px);

    display: flex;
    flex-flow: row wrap;

    .c-review__vote-label {
        @include rem(margin-bottom, 10px);

        flex: 1 1 100%;
    }

    .c-review__vote-link {
        @include rem(margin-right, 20px);

        display: flex;
        flex-flow: row;
        align-items: center;
        color: $color-ui-grey-2;
        text-decoration: none;
        border: 0;
        padding: 0;
        background: none;

        &[disabled] {
            cursor: auto;
        }
    }

    .c-icon--thumbs-down {
        @include rem(margin-top, 6px);
    }

    .c-icon--thumbs-up {
        @include rem(margin-bottom, 3px);
    }

    .c-review__vote-text {
        @include rem(margin, 0 5px);
        @include font-weight(bold);
    }
}

.c-review-pagination__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    .c-review-pagination__item {
        @include rem(margin-right, 5px);
    }

    .c-review-pagination__link {
        @include rem(width, 35px);
        @include rem(height, 35px);

        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border: 0;

        color: $color-ui-grey-2;
        background-color: $color-off-white;

        &:hover {
            background-color: darken($color-off-white, 5%);
        }

        &.is-active {
            cursor: not-allowed;
            background-color: white;

            &:hover {
                background-color: white;
            }
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: .3;
        }

        .c-icon--arrow-left-sm,
        .c-icon--arrow-right-sm{
            @include rem(width, 15px);
            @include rem(height, 10px);
        }
    }
}

.c-review__add {
    .c-review__title {
        display: flex;
        align-items: center;

        @include mq($from: tablet) {
            cursor: pointer;
        }

        .c-icon {
            @include rem(width, 15px);
            @include rem(height, 12px);
            @include rem(margin-left, 10px);

            display: none;

            @include mq($from: tablet) {
                display: inline-block;
            }
        }
    }

    [data-role="content"] {
        @include rem(padding, 0 20px);
    }

    &.active {
        .c-review__title .c-icon {
            transform: rotate(180deg);
        }
    }
}
