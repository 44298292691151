//------------------------------------
// #STORE SWITCHER
//------------------------------------

.c-store-switcher {
    height: 100%;
    display: flex;

    .c-dropdown__label {
        @include rem(padding, 13px 12px);

        > span {
            display: inline-block;
        }

        .c-icon {
            @include rem(width, 23px);
            @include rem(height, 23px);
        }
    }

    @include mq($from: tablet) {
        height: auto;
        display: block;

        .c-dropdown__label {
            padding: 0;

            .c-icon {
                @include rem(width, 20px);
                @include rem(height, 20px);
            }
        }
    }

    .c-store-switcher__label,
    .c-store-switcher__item {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .c-store-switcher__item-icon,
    .c-store-switcher__label-icon {
        @include rem(width, 13px);
        @include rem(margin-right, 10px);

        > svg {
            @include rem(margin-top, 4px);
        }
    }
}

.c-mobile-nav__store-switcher {
    margin-left: auto;
}

