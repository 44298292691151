//------------------------------------
// #AKM2
//------------------------------------

@import 'akm2/akm2.chapters';
@import 'akm2/akm2.map';
@import 'akm2/akm2.pdp';
@import 'akm2/akm2.view-switcher';

.c-akm2__preface p {
    @include font-size(14px, 1.3);
}

.c-akm2__hero-image {
    @include rem(margin-bottom, 20px);

    display: inline-block;
}

.c-akm2__hero-image__img {
    display: block;
    width: 100%;
    max-width: 480px;
}

.c-akm2__breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-akm2__title {
    @include rem(margin-bottom, 10px);
    @include font-weight(bold);
    @include rem(font-size, 18px);

    text-transform: none;
    color: $color-black;
}

.c-akm2__link,
.c-akm2__link:visited {
    color: $color-ui-grey-2;
    text-decoration: none;
}

.c-akm2__link:hover {
    color: $color-links;
}

.c-akm2__list {
    @include rem(margin-bottom, 70px);
}

.c-akm2__plp-list-title {
    @include font-size(20px);

    @include mq($from: tablet) {
        @include font-size($font-size-gamma);

    }
}
