//------------------------------------
// #PRODUCT
//------------------------------------

.c-product__item {
    @include rem(margin-bottom, 18px);
}

.c-product__img {
    @include rem(margin-bottom, 15px);

    width: 100%;
}

.c-product__title {
    @include rem(min-height, 64px);

    display: block;
    text-decoration: none;
}

.c-product__title--pdp {
    @include rem(padding-top, 15px);
    @include rem(padding-bottom, 15px);
    @include mobile-gutter();
    @include font-size($font-size-gamma);
}

.c-product__add-to {
    a {
        @include rem(margin-right, 5px);

        float: left;
    }
}

.c-product__rating {
    @include font-size($font-size-zeta);
    @include rem(margin-bottom, 15px);

    display: flex;
    justify-content: space-between;

    .c-product__rating-review-action {
        &,
        &:hover,
        &:visited,
        &:active {
            color: $color-brand-primary;
            color: var(--color-brand-primary);
        }
    }

    @include mq($from: desktop) {
        justify-content: flex-start;

        .c-product__no-rating {
            @include rem(margin-right, 10px);
        }

        .c-star-rating {
            @include rem(margin-right, 10px);
        }
    }
}
