//------------------------------------
// #CHECKOUT PAYMENT OPTION
//------------------------------------

.c-checkout__payment-option {
    @include clearfix();
    @include rem(margin-bottom, 24px);

    .c-input-wrap__label {
        @extend %label;
        @include rem(margin-bottom, spacer(.5));

        @include mq($from: tablet) {
            @include rem(margin-bottom, spacer());
        }
    }

    &__messages {
        @include font-size(12px, 1);

        width: 100%;

        @include mq($from: tablet) {
            width: 270px;
        }

        .message-error,
        .message-success {
            @include font-size(12px, 1); // Override base

            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
            border: 0;
        }

        .message-error {
            color: $color-validation-error;
        }

        .message-success {
            color: $color-brand-secondary;
        }

        .c-icon {
            @include rem(margin-right, 10px);

            width: 12px;
            height: 12px;
            float: left;
        }
    }

    &.c-accordion {
        .c-input-wrap__label {
            @include mq($until: desktop) {
                @include visually-hidden();
            }
        }
    }
}

.c-checkout__payment-option {
    .c-input-wrap {
        margin-bottom: 0;
    }
}

.c-checkout__payment-option__control,
.c-checkout__payment-option__actions {
    @include mq($from: desktop) {
        display: table-cell;
        vertical-align: top;
    }
}

.c-checkout__payment-option__control {
    @include mq($from: desktop) {
        width: 270px;
    }
}

.c-checkout__payment-option__actions {
    @include rem(padding-top, 12px);

    .c-btn {
        @include rem(margin-bottom, 10px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include mq($from: desktop) {
        @include rem(padding-left, 12px);

        padding-top: 0;

        .c-btn {
            margin-bottom: 0;
        }
    }
}

.c-tabs .c-tabs__tab-content .c-checkout__messages {
    // Prevent checkout messages breaking layout width
    width: auto;
}

.checkout-payment > .page-wrapper > .page-main > .c-page__columns > .c-page__main > .checkout-container > .o-layout > .c-checkout__messages > .message-error{
    display:none !important;
}
