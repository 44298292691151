//------------------------------------
// #CHECKOUT SIDEBAR
//------------------------------------

.c-checkout__sidebar-wrapper {
    &__underlay {
        background: $color-black;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        z-index: zIndex('summary-overlay') - 1;

        transition: visibility 0s ease $base-transition, opacity $base-transition ease;
    }

    &__close {
        text-align: right;
        @include rem(padding, 15px 15px 0 15px);
        cursor: pointer;

        .c-icon {
            width: 20px;
            height: 20px;
        }
    }

    // Overlay styling when on mobile
    @include mq($until: tablet) {
        z-index: zIndex('summary-overlay');
        position: fixed;
        top: 0;
        right: 0;
        background-color: $color-checkout-sidebar-bg;
        height: 100%;
        width: 90%;
        padding-left: 0;
        margin-right: -90%; // Move off-screen unless active
        box-shadow: 1px 1px 0 0 rgba(0, 0, 0, .5);

        transition: margin-right $base-transition ease, box-shadow $base-transition ease;

        &.c-checkout__sidebar-wrapper--active {
            margin-right: 0;
            box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, .5);

            // Ensure sidebar can be manipulated when body cannot
            touch-action: manipulation !important;
        }
    }
}

.c-checkout__sidebar-wrapper--active + .c-checkout__sidebar-wrapper__underlay {
    // Transition hack to prevent visibility changing before transition starts
    transition-delay: 0s;

    visibility: visible;
    opacity: .6;
}
.c-checkout__sidebar__text {
    display: none;
}

.c-checkout__sidebar__title {
    display: block;
}

.c-checkout__sidebar .opc-block-summary .items-in-cart .minicart-items {
    display: block;
}

.c-checkout__sidebar-toggle {
    display: block;
    float: right;
    cursor: pointer;

    &__qty {
        @include font-size(16px, 1.7);

        display: inline-block;
        text-align: center;
        width: 40px;
        vertical-align: top;
        color: $color-white;
    }

    .c-icon {
        width: 40px;
        height: 40px;
    }
}

.c-checkout__sidebar {
    @include rem(padding, 15px 20px);
    @include rem(margin-bottom, 15px);

    margin-top: 0;
    background-color: $color-checkout-sidebar-bg;

    &__title {
        @extend %heading;

        @include rem(padding-bottom, 10px);
        @include rem(margin-bottom, 10px);

        a {
            @include font-weight(normal);
            @include font-size(16px, 2.25);
            @include link-color($color-brand-secondary, $color-brand-secondary);

            font-family: $font-family-secondary;
            display: inline-block;
            float: right;
        }

        &--condensed {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
}

.c-checkout__sidebar__item {
    @include rem(margin-bottom, 10px);

    &__inner {
        display: table;
        width: 100%;

        > div {
            display: table-cell;
        }
    }

    &__title {
        font-family: $font-family-secondary;
        margin-bottom: 0;
    }

    &__subtotal {
        font-family: $font-family-secondary;
        text-align: right;

        .price {
            @extend %price;
        }
    }
}

.c-checkout__sidebar__totals {
    width: 100%;
    margin-bottom: 0;

    th, td {
        @include font-weight(normal);

        font-family: $font-family-secondary;
        text-align: right;
        padding: 0;
    }

    // Set column widths using utility classes here rather than applying to every th/td within the templates
    th {
        @extend .u-8-of-12;
    }

    td {
        @extend .u-4-of-12;
    }

    tr {
        border-bottom: 0;
    }

    .amount {
        text-align: right;
        font-family: $font-family-secondary;

        .price {
            @extend %price;
        }
    }

    .grand {
        th, td {
            color: $color-black;
            @include rem(padding-top, 10px);
        }

        .price {
            @include font-size(18px);
            @include font-weight(bold);
            color: $color-black;
        }
    }
}

.c-checkout__sidebar__delivery-method {
    @extend %label;
    @include font-size(16px);

    // Pull element outside of padding (as apposed to adding separate padding to all internal elements)
    @include rem(margin-left, -20px);
    @include rem(margin-right, -20px);

    @include rem(margin-bottom, 10px);
    background-color: $color-delivery-method-background;
    @include rem(padding, 5px 20px);
}

body {
    // Class to prevent body scroll when summary is open
    &.disable-scrolling {
        overflow: hidden;
        touch-action: none;
    }
}
