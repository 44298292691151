//------------------------------------
// #INPUT
//------------------------------------

.c-form__control {
    @include rem(padding, 3px 13px);

    font-family: $font-family-secondary;
}

.c-input-wrap__label {
    padding-left: 0;
    color: $color-label;
    font-family: $font-family-secondary;
}

.c-input-wrap,
.c-form__group {
    @include rem(margin, 0 0 $spacing-unit-small);

    position: relative;

    @include mq($from: desktop) {
        @include rem(margin-bottom, $spacing-unit);
    }

    input[type=text],
    input[type=password],
    input[type=email],
    input[type=number],
    input[type=tel],
    input[type=url],
    select,
    textarea {
        @extend .c-form__control;

        &.c-form__control {
            background-color: $color-off-white;
            border: 1px solid $color-off-white;
        }

        &.c-input__dark {
            background-color: $color-white;
            border: 1px solid $color-white;
        }

        &.c-form__control,
        &.c-input__dark {
            color: $color-ui-grey-2;

            &:focus {
                border: 1px solid $color-brand-secondary;
            }

            @include placeholder-color($color-ui-grey-4);
        }
    }

    label {
        @extend .c-input-wrap__label;
    }

    textarea {
        @include rem(padding, 13px);
    }
}

.c-input-wrap__field-note {
    @include rem(margin-top, 10px);

    display: flex;
    align-items: baseline;

    &__text {
        @include font-size(11px, 1.3);

        display: block;
        overflow: hidden;
    }
}

div.mage-error {
    font-family: $font-family-secondary;
}

.password-strength-meter {
    font-family: $font-family-secondary;
}
