//------------------------------------
// #PRODUCT LIST
//------------------------------------

.c-product-list__item {
    height: 100%;

    .c-product-list__item-header {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .c-saved-items__list & {
        display: flex;
        flex-direction: column;
    }

    .c-product-list__item-title {
        @include font-size(15px, 1.4);
        @include font-weight(semi-bold);
        @include link-color($color-black, $color-brand-secondary);
        @include rem(min-height, 105px);

        display: block;
        text-decoration: none;
        letter-spacing: -0.1px;
        flex-grow: 1;

        @include mq($from: tablet) {
            @include rem(min-height, 65px);
        }

        &:hover {
            text-decoration: none;
        }
    }

    .c-product-list__item-photo {
        display: block;
    }

    .c-product-list__item-photo img {
        width: 100%;
        height: auto;
    }

    .c-product-list__item-availibility {
        display: inline-block;
    }

    .c-product-list__secondary-actions {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }
}

.c-product-list__item-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .price-label {
        margin: 0;
    }

    .c-grouped-price__wrapper {
        margin: 0;
    }

    .c-product__special-price,
    .c-product__current-price {
        @include rem(margin-top, 22px);
    }

    .c-price-box, .price-box {
        @include rem(margin-bottom, 5px);

        flex-grow: 1;
        display: block;

        @include mq($from: tablet) {
            margin-bottom: inherit;
        }

        .c-product-list__item--list & {
            margin-bottom: 0;
        }

        .c-product__price {
            @include rem(font-size, 17px);
            @include rem(margin-bottom, 5px);
            @include font-weight(semi-bold);

            &:after {
                @include rem(font-size, 12px);
            }

            .c-vat__label {
                text-transform: uppercase;
                @include rem(font-size, 12px);
            }
        }
    }
}

.c-product-list__item-info {
    display: flex;
    flex-flow: row wrap;

    .c-product-list__item-sku,
    .c-product-list__item-newin {
        display: flex;
        line-height: 1;

        @include rem(margin, 5px 15px 10px 0);
        @include rem(font-size, 12px);
    }
}

.c-plp__wrapper.c-plp__wrapper--list .o-grid {
    display: block;

    .o-grid__item {
        width: auto;
        margin: 0;
    }
}

.c-product-list__item--list {
    display: flex;

    .c-product-list__item-header.o-media {
        display: inline-block;

        .c-product-list__item-photo img {
            @include rem(width, 160px);

            display: block;
            max-width: unset;

            @include mq($from: tablet) {
                // @see app/design/frontend/MiniSpares/default/etc/view.xml:23
                @include rem(min-width, 230px);

                width: auto;
            }
        }
    }

    .c-product-list__item-main.o-media__body {
        @include rem(margin-left, 10px);

        .c-product-list__item-title {
            @include font-size($font-size-zeta, 1.5);

            min-height: initial;
            margin-bottom: 0;
            flex-grow: 0;

            @include mq($from: tablet) {
                @include rem(font-size, 20px);
            }
        }
    }

    .c-product-list__item-actions {
        @include rem(margin-top, 10px);

        form {
            width: 100%;
        }
    }

    .c-input-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        flex-wrap: wrap;

        @include mq($from: desktop) {
            flex-wrap: nowrap;
        }

        .c-input-qty__label {
            order: 1;
        }

        .c-input__qty {
            order: 2;
        }

        .c-pdp__stock {
            white-space: nowrap;
            order: 3;
        }

        .c-btn {
            @include rem(margin-top, 10px);

            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            order: 4;

            @include mq($from: desktop) {
                margin-top: 0;
            }
        }

        .c-input-qty__label,
        .c-input__qty,
        .c-pdp__stock {
            @include rem(margin-right, 10px);

            margin-bottom: 0;
        }
    }

    .c-product-list__item-footer {
        @include rem(border-top-left-radius, 5px);

        position: absolute;
        bottom: 0;
        right: 0;
        background-color: rgba($color-white, .7);

        .c-wishlist__link {
            margin-bottom: 0;
        }
    }

    .c-product-list__item-cart-wrapper {
        margin-top: auto;
    }

    .c-product-list__secondary-actions {
        display: block;

        @include mq($from: tablet) {
            display: flex;
        }

        a {
            @include rem(margin, 0 10px);
        }
    }
}

.c-product-list__item-inner .c-saved-items__tocart {
    display: flex;

    .c-saved-items__tocart-qty,
    .c-saved-items__tocart-buy {
        width: 50%;
    }

    @include mq($from: desktop) {
        display: block;

        .c-saved-items__tocart-qty,
        .c-saved-items__tocart-buy {
            width: auto;
        }
    }
}

.c-product-list__item-actions {
    @include rem(margin-top, 5px);

    .c-btn {
        @include rem(margin-top, 10px);
    }
}
