//------------------------------------
// #PRODUCT VIDEO
//------------------------------------

.c-product-videos__title {
    @include font-size($font-size-zeta, 1.3);

    color: $color-black;
    font-weight: bold;
}

.c-product-videos__iframe-wrapper {
    @include rem(padding-top, 25px);

    position: relative;
    padding-bottom: 56.25%; // 16:9
    height: 0;
}

.c-product-videos__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
