//------------------------------------
// #PRODUCT EXTEND
//------------------------------------

.c-product__item {
    @include rem(margin-bottom, 30px);
}

.c-product__img {
    width: 100%;
}

.c-product__title {
    display: block;
    text-decoration: none;
    min-height: 66px;

    @include rem(margin-bottom, 5px);
    @include font-size(18px, 1.222);
    @include link-color($color-black, $color-brand-secondary);

    @include mq($until: tablet) {
        @include rem(font-size, 13px);
    }

}

.c-product__sku,
.c-product__price,
.c-product__stock-status {
    @include rem(margin-bottom, 5px);
}

.c-product__stock-status {
    display: inline;
}

.c-pdp__stock {
    @include rem(margin-bottom, 10px);

    .c-icon--tick-sm-icon {
        width: 12px;
        height: 10px;
    }
}

.c-product__price {
    font-family: $font-family-default;
}

.c-product__price--excl,
.c-product__price--incl {
    &:after {
        content: " " attr(data-label);
        left: 0;

        @include rem(font-size, 14px);
    }
}

.c-pdp {
    a {
        @include rem(font-size, 16px);
    }
}

.c-pdp__content .c-pdp__content {
    padding-top: 10px;
    padding-bottom: 10px;
}

.c-pdp__content-main {
    a {
        color: $color-black;
        text-decoration: none;

        @include font-weight('bold');

        &::after {
            @include rem(margin-left, 5px);
            @extend .c-icon--secondary-down-arrow-icon;

            content: " ";
            width: 12px;
            height: 8px;
            display: inline-block;
        }
    }

    p {
        color: $color-black;
        line-height: 1.8;
    }

    @include mq($until: tablet) {
        p {
            display: none;
        }
    }

}

.c-product__sku--wrapper {
    @include rem(margin-bottom, 10px);
    @include font-size(12px, 1.25);

    display: flex;
    justify-content: space-between;
}

.c-product__btn {
    @include mq($until: tablet) {
        @include rem(font-size, 13px);
    }
}

.c-product__add-to {
    a {
        @include rem(margin-right, 15px);

        float: left;
        color: $color-ui-grey-2;
        text-decoration: none;

        @include mq($until: tablet) {
            @include rem(font-size, 13px);
        }

    }

}

.c-price-box {
    @include rem(margin-bottom, 10px);

    @include mq($until: desktop) {
        display: flex;
        flex-direction: column;
    }

}

.c-product__special-price,
.c-product__special-price-pdp {
    display: inline-block;

    @include mq($until: desktop) {
        order: 1;
    }

    .c-product__price {
        color: $color-red;
    }

}

.c-product__old-price {
    @include rem(margin-right, 10px);

    float: left;
    display: none;

    .c-product__price {
        text-decoration: line-through;
        color: $color-ui-grey-4;
    }

}

.c-product__price {
    @include font-size(22px);

    font-weight: bold;
    color: $color-black;

    .c-product__vat-label {
        @include font-size(11px);
        @include rem(margin-left, 8px);

        text-transform: uppercase;
    }
}

// End Pricing styling
.c-product__new-in {
    @include font-weight(bold);

    text-transform: uppercase;
    color: $color-brand-secondary;
    margin-left: auto;
    margin-right: 0;
}

.c-price__label {
    @include rem(font-size, 11px);

    &--special-price {
        @include font-weight(bold);

        margin: 0;
        color: $color-special-price-label;
    }

    &--old-price {
        line-height: 1;
    }
}
