//------------------------------------
// #NAVIGATION
//------------------------------------

// Desktop
.c-navigation {
    position: relative;
    background-color: $color-brand-primary;
    background-color: var(--color-brand-primary);

    ul {
        list-style: none;
        margin: 0;
    }

    a {
        display: block;
        color: $color-white;
        color: var(--color-white);

        @include hocus() {
            text-decoration: none;
        }
    }

    @include mq($until: desktop) {
        display: none;
    }
}

.c-navigation--no-touch .c-navigation__categories > li:hover .c-navigation__flyout {
    display: block;
}

.c-navigation__categories {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > li > a {
        @include rem(padding, 10px 25px);

        @if ($is-menu-uppercase) {
            text-transform: uppercase;
        }
    }

    > li:first-child > a {
        padding-left: 0;
    }

    > li:last-child > a {
        padding-right: 0;
    }
}

.c-navigation__flyout {
    @include rem(padding, 20px 0 50px);

    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: zIndex('dropdown');
    transform: translateY(100%);
    width: 100%;
    background-color: $color-white;
    background-color: var(--color-white);
    color: $color-body;
    color: var(--color-body);
    box-shadow: 0 26px 28px -9px rgba(0, 0, 0, .1);
    border-top: solid 30px $color-ui-grey-4;
    border-top: solid 30px var(--color-ui-grey-4);

    a {
        @include rem(padding, 12px 0);

        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);

        @include hocus() {
            color: $color-brand-primary;
            color: var(--color-brand-primary);
        }
    }

    ul > li > ul > li > a {
        @include rem(padding-left, 10px);
        @include rem(padding-top, 8px);
        @include rem(padding-bottom, 8px);

        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }

    &.c-navigation__flyout--is-active {
        display: block;
    }
}

// Mobile
.c-mobile-nav {
    .c-mobile-nav__underlay {
        background: $color-black;
        background: var(--color-black);
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        z-index: zIndex('topmenu') - 1;
        transition: opacity .5s ease;
    }

    &.is-active {
        .c-mobile-nav__underlay {
            pointer-events: auto;
            opacity: .5;
        }

        .c-mobile-nav__container {
            transform: translate3d(0, 0, 0);
        }
    }
}

.c-mobile-nav__container {
    display: none;
    width: 80%;
    height: 100%;
    position: fixed;
    transform: translate3d(-100%, 0, 0);
    transition: transform .5s ease;
    top: 0;
    left: 0;
    z-index: zIndex('topmenu');

    @include mq($from: tablet) {
        width: 45%;
    }

    ul {
        list-style: none;
        margin: 0;
    }

    a {
        @include rem(padding, 12px 15px);

        display: block;

        @include hocus() {
            text-decoration: none;
        }
    }
}

.c-mobile-nav__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $color-white;
    background-color: var(--color-white);
}

.c-mobile-nav__header {
    display: flex;

    > li > a {
        @include rem(padding, 18px 25px);

        line-height: 1;
        font-size: $font-size-zeta;

        &.c-nav__link--is-current {
            pointer-events: none;
            color: $color-brand-primary;
            color: var(--color-brand-primary);
            font-weight: bold;
            box-shadow: inset 0 -4px 0 0 $color-brand-primary;
            box-shadow: inset 0 -4px 0 0 var(--color-brand-primary);
        }
    }
}

.c-mobile-nav__categories {
    flex: 1 1 0%;
    overflow-y: auto;

    .c-nav__link--all {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }

    // Primary level
    > li {
        > a {
            @include font-size($font-size-delta);

            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $color-ui-grey-1;
            color: var(--color-ui-grey-1);
            border-bottom: 1px solid $color-ui-grey-3;
            border-bottom: 1px solid var(--color-ui-grey-3);
        }

        > a::after {
            @include rem(height, 22px);
            @include rem(width, 15px);
            @include rem(background-size, 30px);

            content: ' ';
            background-image: url('../images/icons/svg/arrow-expand-down.svg');
            background-position: 50%;
            display: inline-block;
            pointer-events: none;
        }

        > ul {
            display: none;
        }

        .c-nav__link--is-active {
            &::after {
                transform: rotate(180deg);
            }

            + ul {
                display: block;
            }
        }
    }

    // Secondary level
    > li > ul > li > a {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
    }

    // Tertiary level
    > li > ul > li > ul > li > a {
        @include rem(padding-left, 20px);
    }
}
