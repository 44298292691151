//------------------------------------
// #AKM2 CHAPTERS
//------------------------------------

.c-akm2__chapters,
.c-akm2__chapters:visited {
    color: $color-black;
    font-size: 18px;

    @include mq($until: tablet) {
        font-size: 12px;

        .c-icon--arrow-right {
            width: 15px;
            height: 8px;
        }
    }
}

.c-akm2__chapters:hover {
    color: $color-links;
}

.c-akm2__chapters__name {
    @include rem(margin-right, 10px);
    font-weight: bold;

    @include mq($until: tablet) {
        @include rem(margin-right, 5px);
    }
}

.c-akm2__chapter__item-wrap {
    @include rem(margin-bottom, 20px);
    height: 250px;
    display: inline-block;

    @include mq($until: tablet) {
        height: 220px;
    }
}

.c-akm2__chapter__image {
    width: 100%;
    border: 2px solid $color-links;
}

.c-akm2__chapter__image:hover {
    border: 2px solid $color-black;
}

.c-akm2__chapter__title {
    margin: 0;
    font-size: 14px;
    color: $color-black;
    font-weight: bold;
    text-decoration: none;
    height: 65px;
    text-align: left;
    line-height: 1.2em;
}
