//------------------------------------
// #MEDIA
//------------------------------------

// Place any image- and text-like content side-by-side, as per:
// http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code

.o-media {
    @include clearfix();

    display: block;
}

.o-media__img {
    @include rem(margin-right, $base-spacing-unit-small);

    float: left;
    overflow: hidden; // Used to negate effect of javascript-added padding-bottom on mini cart

    > img {
        display: block;
    }
}

.o-media__body {
    display: block;
    overflow: hidden;

    &,
    > :last-child {
        margin-bottom: 0;
    }
}

// Size variants
.o-media--large {
    > .o-media__img {
        @include rem(margin-right, $base-spacing-unit);
    }
}

// Reversed media objects
.o-media--reverse {
    > .o-media__img {
        @include rem(margin-left, $base-spacing-unit-small);
        
        float: right;
        margin-right: 0;
    }
}
