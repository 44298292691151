//------------------------------------
// #REACT SUGGEST
//------------------------------------

.c-search__suggestions {
    display: none;
    position: absolute;
    width: 100%;
    z-index: zIndex('react-suggestions');
    border-bottom: 1px solid $color-suggestions-input-border;
    border-bottom: 1px solid var(--color-suggestions-input-border);
    background-color: $color-suggestions-input-bg;
    background-color: var(--color-suggestions-input-bg);
    border-top: 0;
    max-height: $react-search-suggestions-height;
    overflow-y: scroll;

    @include mq($from: tablet) {
        border-left: 1px solid $color-suggestions-input-border;
        border-left: 1px solid var(--color-suggestions-input-border);
        border-right: 1px solid $color-suggestions-input-border;
        border-right: 1px solid var(--color-suggestions-input-border);
    }

    &.c-search__suggestions--show {
        display: block;
    }
}

.c-search__suggestion .c-search__suggestion-link {
    @include rem(padding, $react-search-suggestions-top-bottom-padding 10px);
    @include rem(font-size, $font-size-delta);
    @include hocus('.c-search__suggestion-link--active') {
        background-color: $color-suggestions-hover-bg;
        background-color: var(--color-suggestions-hover-bg);
        color: $color-suggestions-hover-text;
        color: var(--color-suggestions-hover-text);
    }

    text-decoration: none;
    display: inline-block;
    width: 100%;
    line-height: 1;
}
