//------------------------------------
// #CURRENT FILTER
//------------------------------------

.c-current-filter {
    .c-current-filter__title {
        display: inline-block;
        margin: 0;
        padding: 0;
        line-height: inherit;

        @include rem(font-size, 17px);

        @include mq($from: desktop) {
            display: block;
        }
    }
}

.c-current-filter__items {
    margin: 0;
    padding: 0;
    list-style: none;

    .c-current-filter__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-ui-grey-2;
    }

    .c-current-filter__item a {
        line-height: 1;

        @include rem(font-size, 16px);

        @include hocus() {
            text-decoration: none;
        }
    }

    .c-link--remove-filter {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        background-color: $color-remove-current-filter-bg;
        color: $color-remove-current-filter;
        border-radius: 4px;

        @include font-weight(normal);

        &:hover {
            background-color: lighten($color-remove-current-filter-bg, 10%);
        }

        .c-icon--close {
            width: 25px;
            height: 25px;
        }
    }

    .c-current-filter__item .c-current-filter__label {
        @include font-weight(bold);
    }

    .c-current-filter__item .c-current-filter__value {
        color: $color-active-filter-font;
        background-color: $color-active-filter-background;
        @include rem(padding, 2px 4px);
        border-radius: 4px;

        @include font-weight(bold);
    }
}
