//------------------------------------
// #BUTTONS EXTEND
//------------------------------------

.c-btn,
.c-button {
    @include rem(padding, 7px 25px);

    border: 0;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: $base-transition;
    font-family: $font-family-default;

    @include font-size(16px);

    &:focus,
    &:hover,
    &:active {
        outline: none;
        text-decoration: none;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }
}

.c-btn--primary,
.c-button--primary {
    background: $color-brand-primary;
    color: $color-white;

    @include link-color($color-white, $color-white);

    &:focus,
    &:hover,
    &:active {
        background: darken($color-brand-primary, 10%);
    }

    &:focus {
        border: none;
    }
}

.c-btn--secondary,
.c-btn.action-secondary,
.c-button--secondary {
    background: $color-brand-secondary;
    color: $color-white;

    @include link-color($color-white, $color-white);

    &:focus,
    &:hover,
    &:active {
        background: darken($color-brand-secondary, 10%);
    }
}

.c-btn--tertiary {
    background-color: $color-white;
    color: $color-brand-secondary;
    border: 1px solid $color-brand-secondary;

    &:focus,
    &:hover,
    &:active {
        background: darken($color-white, 10%);
        border-color: darken($color-brand-secondary, 10%);
    }
}

.c-btn--link {
    background-color: transparent;
    height: auto;
    padding: 0;
    @include font-weight(bold);

    @include link-color($color-black, $color-black);
}

.c-btn--icon {
    position: relative;
    @include rem(padding-right, 55px);

    .c-icon {
        @include rem(margin-top, -8px);

        position: absolute;
        right: 20px;
        top: 50%;
    }
}

.c-button--checkout,
.c-btn--checkout {
    background: $color-green;
    color: $color-white;

    @include link-color($color-white, $color-white);

    &:focus,
    &:hover,
    &:active {
        background: darken($color-green, 10%);
    }
}

.c-btn--discount,
.c-btn--giftcard {
    @extend .c-btn--tertiary;
    @include font-weight(bold);

    width: 100%;
    border-width: 2px;
    min-width: unset;

    @include mq($from: tablet) {
        width: auto;
    }

    &:focus,
    &:hover,
    &:active {
        color: $color-white;
    }
}

.c-btn--full {
    width: 100%;
}

.c-button--large,
.c-btn--large {
    @include rem(padding-top, 16px);
    @include rem(padding-bottom, 16px);
}
