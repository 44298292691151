//------------------------------------
// #REACT LISTINGS CAMPAIGNS
//------------------------------------

.c-listings__group {
    height: 100%;
}

.c-listings__group.c-listings__group--has-campaign {
    > .c-listings__group {
        order: 2;

        @include mq($from: desktop) {
            order: 1;
        }
    }

    .c-listings__campaign {
        order: 1;

        @include mq($from: desktop) {
            order: 2;
        }
    }

    &.o-grid--2-col\@mobile,
    &.o-grid--2-col--from-mobile {
        @include mq($from: mobile) {
            > .c-listings__group {
                width: 100%;
                margin: 0;
            }

            .c-listings__campaign {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    &.o-grid--2-col\@tablet,
    &.o-grid--2-col--from-tablet {
        @include mq($from: tablet) {
            > .c-listings__group {
                width: 100%;
                margin: 0;
            }

            .c-listings__campaign {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    &.o-grid--4-col\@desktop,
    &.o-grid--4-col--from-desktop {
        $grouped-item-width: calc(50% - #{$grid-gutter});

        @include mq($from: desktop) {
            > .c-listings__group {
                width: 50%;
                margin: 0;

                .c-listings__item {
                    width: $grouped-item-width;

                    // Match width of items in 4 column
                    @if function-exists(calculate-grid-gutter-width) {
                        @include rem(margin-right, calculate-grid-gutter-width(4));
                    } @else {
                        // Fallback if function not present
                        @include rem(margin-right, 24px);
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            .c-listings__campaign {
                width: $grouped-item-width;
                margin-right: 0;
            }
        }
    }
}
