//------------------------------------
// #RADIO
//------------------------------------

.c-radio {
    .c-radio__wrap {
        position: relative;
        min-height: 110px;
        cursor: pointer;
        background-color: $color-radio-background;
        width: 100%;
        height: 100%;
        @include rem(padding, 15px);

        transition: background-color $base-transition ease;

        @include mq($from: 'tablet') {
            @include rem(padding, 30px);
        }

        // Hide default radio input if present
        .c-radio__input {
            visibility: hidden;
        }

        &:hover {
            background-color: darken($color-radio-background, 10%);
        }
    }

    .c-radio__label {
        @include rem(margin-left, 35px);

        display: block; // If label happens to be an inline element
        cursor: pointer;

        @include mq($from: tablet) {
            @include rem(margin-left, 55px);
        }
    }

    .c-radio__header,
    .c-radio__content {
        @include font-size(14px, 1.2);

        font-family: $font-family-secondary;
        color: $color-radio-text;
        display: block; // If element happens to be inline

        @include mq($from: tablet) {
            @include font-size(14px, 1.4);
        }
    }

    .c-radio__header {
        @include font-weight(bold);
    }

    .c-radio__content + .c-radio__content {
        @include rem(margin-top, 10px);
    }

    .c-radio__dot {
        @include rem(padding, 2px);

        display: inline-block;
        float: left;
        border: 5px solid $color-radio-wrapper-border;
        background-color: $color-radio-wrapper-background;
        border-radius: 50%;
        height: 20px;
        width: 20px;

        transition: background-color $base-transition ease;
    }

    // Hide default radio input if present
    .c-radio__input {
        // Sit input underneath "dot"
        @include rem(margin-left, 19px);
        @include rem(margin-top, 3px);

        position: absolute;
        z-index: zIndex('radio-input');

        @include mq($from: 'tablet') {
            @include rem(margin-left, 34px);
        }

        &:focus + .c-radio__dot {
            @include add-default-focus-styles();
        }

        &:checked + .c-radio__dot {
            background-color: $color-radio-wrapper-active-background;
        }
    }

    &.selected-item {
        .c-radio__wrap {
            background-color: $color-radio-active-background;

            &:hover {
                background-color: darken($color-radio-active-background, 10%);
            }
        }

        .c-radio__dot {
            background-color: $color-radio-wrapper-active-background;
        }
    }
}

// Styling used when rendering a radio button as a tab on checkout
.c-radio--tab {
    height: 100%;

    .c-radio__wrap {
        @extend .u-flex-align-central;
        @include rem(padding, 10px);

        min-height: 65px;

        @include mq($from: tablet) {
            @include rem(padding, 30px);

            min-height: 120px;
        }
    }

    .c-radio__inner {
        position: relative;
    }

    .c-radio__input {
        @include rem(margin-left, 4px);
        @include rem(margin-top, 4px);

        left: 0;
    }

    .c-radio__label {
        @include rem(margin-left, 25px);

        @include mq($from: tablet) {
            @include rem(margin-left, 30px);
        }
    }

    .c-radio__header {
        @include font-size(14px);

        @include mq($from: tablet) {
            @include font-size(18px);
        }

        color: $color-black;
        font-family: $font-family-default;
    }

    &.c-radio--small {
        .c-radio__inner {
            display: flex;
            align-items: center;
        }

        .c-radio__header {
            @include font-size(14px, 1.5);
        }

        .c-radio__label {
            @include rem(margin-left, 10px);
        }

        .c-radio__wrap {
            @include rem(padding, 10px);

            min-height: 65px;
        }
    }
}

.c-radio__link {
    @include font-size(16px);

    float: right;
    border: 0;

    @include mq($until: 'tablet') {
        @include font-size(14px);
    }
}

.c-radio--inline {
    .c-radio__wrap {
        min-height: unset;
        background-color: transparent;
        padding: 0;
        margin: 10px 0;

        &:hover {
            background-color: transparent;
        }
    }

    .c-radio__input {
        // Position hidden input beneath "dot"
        @include rem(margin-left, 4px);
        @include rem(margin-top, 5px);

        &:checked + .c-radio__dot {
            background-color: $color-radio-wrapper-active-background;
        }
    }

    .c-radio__label {
        @include rem(padding-left, 35px);

        margin-left: 0;
    }

    .c-radio__header,
    .c-radio__content {
        @include font-size(14px);

        display: inline-block;
        vertical-align: top;
    }

    .c-radio__dot {
        @include rem(margin-top, 2px);

        border-color: $color-radio-inline-border;
        background-color: $color-radio-background;
        pointer-events: none;
    }

    &.selected-item {
        .c-radio__wrap {
            &,
            &:hover {
                background-color: transparent;
            }
        }

        .c-radio__dot {
            background-color: $color-radio-wrapper-active-background;
        }
    }
}
