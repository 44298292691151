//------------------------------------
// #SHIPPING GRID
//------------------------------------

// As used for shipping address items on checkout

.o-shipping-grid {
    @include clearfix();
    @include rem(margin-bottom, 50px);

    display: block;

    .o-shipping-grid__item {
        @include rem(margin-bottom, $shipping-grid-gutter-half);
    }

    @include mq($from: 'tablet') {
        @include rem(margin-bottom, 100px);

        display: flex;
        flex-wrap: wrap;

        .o-shipping-grid__item {
            @include rem(padding-right, $shipping-grid-gutter-half);
            @include rem(margin-bottom, $shipping-grid-gutter-half);

            flex: 1;
            flex-basis: 50%;
            max-width: 50%;

            &:nth-child(2n) {
                @include rem(padding-left, $shipping-grid-gutter-half);

                padding-right: 0;
            }

            &:nth-child(n + 3) {
                @include rem(margin-top, $shipping-grid-gutter-half);
            }
        }
    }
}
