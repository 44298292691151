//------------------------------------
// #CHECKOUT STEP
//------------------------------------

.c-checkout__step-title {
    @extend %heading;

    &__link,
    &__sub-title {
        @include font-weight(normal);

        font-family: $font-family-secondary;
    }

    &__sub-title {
        @include font-size(12px, 2);

        color: $color-step-title-sub-title;
    }

    &__link {
        @include font-size(14px, 2);

        display: inline-block;
        float: right;

        a {
            text-decoration: underline;
            vertical-align: text-bottom;

            @include mq($from: desktop) {
                vertical-align: text-top;
            }
        }
    }
}

.c-checkout__step__heading {
    @extend %heading;

    border-bottom: 0;
}
