//------------------------------------
// #LISTINGS
//------------------------------------

.c-listings__toolbar--top {
    .c-pagination {
        display: none;
    }

    .c-listings__limiter {
        display: none;
    }
}

.c-listings__toolbar--bottom {
    .c-listings__pagesize {
        display: none;
    }

    .c-listings__sort {
        display: none;
    }

    .c-facet__toggle {
        display: none;
    }

    .c-listings__limiter {
        @include mq($until: 'tablet') {
            display: none;
        }
    }
}

.c-listings__toolbar-content {
    display: flex;
    flex-flow: wrap;
    align-items: center;
}

.c-listings__limiter {
    float: right;
    text-align: right;

    .c-form__label {
        display: inline-block;
    }

    .c-form__select {
        @include rem(margin, 0 3px);

        display: inline-block;
    }
}

.c-listings__pagesize {
    @include rem(margin-top, 30px);

    color: $color-ui-grey-2;
    color: var(--color-ui-grey-2);
    order: 1;

    @include mq($from: tablet) {
        order: unset;
        margin-top: 0;
    }
}

.c-listings__sort {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    .c-form__label {
        // Unsetting the visually hidden classes is hard if attempting to be mobile first, simplify by using `$until`
        @include mq($until: tablet) {
            // Hide label span when on mobile
            > span:not(.c-form__select) {
                @include visually-hidden();
            }
        }

        &,
        .c-form__select {
            width: 100%;
        }
    }

    @include mq($from: tablet) {
        .c-form__label {
            &,
            .c-form__select {
                width: auto;
            }

            .c-form__select {
                @include rem(margin-left, 5px);
            }
        }
    }
}

.c-listings__sort-order {
    @include rem(margin-left, 5px);
    @include rem(margin-right, 5px);

    display: inline-block;
    white-space: nowrap;

    &::after {
        @include rem(width, 12px);
        @include rem(height, 19px);
        @include rem(background-size, 12px);

        content: ' ';
        display: inline-block;
        background-image: url('../images/icons/svg/arrow-large-down.svg');
        background-repeat: no-repeat;
    }

    &.c-listings__sort-order--asc::after {
        transform: rotate(180deg);
    }

    &:hover,
    &:focus {
        opacity: 0.4;
    }
}

.c-listings__limiter,
.c-listings__sort {
    margin-bottom: 0;

    .c-form__label {
        &,
        > span {
            margin-bottom: 0;
        }
    }

    .c-form__select {
        &,
        .c-form__control {
            margin-bottom: 0;
        }
    }
}
