//------------------------------------
// #HEADINGS EXTEND
//------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-weight('bold');

    color: $color-black;
}
