//------------------------------------
// #CONTACT DETAILS
//------------------------------------

.s-contact-details {
    @include rem(font-size, 16px);

    color: $color-red;
    font-weight: bold;

    a {
        @include link-color($color-red, $color-red);

        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
        border: none;
    }
}
