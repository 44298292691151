//------------------------------------
// #HEADINGS
//------------------------------------

.c-heading {
    color: $color-black;
    text-transform: none;
    font-weight: bold;
}

.c-heading--reduce-margin {
    @include rem(margin-bottom, 5px);
}

.c-heading__link,
.c-heading__link:visited {
    text-decoration: none;
    color: $color-black;
}
