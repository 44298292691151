//------------------------------------
// #FOOTER NEWSLETTER EXTEND
//------------------------------------

.s-footer-newsletter__wrapper {
    @include mq($until: tablet) {
        padding-left: 0;
    }
}

.s-footer-newsletter {
    @include rem(padding-right, 45px);

    input {
        height: 35px;
    }

    @include mq($until: tablet) {
        @include rem(margin-top, 10px);

        padding-right: 0;

        .c-input-wrap {
            @include rem(margin-bottom, 10px);
        }
    }
}

.s-footer-newsletter__input {
    @include rem(margin-bottom, 5px);
}

.s-footer-newsletter__label {
    @include rem(font-size, 16px);
    @include rem(margin-bottom, 5px);

    display: block;
}

.s-footer-newsletter__label,
.s-footer-newsletter__submit {
    @include link-color($color-off-white, $color-off-white);
}

.s-footer-newsletter__submit {
    @include rem(margin-top, 5px);

    text-align: left;
    line-height: 1.25;

    .c-icon {
        @include rem(margin-left, 5px);
    }
}
