//------------------------------------
// #DASHBOARD NAVIGATION
//------------------------------------

.c-dashboard__navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    letter-spacing: -0.4px;

    li {
        @include rem(margin-bottom, 5px);
        @include rem(margin-left, 15px);

        @include mq($from: desktop) {
            @include rem(margin-bottom, 10px);
        }
    }

    li.current {
        color: $color-dashboard-navigation-active-link;
        margin-left: 0;

        &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 10px;

            @include rem(margin-right, 3px);

            &::before {
                @extend .c-icon--chevron-right-icon;
            }
        }
    }

    a {
        text-decoration: none;

        @include link-color($color-dashboard-link, $color-dashboard-link-hover);
    }
}
