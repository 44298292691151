//------------------------------------
// #CAMPAIGN GRID
//------------------------------------

.c-campaign-grid {
    img {
        @include rem(margin-bottom, 15px);
        
        width: 100%;
    }
}
