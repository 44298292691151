//------------------------------------
// #STAR RATING EXTEND
//------------------------------------

.c-star-rating {
    .c-star-rating__foreground,
    .c-star-rating__background {
        @include rem(height, 15px);

        &:before {
            content: none;
        }

        .c-icon {
            background-repeat: repeat-x;
            float: left;
            width: 100%;
            height: 100%;
        }
    }

    .c-star-rating__background {
        @include rem(width, 80px);
    }
}

.c-star-select {
    @include rem(font-size, 15px);

    .c-star__rating {
        margin-bottom: 0;
    }
}

.c-star-rating__label {
    font-family: $font-family-secondary;
}
