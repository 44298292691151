//------------------------------------
// #BREAKS
//------------------------------------

// Simple content break styles

hr {
    @include rem(margin, $base-spacing-unit 0);

    background: $color-ui-grey-3;
    background: var(--color-ui-grey-3);
    border: 0;
    height: 1px;
}
