//------------------------------------
// #CHECKOUT DELIVERY TYPES
//------------------------------------

.c-checkout__delivery-types {
    @include rem(margin-bottom, 30px);

    .c-radio--tab {
        .c-radio__wrap {
            justify-content: normal;
            align-items: normal;
            @include mq($until: tablet) {
                @include rem(padding, 15px);
            }
        }

        .c-radio__header {
            @include font-size(14px, 1.25);

            @include mq($from: tablet) {
                @include font-size(14px, 1.45);
            }
        }

        .c-radio__content {
            color: $color-ui-grey-2;
            @include rem(margin-top, 20px);
            @include mq($until: tablet) {
                @include rem(margin-top, 10px);
            }
        }

        &.selected-item {
            .c-radio__content {
                color: $color-black;
            }
        }
    }
}
