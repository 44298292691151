//------------------------------------
// #CHECKOUT CMS
//------------------------------------

.c-checkout-success-promo__link {
    display: block;
}

.c-checkout-success-promo__image {
    width: 100%;

    @include mq($from: desktop) {
        width: auto;
    }
}
