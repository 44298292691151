//------------------------------------
// #ALTERNATIVE PRODUCTS
//------------------------------------

.c-alternative-products {
    @include rem(margin-bottom, 60px);
}

.c-alternative-products__title {
    @include rem(margin-bottom, 10px);
    @include font-size(18px);

    display: flex;
    align-items: center;
    letter-spacing: -0.5px;

    @include mq($until: tablet) {
        @include rem(padding-top, 15px);
    }
}

.c-alternative-product__image-link img {
    display: block;
    max-width: none;
}

.c-alternative-product__items {
    display: flex;
    flex-flow: column;

    .c-alternative-product__item {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}

.c-alternative-product__wrapper {
    @include rem(margin, 32px 20px 0 15px);
}

.c-alternative-product__name a {
    font-weight: 800;
    color: $color-ui-black;
    text-decoration: none;
    line-height: 1;

    &:hover {
        text-decoration: none;
    }
}

.c-alternative-product__price {
    text-transform: uppercase;

    // because the pricing module has many divs with many margin-bottom values
    * {
        margin-bottom: 0;
    }

    .c-product__price {
        @include rem(font-size, $font-size-zeta);

        font-weight: normal;
        color: $color-ui-black;
    }

    .c-product__price--excl {
        @include rem(margin-top, 7px);
    }

    .c-product__price--excl::after,
    .c-product__price--incl::after {
        @include rem(font-size, 11px);

        display: block;
    }
}

.c-alternative-product__sku {
    @extend .c-product__sku--wrapper;
}
