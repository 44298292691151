//------------------------------------
// #SHOP BY CATEGORY
//------------------------------------

.c-sbc {
    @include mq($until: tablet) {
        margin: 0 auto;
        width: 90%;
    }

    .c-sbc__heading {
        @include rem(margin-bottom, 10px);

        @include mq($until: desktop) {
            @include rem(font-size, 18px);
        }
    }
}

.c-sbc__section {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include mq($until: tablet) {
        padding: 0;
    }

    @include mq($until: desktop) {
        flex-wrap: wrap;
    }
}

.c-sbc__item {
    @include rem(margin-right, 10px);
    @include rem(padding, 5px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: auto;
    border: 1px solid $color-mono-b;
    max-width: calc(33.75% - 10px);

    @include mq($until: desktop) {
        @include rem(margin-bottom, 10px);
    }

    @include mq($from: desktop) {
        max-width: calc(11.11% - 10px); // 100 / 9
    }

    &:nth-of-type(3n) {
        @include mq($until: desktop) {
            margin-right: 0;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.c-sbc__img {
    max-width: 100%;
    height: auto;
    flex: 0 0 auto;
}

.c-sbc__title {
    @include rem(padding-top, 5px);
    @include font-size(10px, 1.25);
    margin: auto 0;

    @include mq($from: desktop) {
        @include font-size(13px, 1.25);
        @include rem(padding-top, 10px);
        @include rem(padding-bottom, 10px);
    }
}
