//------------------------------------
// #AKM2 MAP
//------------------------------------


.c-akm2__map__list {
    @include mq($from: desktop) {
        @include rem(padding-right, $base-layout-gutter);
        @include rem(height, 900px);

        overflow-y: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: rem(4px);
            background-color: $color-scrollbar-bg;
            box-shadow: 0 0 rem(1px) $color-scrollbar-box-shadow;
        }
    }
}

.c-akm2__map__list__item {
    margin: 0 !important;
    @include rem(padding-left, 5px);
    border-bottom: 1px solid lighten($color-ui-grey-4, 15%);
    height: 34px;
}

.c-akm2__map__list__item:hover {
    background-color: $color-list-akm2-highlight;
    cursor: pointer;
}

.c-akm2__map__list__value {
    height: 34px;
    line-height: 34px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.c-akm2__map__list__title--label,
.c-akm2__map__list__value--label {
    @include rem(padding-left, 5px);
}

.c-akm2__map__list__value--part-name {
    @include rem(margin-right, 10px);
}

.c-akm2__map__list__title {
    font-size: 14px;
    color: $color-black;
    font-weight: bold;
}

.c-akm2__map__image {
    width: 100%;
}

.c-akm2__map__anchors {
    position: relative;
}

.c-akm2__map__anchor {
    display: block;
    position: absolute;
    cursor: pointer;

    transition: background-color $transition-quickview-carousel-animation;

    &:hover,
    &:focus,
    &.active {
        background-color: rgba($color-list-akm2-highlight, .7);
    }
}

.c-akm2__map__list__item {
    transition: background-color $transition-quickview-carousel-animation;

    &.active {
        background-color: $color-list-akm2-highlight;
    }
}
