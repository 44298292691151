//------------------------------------
// #ICONS
//------------------------------------

.c-icon {
    display: inline-block;
    background-repeat: no-repeat;
    max-width: 100%;
}

.c-icon--arrow-right {
    @extend .c-icon--arrow-right-icon;

    width: 22px;
    height: 14px;
}

.c-icon--white-arrow-right {
    @extend .c-icon--white-arrow-right-icon;

    width: 22px;
    height: 14px;
}

.c-icon--twitter {
    @extend .c-icon--twitter-icon;

    width: 30px;
    height: 30px;
}

.c-icon--facebook {
    @extend .c-icon--facebook-icon;

    width: 30px;
    height: 30px;
}

.c-icon--payment-methods {
    @extend .c-icon--payment-types;

    width: 203px;
    height: 28px;
}

.c-icon--black-close {
    @extend .c-icon--black-close-icon;

    height: 20px;
    width: 20px;
}

.c-icon--delivery {
    @extend .c-icon--delivery-icon;

    height: 30px;
    width: 30px;
}

.c-icon--mini-logo {
    @extend .c-icon--usp-mini-logo;

    height: 30px;
    width: 30px;
}

.c-icon--globe {
    @extend .c-icon--globe-icon;

    height: 30px;
    width: 30px;
}

.c-icon--chevron-right {
    @extend .c-icon--chevron-right-icon;

    height: 10px;
    width: 10px;
}

.c-icon--exclamation::before,
.c-icon--question-mark::before {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: $color-white;
    text-align: center;
    flex-shrink: 0;
    @include rem(margin-right, 5px);
    @include font-weight(bold);
    @include font-size(12px, 18px);
}

.c-icon--exclamation::before {
    content: '!';
    background-color: $color-yellow;
}

.c-icon--question-mark::before {
    content: '?';
    background-color: $color-brand-secondary;
}

.c-icon--quick-view-icon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 5px;

    @include rem(margin-right, 3px);
}

.c-icon--zoom-icon {
    width: 20px;
    height: 20px;
}

.c-icon--thumb {
    @include rem(width, 15px);
    @include rem(height, 15px);
}

.c-icon--thumbs-down {
    @extend .c-icon--thumbs-up;

    transform: rotate(180deg);
}
