//------------------------------------
// #TABS
//------------------------------------

.c-tabs__wrapper {
    @include mq($until: desktop) {
        .o-region__inner {
            width: 100%;
        }
    }

    @include mq($from: desktop) {
        @include rem(padding, 60px 0);

        background-color: $color-ui-grey-5;
        background-color: var(--color-ui-grey-5);
    }
}

.c-tabs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .c-tabs__input {
        display: none; // Hide radio element by default
    }
}

.c-tabs__input {
    &:checked + .c-tabs__label {
        border-bottom: none;
        color: $color-tab-selected;
        color: var(--color-tab-selected);

        &::after {
            transform: rotate(180deg);
        }
    }

    &:checked + .c-tabs__label + .c-tabs__content {
        width: 100%;
        display: block;
    }
}

.c-tabs__label {
    @include font-size($font-size-zeta);
    @include rem(padding, 15px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0;
    font-weight: bold;
    color: $color-tab;
    color: var(--color-tab);
    cursor: pointer;
    border-top: 1px solid $color-ui-grey-3;
    border-top: 1px solid var(--color-ui-grey-3);
    border-bottom: 1px solid $color-ui-grey-3;
    border-bottom: 1px solid var(--color-ui-grey-3);
    background-color: $color-white;
    background-color: var(--color-white);

    .counter {
        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }
    }

    &::after {
        @include rem(height, 30px);
        @include rem(width, 30px);

        content: '';
        flex-shrink: 0;
        background-image: url('../images/icons/svg/arrow-expand-down.svg');
        display: inline-block;
        pointer-events: none;
    }
}

.c-tabs__label ~ .c-tabs__label {
    border-top: none;
}

.c-tabs__content {
    @include rem(padding, 0 15px 15px);

    display: none;
    border-bottom: 1px solid $color-ui-grey-3;
    border-bottom: 1px solid var(--color-ui-grey-3);

    p {
        @include font-size($font-size-zeta);

        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
    }

    p:last-child {
        margin-bottom: 0;
    }
}

@include mq($from: desktop) {
    .c-tabs {
        flex-direction: row;
    }

    .c-tabs__label {
        @include rem(padding, 15px 60px);
        @include rem(margin-bottom, -1px);

        flex-grow: 0;
        color: $color-ui-grey-3;
        color: var(--color-ui-grey-3);
        border: 1px solid $color-ui-grey-3;
        border: 1px solid var(--color-ui-grey-3);
        border-bottom-color: $color-white;
        border-bottom-color: var(--color-white);
        justify-content: center;

        &::after {
            display: none;
        }
    }

    .c-tabs__label ~ .c-tabs__label {
        border-top: 1px solid $color-ui-grey-3;
        border-top: 1px solid var(--color-ui-grey-3);
    }

    .c-tabs__input:checked + .c-tabs__label {
        color: $color-black;
        color: var(--color-black);
        border-color: $color-black;
        border-color: var(--color-black);
        border-bottom: 1px solid $color-white;
        border-bottom: 1px solid var(--color-white);
        box-shadow: 0 1px 0 -1px $color-white;
        box-shadow: 0 1px 0 -1px var(--color-white);
        position: relative;
    }

    .c-tabs__content {
        @include rem(padding, 30px);

        order: 1;
        border: 1px solid $color-black;
        border: 1px solid var(--color-black);
        background-color: var(--color-white);
    }
}
