//------------------------------------
// #FACETS EXTEND
//------------------------------------

.c-facets {
    @include mq($from: desktop) {
        display: block;
        max-width: 90%;
        margin-top: 0;
    }

    .ui-slider-handle {
        background: $color-brand-secondary;
    }

    .c-slider {
        width: calc(100% - 10px);
        float: none;
        margin: auto;
        max-width: inherit;
    }

    .c-slider-labels {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        span {
            padding: 0;
        }
    }

    .c-filter__slider-actions {
        .c-btn {
            @include link-color($color-white, $color-white);
        }
    }
}

.c-facets__content {
    @include rem(margin-bottom, 40px);

    display: none;

    &.active {
        display: block;

        .c-facets__body {
            height: auto !important;
        }
    }

    @include mq($from: tablet) {
        margin-bottom: 0;
        display: block;
    }

    .c-facets__close-button {
        float: right;

        @include mq($from: tablet) {
            display: none;
        }
    }

    .c-icon--black-close-icon {
        @include rem(width, 20px);
        @include rem(height, 20px);
    }
}

.c-facets__block {
    @include rem(margin, 10px 0);
    @include rem(padding-bottom, 10px);

    position: relative;
    border-bottom: 1px solid $color-ui-grey-2;

    & + .c-facets__block {
        margin-top: 0;
    }

    ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    @include mq($from: tablet) {
        margin-top: 0;
        border-bottom: none;
        padding-bottom: 0;
    }

    &::after {
        position: absolute;
        content: '';
        width: 14px;
        height: 10px;
        background-position: center;

        @include rem(top, 12px);
        @include rem(right, 10px);

        @extend .c-icon--black-down-arrow-icon;
    }

    &.active {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.c-facets__title {
    @include rem(margin-bottom, 7px);
    @include rem(font-size, 17px);

    line-height: inherit;
    padding: 0;
    border-bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $color-black;
}

.c-facets__link {
    text-decoration: none;
    font-family: $font-family-secondary;
    @include rem(font-size, 14px);
    @include link-color($color-ui-grey-2, $color-brand-secondary);

    span.c-icon--chevron-right {
        @include rem(margin-right, 5px);
    }
}

.c-facets__count {
    color: $color-ui-grey-2;
}

.c-facet {
    .count {
        @include rem(margin-right, 5px);

        margin-left: 0;
    }
}

.c-facets__body .items .item a {
    text-decoration: none;

    .c-checkbox-wrap.c-input-wrap {
        @include rem(margin-bottom, 5px);

        .c-checkbox-option__label {
            margin-right: 0;
        }
    }
}

.c-facets__group {
    @include mq($until: tablet) {
        @include rem(padding, 0px 17.6px);
    }

    .filter-current {
        h3 {
            padding: 0;
            font-size: $font-size-delta;
        }

        ol {
            padding-left: 0;
            margin-left: 0;
            list-style: none;

            li {
                a {
                    display: flex;
                    font-size: inherit;
                    transform: none;

                    @include hocus() {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .c-facet__filter-actions {
        @include rem(padding, 15px 0);

        text-align: right;
        margin-bottom: 0;

        .c-link--clear-all {
            @include rem(padding, 4px 5px);

            text-decoration: none;
            background-color: $color-remove-current-filter-bg;
            color: $color-remove-current-filter;
            border-radius: 4px;

            &:hover {
                background-color: lighten($color-remove-current-filter-bg, 10%);
            }
        }
    }
}

.c-facet__toggle {
    width: 100%;
}
