//------------------------------------
// #GALLERY
//------------------------------------

.s-gallery {
    .fotorama__thumb {
        background-color: transparent;
    }

    .fotorama__thumb-border {
        border-color: $color-gallery-thumbnail-border;
        border-color: var(--color-gallery-thumbnail-border);
        background-image: none;
    }

    .fotorama__active .fotorama__dot {
        background-color: $color-gallery-dot;
        background-color: var(--color-gallery-dot);
        border-color: $color-gallery-dot;
        border-color: var(--color-gallery-dot);
    }

    // Visually hide PDP image caption by default
    .fotorama__caption__wrap {
        @include visually-hidden();
    }
}
