//------------------------------------
// #INFO BLOCK
//------------------------------------

.c-info-block {
    @include rem(margin-bottom, 37px);
}

// Info block header
.c-info-block__header {
    @include rem(padding-bottom, 5px);
    @include rem(margin-bottom, 20px);
    @include mobile-gutter();

    border-bottom: solid 1px $color-ui-grey-3;
    border-bottom: solid 1px var(--color-ui-grey-3);

    h3 {
        display: inline;
        font-weight: 400;
    }

    .c-info-block__action {
        float: right;
        font-size: $font-size-zeta;
        color: $color-brand-primary;
        color: var(--color-brand-primary);
    }
}

// Info block content
.c-info-block__content {
    @include mobile-gutter();

    dl {
        margin-bottom: 0;
        display: inline-block;
    }

    dd {
        @include rem(margin-bottom, 10px);

        margin-left: 0;
    }

    h4 {
        @include rem(margin-bottom, 10px);
    }

    .c-actions__toolbar {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-info-item {
    @include rem(margin-bottom, 10px);
}

.c-info-item__actions {
    .c-info-item__action {
        &:not(:first-child) {
            @include rem(margin-left, 20px);
        }
    }
}

.c-info-item__action {
    @include rem(padding, 5px 0);

    display: inline-block;
    font-size: $font-size-zeta;
    color: $color-brand-primary;
    color: var(--color-brand-primary);

    &--style-a {
        @include rem(margin, 5px 0);
    }
}

.c-info-item__copy {
    @include rem(margin-bottom, 5px);

    line-height: 20px;
    font-size: $base-font-size;
}

.c-info-item__address {
    margin-bottom: 0;
    line-height: 20px;
    font-size: $base-font-size;

    &::first-line,
    a {
        line-height: 35px;
    }
}

// Info block table
.c-info-block__table {
    width: 100%;
    margin-bottom: 0;

    .price {
        font-weight: 400;
    }

    @include mq($until: 'tablet') {
        margin-bottom: 0;

        td {
            display: flex;

            &::before {
                content: attr(data-th);
                flex: 0 0 35%;
                font-weight: 700;
            }

            &:last-child {
                &::before {
                    content: none;
                }
            }
        }

        thead {
            display: none;
        }

        tr {
            @include rem(margin-bottom, 15px);

            display: block;
            border-bottom: solid 1px $color-ui-grey-3;
            border-bottom: solid 1px var(--color-ui-grey-3);

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }

    td {
        @include mq($from: 'tablet') {
            @include rem(padding, 10px 0);
        }
    }

    thead {
        .c-info-item__actions {
            @include mq($from: 'tablet') {
                display: none;
            }
        }
    }

    .c-info-item__actions {
        @include rem(margin-top, 5px);
        @include rem(margin-bottom, 10px);

        .c-info-item__action {
            @include rem(margin-left, 10px);
            @include rem(margin-right, 10px);

            &:first-child {
                margin-left: 0;

                @include mq($from: 'tablet') {
                    margin-right: 0;
                }

                &::after {
                    @include mq($from: 'tablet') {
                        @include rem(margin-left, 10px);

                        content: "";
                        width: 2px;
                        height: 10px;
                        display: inline-block;
                        background-color: $color-ui-grey-3;
                        background-color: var(--color-ui-grey-3);
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
