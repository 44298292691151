//------------------------------------
// #AKM2 PDP
//------------------------------------

.c-akm2-pdp {
    @include rem(margin-bottom, 20px);

    // .c-akm2-pdp__item
    &__item {
        @include rem(margin-bottom, 20px);

        display: flex;

        &:last-child {
            @include rem(margin-bottom, 5px);
        }
    }

    // .c-akm2-pdp__manuals
    // .c-akm2-pdp__charts
    &__manuals,
    &__charts {
        @include rem(padding, 15px 20px);

        background-color: $color-off-white;

        // .c-akm2-pdp__manuals-title
        &-title {
            @include rem(margin-bottom, 15px);
        }
    }

    // .c-akm2-pdp__image
    &__image {
        @include rem(width, 55px);
        @include rem(min-width, 55px); // Ensure flexbox keeps the image the same size when shrinking
        @include rem(margin-right, 10px);

        @include mq($from: tablet) {
            @include rem(width, 95px);
            @include rem(min-width, 95px);
        }

        img {
            display: block;
            width: 100%;
        }

        a {
            border: 2px solid $color-brand-secondary;
            display: block;

            @include hocus() {
                border-color: $color-black;
            }
        }
    }

    // .c-akm2-pdp__content
    &__content {
        width: 100%;

        a {
            @include link-color($color-black);

            text-decoration: none;
            display: flex;
            justify-content: space-between;

            @include mq($from: tablet) {
                justify-content: normal;
            }
        }

        // .c-akm2-pdp__content-title
        &-title {
            @include font-size(14px, 1.3);
            @include rem(margin-bottom, 2px);
        }

        // .c-akm2-pdp__content-chapter
        &-chapter p,
        // .c-akm2-pdp__content-catalogue
        &-catalogue p {
            @include font-size(12px, 1.5);

            margin-bottom: 0;
        }

        .c-icon {
            @include rem(height, 9px);
            @include rem(margin-left, 10px);
        }
    }
}
