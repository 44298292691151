//------------------------------------
// #QUANTITY EXTEND
//------------------------------------

.c-qty__form {
    .fieldset {
        @include mq($until: tablet) {
            display: flex;
            align-items: baseline;
        }
    }

    .c-input-wrap {
        @include mq($from: tablet) {
            display: flex;
            align-items: center;
        }
    }
}

.c-qty__recommended {
    @include font-size(12px);

    font-family: $font-family-secondary;
    padding: 5px 0;

    @include mq($from: tablet) {
        padding: 0;
    }
}

// Deeper selector to override
.c-input-wrap {
    .c-input-qty__label,
    .c-input__qty {
        @include rem(margin-right, 10px);
    }

    .c-input-qty__label {
        font-family: $font-family-default;
        display: inline-block;
    }
}

.c-input__qty {
    width: 40px !important;
    font-weight: bold;
    display: inline-block;
}

.c-tocart__actions {
    display: flex;
    align-items: center;
}

