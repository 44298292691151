//------------------------------------
// #COLORS
//------------------------------------

// Attach our brand colour palette to a series of helper classes.

.u-color-white-a,
.u-color-white-a\:hover:hover {
    color: $color-white !important;
    color: var(--color-white) !important;
}

.u-color-black-a,
.u-color-black-a\:hover:hover {
    color: $color-black !important;
    color: var(--color-black) !important;
}

.u-color-grey-a,
.u-color-grey-a\:hover:hover {
    color: $color-ui-grey-3 !important;
    color: var(--color-ui-grey-3) !important;
}
