//------------------------------------
// #BORDER BOX
//------------------------------------

.c-border-box {
    @include clearfix();
    @include rem(padding, 15px);

    background: $color-white;
    background: var(--color-white);
    border: solid 2px $color-ui-grey-4;
    border: solid 2px var(--color-ui-grey-4);
}

.c-border-box--spaced {
    @include rem(margin-bottom, $base-layout-gutter);
}
