//------------------------------------
// #MODAL
//------------------------------------

.modal-popup {
    .modal-inner-wrap {
        @include mq($from: tablet) {
            top: 10%;
            bottom: 10%;
        }

        .modal-header {
            @include rem(padding-top, 18px);

            padding-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end; // Align close to right if no title

            &.has-title {
                justify-content: space-between;
            }

            .modal-title {
                @include font-size(18px);

                padding-top: 0;
                white-space: nowrap;

                @include mq($from: tablet) {
                    @include font-size(26px);
                }
            }

            .action-close {
                padding: 0;
                position: relative;

                &::before {
                    font-size: 30px;
                }

                @include mq($from: tablet) {
                    &::before {
                        font-size: 50px;
                    }
                }

                .c-icon {
                    @include rem(width, 25px);
                    @include rem(height, 25px);
                }
            }

            @include mq($from: tablet) {
                @include rem(padding-top, 25px);
            }
        }
    }

    .modal-content {
        @extend .u-padding-top;
    }
}

.modal-footer {
    display: flex;

    .c-btn {
        flex-grow: 1;
        @include rem(margin-left, 15px);

        &:first-of-type {
            margin-left: 0;
        }
    }

    @include mq($until: tablet) {
        flex-wrap: wrap;

        .c-btn {
            @include rem(margin-top, 12px);

            margin-left: 0;
        }
    }
}

.modal-popup._inner-scroll.c-modal-popup--limited-stock {
    .modal-content {
        ul {
            @include rem(margin-bottom, 12px);

            margin-left: 0;
            list-style: none;

            li {
                color: $color-black;
                font-weight: bold;
                display: flex;
                align-items: center;

                span:first-of-type {
                    @include rem(margin-right, 30px);
                }
            }
        }

        p {
            @include rem(margin-bottom, 12px);
        }

        h3 {
            @include rem(margin-bottom, 10px);
            color: $color-black;
            font-weight: bold;
            @include font-size(16px)
        }

        .c-btn {
            @include rem(margin-top, 24px);
        }

        input {
            @include rem(margin-right, 5px);
            @include rem(margin-bottom, 18px);
        }
    }
}

.c-modal.c-modal--confirm {
    @include mq($from: tablet) {
        .modal-inner-wrap {
            top: 50%;
            bottom: auto;
        }

        &._show .modal-inner-wrap {
            transform: translateY(-50%);
        }
    }
}

// Ensure the styling will never apply to desktop or tablet
@include mq($until: tablet) {
    // Ensure Safari body does not scroll/menu does not show or hide
    .mobile-modal-active {
        -webkit-overflow-scrolling: touch !important;
        //the hidden overflow + 100% height resets the body scroll position. To be tested on all devices
        overflow: auto !important;
        height: 100% !important;
    }

    .c-modal.modal-popup {
        .modal-inner-wrap {
            margin: 0;
            max-width: none;
            top: 0;
            height: 100%;
            width: 100%;
        }

        &._inner-scroll {
            // Deeper selector to override defaults
            .modal-title {
                white-space: normal;
            }

            .modal-inner-wrap {
                max-height: none;
            }

            .modal-content {
                overflow-y: scroll;
            }
        }
    }
}
