//------------------------------------
// #STOCK NOTIFICATION FORM
//------------------------------------

.s-form__stock-notification {
    @include rem(margin-top, 10px);

    @include mq($from: desktop) {
        display: flex;
        align-items: center;
    }

    .c-input-wrap {
        @include rem(margin-bottom, 15px);

        @include mq($from: desktop) {
            flex-grow: 1;
            margin-bottom: 0;
        }
    }

    .c-btn--submit {
        @include rem(margin-bottom, 15px);

        min-width: inherit;

        @include mq($from: desktop) {
            @include rem(margin-right, 40px);

            margin-bottom: 0;
        }
    }

    .c-wishlist__link {
        @include font-size(14px);

        .c-wishlist__icon {
            width: 18px;
            height: 18px;
        }
    }

    .c-form__control {
        margin-bottom: 0;
    }
}

.c-form__stock-notification-label {
    margin-bottom: 0;
}

.s-form__stock-notification__submit-message {
    @include rem(margin-top, 10px);
    @include rem(padding, 5px 10px);

    color: $color-white;
    font-weight: bold;

    &.u-color-alert {
        background-color: $color-input-validation-error;
    }

    &.u-color-success {
        background-color: $color-input-validation-valid;
    }
}
