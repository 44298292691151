//------------------------------------
// #QUICKVIEW CAROUSEL
//------------------------------------

.c-quickview-carousel__modal.modal-popup  {
    .modal-footer {
        // Reduce footer padding to save screen estate
        padding-top: 0;
    }
}

// List
.c-quickview-carousel__wrapper,
.c-quickview-carousel__controls-inner,
.c-quickview-carousel__item {
    height: 100%;
}

.c-quickview-carousel__wrapper {
    display: flex;
    flex-direction: column;
}

.c-quickview-carousel__list {
    height: 100%;
    float: left;
    overflow: hidden;

    transition: height $transition-quickview-carousel-animation;
    will-change: height;

    &.next {
        .c-quickview-carousel__item {
            animation: quickview-carousel-animate-next $transition-quickview-carousel-animation;
        }
    }

    &.previous {
        .c-quickview-carousel__item {
            animation: quickview-carousel-animate-previous $transition-quickview-carousel-animation;
        }
    }
}

// Item
.c-quickview-carousel__item {
    float: left;
    width: 100%;
    animation-iteration-count: 1;
    will-change: opacity, transform;
}

// Dots
.c-quickview-carousel__dots {
    @include rem(padding, 20px);

    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $color-white;

    .active {
        .c-quickview-carousel__dot {
            background-color: $color-quickview-carousel-dot-active;
        }
    }
}

.c-quickview-carousel__dot {
    @include font-size(12px, 1.4);
    @include rem(margin, 0 3px);
    @include rem(height, 12px);
    @include rem(width, 12px);

    transition: background-color $transition-quickview-carousel-base;
    display: inline-block;
    position: relative;
    font-family: $font-family-secondary;
    font-style: normal;
    float: left;
    border-radius: 50%;
    background-color: $color-mono-c;
}

// Controls
.c-quickview-carousel__controls {
    @include rem(height, 55px);

    width: 100%;
    background-color: $color-white;
    border-top: 2px solid $color-mono-c;
}

.c-quickview-carousel__controls__text {
    @include font-weight(bold);

    text-align: center;
    color: $color-black;
}

.c-quickview-carousel__controls__left-arrow,
.c-quickview-carousel__controls__right-arrow {
    a {
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        visibility: hidden;

        transition: visibility $transition-quickview-carousel-base, opacity $transition-quickview-carousel-base;
    }

    .active {
        opacity: 1;
        visibility: visible;
    }
}

.c-quickview-carousel__controls__left-arrow {
    @include mq($from: tablet) {
        @include rem(padding-left, 30px);
    }

    .c-icon {
        transform: rotate(180deg);
    }
}

.c-quickview-carousel__controls__right-arrow {
    @include mq($from: tablet) {
        @include rem(padding-right, 30px);
    }

    text-align: right;
}

// Animations
@keyframes quickview-carousel-animate-next {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes quickview-carousel-animate-previous {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
