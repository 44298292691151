//------------------------------------
// #HEADER LINKS EXTEND
//------------------------------------

.s-header-links {
    @include rem(padding-top, 5px);
    @include rem(padding-bottom, 5px);

    ul {
        margin: 0;
        font-weight: bold;

        & > li > a {
            font-family: $font-family-secondary;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .wishlist {
        display: none;
    }
}
