//------------------------------------
// #VIDEOS
//------------------------------------

// Basic video styles

video {
    height: auto;
    max-width: 100%;
}
