//------------------------------------
// #REACT LISTINGS LOADING
//------------------------------------

.c-listings__loading {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.c-listings__loading--loading {
    display: block;
    background-color: scale-color($color-plp-loader-bg, $alpha: -80%);
}
