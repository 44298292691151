//------------------------------------
// #VIEW MODES
//------------------------------------

.c-plp__view-modes {
    @include rem(height, 40px);

    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.c-plp__view-mode {
    @include rem(margin-left, spacer(1 / 3));

    border: 0;
    padding: 0;
    background-color: transparent;
    opacity: .4;

    &.active {
        opacity: 1;
        cursor: not-allowed;
    }

    &--mobile.active {
        cursor: pointer;
    }
}
