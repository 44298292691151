//------------------------------------
// #POST GRID
//------------------------------------

$post-grid-border: 1px solid $color-brand-secondary;

// Wrap in template retriever class to override WordPress base theme
.c-template-retriever {
    @include mq($until: desktop) {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 30px;
    }

    .c-post-grid__item {
        border: $post-grid-border;
    }

    .c-post-grid__title {
        a {
            @include link-color($color-ui-grey-2, $color-brand-secondary);
        }
    }

    .c-post-grid__meta,
    .c-post-grid__footer {
        background-color: $color-brand-secondary;
    }

    .c-post-grid__item--has-thumbnail {
        border: 0;

        .c-post-grid__title,
        .c-post-grid__meta,
        .c-post-grid__content,
        .c-post-grid__footer {
            border-right: $post-grid-border;
            border-left: $post-grid-border;
        }

        .c-post-grid__footer {
            border-bottom: $post-grid-border;
        }
    }
}
