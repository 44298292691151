//------------------------------------
// #CHECKOUT
//------------------------------------

.s-checkout {
    // Used for the "add new address" button on checkout
    .c-btn--radio {
        @include font-size(14px);

        background-color: $color-radio-background;
        color: $color-radio-active-background;
        min-width: unset;
        min-height: 110px;
        padding: 0;
        height: 100%;
        width: 100%;

        > span {
            @include rem(padding, 8px 30px);

            border: 2px solid $color-radio-active-background;

            @include mq($from: tablet) {
                @include rem(padding, 4px 20px);

                border-width: 1px;
            }
        }

        &:hover,
        &:focus {
            > span {
                background-color: $color-radio-active-background;
                color: $color-radio-background;
            }
        }
    }

    .c-price__shipping {
        @include font-size(14px);
        @include font-weight(normal);

        display: inline-block;
    }
}

.s-checkout.checkout-container {
    @include mq($until: tablet) {
        @include rem(padding, 0px 17.6px);
    }

    .c-checkout__delivery-options {
            .c-checkout__sidebar__items {
                display: none;
            }

        .c-checkout__sidebar__text {
            display: block;
            text-align: right;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .c-checkout__sidebar {
            background-color: transparent;
            transform: translateY(-75px);

            &__title {
                display: none;
            }

            &__totals {
                .grand.totals.excl {
                    display: none;
                }

                .totals-tax {
                    display: none;
                }

                .totals {
                    color: $color-black;

                    .amount .price {
                        color: $color-black;
                    }
                }
            }
        }
    }
    .c-checkout__sidebar__items {
        display: none;
    }
}

// Hide the checkout sidebar when the checkout-payment class is applied to the body
body.checkout-payment .s-checkout.checkout-container .c-checkout__sidebar {
    display: none;
}

.checkout-index-index,
.negotiable-quote-checkout-quote-onepage {
    .c-footer .o-region__inner {
        @include rem(padding-top, 20px);
    }

    .footer__payment-social {
        @include mq($from: tablet) {
            flex-direction: column;
        }
    }

    .footer__payment-social,
    .c-footer__copyright {
        border-top: 0;
        padding-top: 0;
    }
}
