//------------------------------------
// #FOOTER EXTEND
//------------------------------------

.c-footer-static__wrapper .c-btn--link {
    min-width: 0; // [1]
}

.c-footer {
    padding-top: 0;
    background-color: $color-blue;

    .o-region__inner { // [2]
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include mq($until: tablet) {
            width: 100%;
        }

        @include mq($from: tablet) {
            flex-direction: row;
            @include rem(padding-top, 40px);
        }
    }

    .o-layout__item {
        @include mq($until: tablet) {
            padding-left: 0;
        }
    }

    .footer__section {
        @include mq($until: tablet) { // [3]
            @include rem(margin-bottom, 10px);
            border-bottom: 1px solid $color-ui-grey-2;
            @include rem(padding-bottom, 10px);
        }
    }

    .c-footer__catalogue {
        margin: 10px 0;
        text-align: left;
        color: $color-off-white;
    }

    .s-contact-details {
        @include mq($until: tablet) {
            @include font-size(14px);
        }
    }

    .c-footer__links .o-list-inline__item {
        margin-left: 0;
    }

    .c-footer__links a {
        color: $color-off-white;
        text-decoration: none;
    }

    .c-footer__links-bold {
        @include font-weight(bold);

        color: $color-off-white;

        @include mq($until: tablet) {
            display: none;
        }
    }

    .c-accordion {
        order: 1; // [4]
    }

    .c-accordion,
    .c-accordion__content:not(:last-of-type) { // [5]
        @include mq($from: tablet) {
            padding-left: 0;
        }
    }

    .c-accordion__header .u-heading-epsilon {
        margin-bottom: 0;
        color: $color-off-white;
    }

    .ui-accordion-header-active .c-icon--secondary-down-arrow-icon {
        transform: rotate(-180deg);
    }

    .c-footer__social-links-wrapper {
        display: block;

        @include mq($from: tablet) {
            display: flex;
            align-items: center;
        }
    }

    .c-footer__social-text {
        @include mq($from: tablet) {
            @include font-size(18px);
            @include rem(margin-right, 10px);

            vertical-align: top;
            color: $color-off-white;
        }
    }

    .c-footer__social-link {
        text-decoration: none;

        @include mq($from: tablet) {
            @include rem(margin-right, 10px);

            line-height: 1;
        }
    }
}

.s-footer-newsletter {
    order: 2; // [4]
}

.footer__empty { // [6]
    display: none;

    @include mq($from: desktop) {
        display: block;
        order: 3;
    }
}

.footer__catalog-social {
    order: 4; // [4]

    .c-footer__social-links-wrapper {
        @include mq($until: tablet) {
            display: none; // [7]
        }
    }
}

.footer__payment-social {
    display: flex;
    order: 5; // [4]
    justify-content: space-between;

    @include mq($from: tablet) {
        order: 6; // [4]
        justify-content: flex-start;
        text-align: right;
    }

    .c-footer__social-links-wrapper {
        @include rem(margin-left, 20px);

        @include mq($from: tablet) {
            display: none; // [7]
        }
    }
}

.footer__payment-social {
    @include mq($from: tablet) {
        @include rem(margin-top, 20px);
        border-top: 1px solid $color-ui-grey-2;
        @include rem(padding-top, 20px);
    }
}

.footer__section-label {
    @include mq($until: tablet) {
        display: block;
        @include rem(margin-bottom, 5px);
    }
}

.footer__links--checkout {
    text-align: right;

    @include mq($until: tablet) {
        @include font-size(12px);
        align-self: flex-end;
        @include rem(margin-bottom, 12px);
        @include rem(padding-left, 10px);
    }

    @include mq($from: tablet) {
        @include rem(margin-top, 27px); // [8]
    }

    .o-list-inline__item {
        &::after {
            color: $color-off-white;
        }

        &:first-of-type::after { // [9]
            content: "|";
            @include rem(padding-left, 5px);
        }

        &:not(:last-of-type)::after {
            @include mq($from: tablet) { // [9]
                content: "|";
                @include rem(padding-right, 5px);
                @include rem(padding-left, 10px);
            }
        }

        &:last-of-type {
            @include mq($until: tablet) {
                display: none;
            }
        }
    }
}
