//------------------------------------
// #MIXINS EXTEND
//------------------------------------

// A mixin to help create content to fill up a whole block area, e.g. the 'Shop by Category'
// card links.
@mixin block-content() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
}
