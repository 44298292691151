//------------------------------------
// #PRODUCT SOCIAL LINKS
//------------------------------------

.c-product-social-links {
    @include rem(margin-bottom, 15px);

    display: flex;
    justify-content: space-around;

    @include mq($from: tablet) {
        > .c-product-social-link {
            flex: 1 1 0;
        }
    }
}

.c-product-social-link {
    @include font-size($font-size-zeta);

    display: flex;
    align-items: center;

    &::before {
        @include rem(margin-right, 5px);
        @include rem(width, 22px);
        @include rem(height, 22px);
        @include rem(background-size, 22px);

        content: ' ';
        display: inline-block;
    }

    &--compare {
        &::before {
            background-image: url('../images/icons/svg/compare.svg');
        }

        @include mq($until: desktop) {
            display: none;
        }
    }

    &--email {
        &::before {
            background-image: url('../images/icons/svg/email.svg');
        }

        @include mq($from: desktop) {
            display: none;
        }
    }

    &--wishlist {
        &::before {
            background-image: url('../images/icons/svg/heart-outline.svg');
        }
    }
}
