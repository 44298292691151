//------------------------------------
// #LIST
//------------------------------------

.o-list {
    li,
    .o-list__item {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);

        &.o-list__item--highlighted {
            color: $color-brand-primary;
            color: var(--color-brand-primary);
            font-weight: bold;
        }
    }
}
