//------------------------------------
// #USP BAR
//------------------------------------

.c-usp-bar {
    @include rem(padding, 12px 60px);
}

.c-usp-bar__item {
    @include rem(padding-left, 38px);

    height: 30px;
    line-height: 30px;
    position: relative;

    .c-icon {
        position: absolute;
        left: 0;
    }
}
