//------------------------------------
// #DRAWER
//------------------------------------

.c-drawer {
    @include mq($until: desktop) {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        margin-top: 0;
        transform: translateX(-100%);

        * {
            white-space: nowrap; // Prevent wrapping when animating open.
        }
    }

    // State overrides
    &.is-open {
        width: 100%;
        transform: translateX(0);
    }
}

.c-drawer__inner {
    @include mq($until: desktop) {
        display: block;
        background-color: $color-white;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include rem(padding-bottom, 20px);
        @include rem(padding-top, 20px);
        overflow: auto;

        transform: translateX(-100%);
        transition: transform ease-in-out 0.5s;
    }

    .is-open & {
        transform: translateX(0%);
    }
}

.c-drawer__head {
    @include rem(margin-bottom, 15px);

    a {
        color: $color-black;
        @include font-weight('bold');
    }

    ul > li + li {
        @include rem(margin-left, 5px);
    }

    .c-vat-switcher {
        margin: 15px 15px 0;
    }
}

.c-drawer__toggle {
    height: 40px;
    width: 100%;
    font-size: 28px;
    line-height: 1;
    border-right: 1px solid $color-header-border;

    .c-icon--menu-icon-mobile {
        width: 20px;
        height: 17px;
    }
}

.c-drawer__close {
    padding: 0;
    background: transparent;
    border: 0;
    font-family: $font-family-default;
}

.c-drawer__links {
    > ul {
        @include rem(margin, 0 15px);

        display: flex;
        flex-direction: column-reverse;
        list-style: none;

        > li > a {
            @include rem(padding, 12px 8px);
            @include font-weight(bold);

            display: block;
            color: $color-black;
            text-decoration: none;

            &:hover,
            &:focus {
                background-color: $color-mono-b;
            }
        }
    }

    .c-header-dropdown {
        display: none;
    }
}
