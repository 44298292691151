//------------------------------------
// #TABS EXTEND
//------------------------------------

.c-tabs {
    width: 100%;
    margin-left: auto;

    .c-tabs__wrap {
        @include rem(margin-top, 10px);

        width: 100%;
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    .c-tabs__tab {
        flex: 1;
        @include rem(padding-left, 5px);
        overflow: hidden;

        > div {
            // Ensure the first container fills the entire height of the tab
            height: 100%;
        }

        @include mq($from: tablet) {
            @include rem(padding-left, 25px);
        }

        &:first-child {
            padding-left: 0;
        }
    }

    .c-tabs__tab-content {
        @include rem(padding, 30px 0 20px);

        display: none;
        text-align: left;
        order: 1;
        width: 100%;

        @include mq($from: tablet) {
            @include rem(padding, 50px 0 20px);
        }

        .u-heading-delta {
            @include font-weight(bold);

            color: $color-black;
        }
    }

    .c-tabs__tab.selected-item + .c-tabs__tab-content {
        display: block;
    }

    .c-tabs__wrap--single .c-tabs__tab.selected-item {
        flex-basis: 100%;
    }
}
