//------------------------------------
// #WISHLIST SIDEBAR
//------------------------------------

.c-wishlist {
    @include rem(margin-bottom, 30px);
}

.c-wishlist__title {
    @include rem(margin-bottom, 10px);
}

.c-wishlist__count {
    @include font-size($font-size-zeta);
    @include rem(margin-left, 10px);

    display: inline-block;
}
