//------------------------------------
// #GRID
//------------------------------------

@if not(mixin-exists(mq)) {
    @error "Grid depends on the Sass MQ library";
}

// Default columns for various breakpoints
$grid-default-columns: (
    mobile: 3,
    tablet: 4,
    desktop: 5,
    wide: 6
) !default;

$grid-fallback-column-count: 6;
$grid-column-count:          12 !default;
$grid-gutter:                18px !default;

// Usage:
// 1. Add .o-grid class to container
// 2. Add .o-grid__item class to child elements
//
// By default the grid will collapse to the column widths defined in $grid-default-columns at the appropriate
// breakpoint. You can override this behaviour by applying a modifier class to the container specifying the column count
// and breakpoint, eg:
//
// .o-grid--4-col@desktop

// Calculate grid column width
@function calculate-grid-column-width($column-count, $gutter: $grid-gutter) {
    @return if(
            $column-count == 1,
            100%,
            calc((100% / #{$column-count}) - (#{rem($gutter)} - (#{rem($gutter)} / #{$column-count})))
    );
}

// Get default column count
@function get-default-column-count($breakpoint, $defaults: $grid-default-columns) {
    @return if(map-has-key($defaults, $breakpoint), map-get($defaults, $breakpoint), $grid-fallback-column-count);
}

// Mixin which generates grid item classes based on column count
@mixin get-grid-calculated-classes($count, $gutter: $grid-gutter) {
    .o-grid__item {
        @include rem(margin, 0 $gutter $gutter 0);

        width: calculate-grid-column-width($count);
    }

    // Reset all lesser nth child items
    $iterative-column-count: $count;
    @while $iterative-column-count > 0 {
        .o-grid__item:nth-child(#{$iterative-column-count}n) {
            @include rem(margin-right, $gutter);
        }

        $iterative-column-count: $iterative-column-count - 1;
    }

    .o-grid__item:nth-child(#{$count}n) {
        margin-right: 0;
    }
}

.o-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    .o-grid__item {
        @include rem(margin-bottom, $grid-gutter);
    }

    // Add default columns for breakpoints
    @each $breakpoint, $width in $mq-breakpoints {
        @include mq($from: $breakpoint) {
            @include get-grid-calculated-classes(get-default-column-count($breakpoint));
        }
    }
}

// Add modifier classes for breakpoints. This functionality lives outside .o-grid so the deeper selector mitigates
// the need for !important
@each $breakpoint, $count in $grid-default-columns {
    $iterative-column-count: $grid-column-count;

    // Iterate entire grid count so we have modifier classes for all column widths
    @while $iterative-column-count > 0 {
        @include mq($from: $breakpoint) {
            .o-grid.o-grid--#{$iterative-column-count}-col\@#{$breakpoint},
            .o-grid.o-grid--#{$iterative-column-count}-col--from-#{$breakpoint} {
                @include get-grid-calculated-classes($iterative-column-count);
            }
        }

        $iterative-column-count: $iterative-column-count - 1;
    }
}
