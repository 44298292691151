//------------------------------------
// #BASKET PRODUCT
//------------------------------------

.c-basket-product {
    @include rem(padding, 15px);

    border-bottom: 1px solid $color-ui-grey-2;
    border-bottom: 1px solid var(--color-ui-grey-2);

    @include mq($from: tablet) {
        @include rem(padding, 20px 0);
    }

    &:first-child {
        border-top: 1px solid $color-ui-grey-2;
        border-top: 1px solid var(--color-ui-grey-2);
    }

    .c-product__img {
        margin-bottom: 0;
    }
}

.c-basket-product__details {
    display: flex;
}

.c-basket-product__img {
    @include rem(margin-right, 15px);

    flex-shrink: 0;

    img {
        @include rem(width, 100px);

        display: block;
    }
}

.c-basket-product__info-wrapper {
    width: 100%;
}

.c-basket-product__addto {
    @include rem(margin-top, 5px);

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c-basket-product__title {
    @include font-size($base-font-size);

    font-weight: normal;

    &,
    a {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
    }

    @include mq($from: desktop) {
        @include font-size($font-size-delta);
    }
}

.c-basket-product__options {
    @include font-size($font-size-zeta);
    @include rem(margin-bottom, 15px);

    .c-basket-product__option-value {
        color: $color-ui-grey-2;
        color: var(--color-ui-grey-2);
    }
}

.c-basket-product__actions {
    @include font-size($font-size-zeta);

    a {
        display: inline-block;
    }

    a + a {
        @include rem(margin-left, 10px);
    }

    a,
    a:visited {
        color: $color-brand-primary;
        color: var(--color-brand-primary);
    }
}

.c-basket-product__price {
    @include font-size($font-size-zeta);

    color: $color-brand-primary;
    color: var(--color-brand-primary);
}

.c-basket-product__price,
.c-basket-product__subtotal {
    @include mq($from: desktop) {
        @include font-size($font-size-delta);
    }
}

.c-basket-product__qty {
    .c-form__group--qty {
        margin-bottom: 0;
    }
}

.c-basket-product__subtotal {
    text-align: right;
    color: $color-brand-primary;
    color: var(--color-brand-primary);
    font-weight: bold;

    @include mq($from: desktop) {
        color: $color-ui-grey-1;
        color: var(--color-ui-grey-1);
        font-weight: normal;
    }
}
