//------------------------------------
// #QUICK ORDER
//------------------------------------

// Deeper selector to override defaults
.c-quick-order {
    .c-quick-order__product,
    .c-quick-order__th,
    .c-quick-order__label {
        @include rem(font-size, $font-size-delta);

        font-weight: bold;
        color: $color-black;
        font-family: $font-family-default;
    }

    .c-quick-order__upload-inner,
    .c-btn {
        width: 100%;

        @include mq($from: tablet) {
            width: auto;
        }
    }
}

.c-quick-order__search-input {
    @include rem(margin-bottom, 20px);
    border: 0;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq($from: tablet) {
        @include rem(margin-bottom, spacer(0.5));
    }
}

.c-quick-order__multiple,
.c-quick-order__upload {
    float: left;
}

.c-quick-order__upload {
    @include mq($from: tablet) {
        @include rem(padding-left, $spacing-unit);
    }
}

.c-quick-order__items {
    @include rem(margin-bottom, 30px);

    table-layout: fixed;
    position: relative;

    @include mq($from: tablet) {
        margin-top: 0;
    }

    th,
    td {
        padding: 0;

        @include mq($from: tablet) {
            @include rem(padding, 5px 10px);

            &:not(.c-messages__message) {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    thead {
        display: none;

        @include mq($from: tablet) {
            display: table-header-group;
        }
    }

    tr {
        @include rem(margin-bottom, 20px);

        display: block;
        float: left;
        width: 100%;

        @include mq($from: tablet) {
            margin-bottom: 0;
            display: table-row;
            float: none;
            width: auto;
        }
    }

    th {
        border-bottom: 1px solid $color-off-white;
    }

    td {
        display: block;
        float: left;

        @include mq($from: tablet) {
            vertical-align: middle;
            display: table-cell;
            float: none;
        }
    }

    .c-quick-order__row {
        animation: table-animate linear $base-transition;
        animation-iteration-count: 1;
        will-change: opacity, transform;
    }

    .c-quick-order__product {
        @include rem(font-size, $font-size-epsilon);
        @include rem(padding-right, 20px);

        display: flex;
        align-items: flex-start;

        @include mq($from: tablet) {
            align-items: center;
            padding-right: 0;
        }

        &-image {
            @include rem(min-width, 75px);
            @include rem(min-height, 75px);
            @include rem(margin-right, 20px);
        }

        &-link {
            @include link-color($color-black, $color-black);

            text-decoration: none;
            line-height: 1.3;
        }
    }

    .c-quick-order__product-price {
        @include rem(font-size, $font-size-delta);

        &--mobile {
            @include font-size($font-size-epsilon, 1.3);

            @include mq($from: tablet) {
                display: none;
            }
        }

        &--desktop {
            @include mq($until: tablet) {
                display: none;
            }
        }
    }

    .c-quick-order__remove-row,
    .c-quick-order__product-qty {
        @include rem(margin-top, 10px);

        @include mq($from: tablet) {
            margin-top: 0;
        }
    }

    .c-quick-order__product-qty {
        .c-form__group {
            display: flex;
            align-items: center;

            @include mq($from: tablet) {
                display: block;
            }

            .label {
                @include rem(margin, 0 10px 0 0);

                @include mq($from: tablet) {
                    @include visually-hidden();
                }
            }

            .c-input__qty {
                margin-bottom: 0;
            }
        }
    }

    .c-quick-order__remove-row {
        @include rem(height, 40px);

        text-align: right;

        a {
            @include link-color($color-brand-secondary, $color-brand-secondary);

            line-height: (40 / 14);
        }

        @include mq($from: tablet) {
            height: auto;
            text-align: left;

            a {
                line-height: inherit;
            }
        }
    }

    .c-messages__message {
        @include rem(padding, 3px 15px);

        margin-bottom: 0;
    }

    .c-form__group {
        margin-bottom: 0;
    }
}

.c-quick-order__actions-toolbar {
    @include rem(margin, 40px 0);

    @include mq($from: tablet) {
        @include rem(margin-bottom, 24px);

        margin-top: 0;
    }
}

.c-quick-order__actions-toolbar--right {
    text-align: right;
}

.c-quick-order__upload {
    .label {
        margin-bottom: 0;
    }

    .label {
        & + .note {
            @include rem(margin-bottom, 5px);

            padding-top: 0;
        }
    }
}

.c-quick-order__search {
    .control {
        flex: 1;
        position: relative;

        @include mq($from: tablet) {
            @include rem(margin-right, 10px);
        }
    }

    .c-quick-order__search-text-input[type="text"] {
        margin-bottom: rem(10px);

        @include mq($from: tablet) {
            margin-bottom: 0;
        }
    }

    .c-input__qty {
        @include rem(width, 50px, true);
        text-align: center;
    }

    .ui-autocomplete {
        list-style-type: none;
        border: 1px solid $color-brand-secondary;
        border-top: 0;
    }

    .ui-menu-item {
        cursor: pointer;

        a {
            @include rem(padding, 10px);

            display: inline-block;
            width: 100%;

            &.ui-state-focus {
                @include link-color($color-white, $color-white);

                background-color: $color-brand-secondary;
            }
        }
    }

    .ui-helper-hidden-accessible {
        @include visually-hidden();
    }

    .ui-autocomplete-loading {
        opacity: .5;
    }
}

.c-quick-order__search-loading {
    @include rem(padding, 10px);
    @include rem(width, 40px);
    @include rem(height, 40px);

    display: none;
    top: 0;
    right: 0;
    position: absolute;
}

.ui-autocomplete-loading ~ .c-quick-order__search-loading {
    display: block;
}

.c-quick-order__upload-inner {
    display: inline-block;
    overflow: hidden;
    position: relative;

    .c-quick-order__upload-action {
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.c-quick-order__basket {
    @include rem(padding-top, 15px);
}

@keyframes table-animate {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.c-quick-order__remove-row {
    .action.remove.no-display {
        display: block !important;
    }
}
