//------------------------------------
// #MESSAGES
//------------------------------------

.c-messages__message,
.message {
    font-family: $font-family-secondary;
    border: solid 1px $color-black;
    border-radius: 4px;

    @include rem(padding, 15px);
    @include rem(margin-bottom, $base-spacing-unit);

    > :last-child {
        margin-bottom: 0;
    }

    p,
    .c-message__text {
        margin: 0;
        color: $color-white;

        @include font-weight(bold);
    }

    a {
        @include link-color($color-white, $color-white);

        text-decoration: underline;
    }
}

.c-messages__message--success,
.message-success {
    background-color: $color-alert-success;
    border-color: lighten($color-alert-success, 45%);
}

.c-messages__message--notice,
.c-messages__message--warning,
.message-warning,
.message-notice {
    background-color: $color-alert-warning;
    border-color: lighten($color-alert-warning, 25%);
}

.c-messages__message--error,
.c-messages__message--alert,
.message-error {
    background-color: $color-alert-error;
    border-color: lighten($color-alert-error, 40%);
}

// This extend is being left in as there are about 60+ usages of it in .phtml files alone
.messages {
    @extend .o-region__inner;
}
