//------------------------------------
// #SWITCH
//------------------------------------

.c-switch {
    display: flex;
    align-items: center;
    position: relative;

    &__toggle {
        position: absolute;
        left: 60px;
        width: 40px;
        height: 20px;
        background-color: $color-off-white;
        border-radius: 20px;

        &::before {
            content: ' ';
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $color-brand-secondary;
            transform: translateX(100%);
            transition: transform .1s ease-out;
        }
    }

    input {
        display: none;

        &:checked {
            ~ .c-switch__toggle::before {
                transform: translateX(0);
            }
            + label {
                opacity: 1;
            }
        }
    }

    label {
        position: relative;
        z-index: 1;
        cursor: pointer;
        opacity: .5;
        color: $color-ui-grey-2;
        @include font-size(13px);

        &:first-of-type {
            @include rem(padding-right, 30px);
        }

        &:last-of-type {
            @include rem(padding-left, 30px);
        }
    }
}
