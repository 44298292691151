//------------------------------------
// #VAT SWITCHER
//------------------------------------

.c-vat-switcher {
    display: flex;
    align-items: center;

    @include mq($until: tablet) {
        @include rem(margin, 0 15px);
    }
}
