//------------------------------------
// #PAGE
//------------------------------------

// Page-level styling (e.g. HTML and BODY elements).

html {
    font-size: ($base-font-size / 16px) * 1em;
    line-height: decimal-round($base-line-height / $base-font-size, 2);
    font-family: $font-family-default;
    background-color: $color-white;
    background-color: var(--color-white);
    color: $color-font;
    color: var(--color-font);
}
