//------------------------------------
// #PAGINATION EXTEND
//------------------------------------

.c-pagination {
    @include mq($until: tablet) {
        float: right;
        margin-bottom: 0;
    }
    // .c-pagination--mobile
    &--mobile {
        float: none;

        a {
            flex-basis: 100%;
        }
    }

    @include mq($until: tablet) {
        padding-left: 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    // .c-pagination__link
    &__link--show-next,
    &__link--show-previous {
        width: auto;

        @include link-color($color-pagination-link, $color-pagination-link-hover);
        @include font-size(12px);

        &:visited {
            color: $color-pagination-link;
        }
    }

    &__link--show-next {
        text-align: right;
    }

    // NP: Pseudo element created to house the background image, ensuring opacity only impacts the icon
    // Ideally we'd have an SVG symbol so I could change the fill property, but alas...
    &__pager {
        &,
        &::before {
            display: inline-block;
            width: 40px;
            height: 40px;
        }

        &::before {
            content: ' ';
            display: inline-block;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 16px;
        }

        &--previous {
            @include rem(margin-right, 8px);

            &::before {
                @extend .c-icon--arrow-left-sm;
            }
        }

        &--next {
            @include rem(margin-left, 8px);

            &::before {
                @extend .c-icon--arrow-right-sm;
            }
        }

        &--disabled {
            pointer-events: none;

            &::before {
                opacity: 0.5;
            }
        }
    }

    @include mq($from: tablet) {
        float: right;
        justify-content: flex-end;

        &__pager {
            background-color: $color-off-white;
        }
    }
}

.c-pagination__list {
    float: right;
    margin: 0;
}

.c-pagination__pages-items {
    display: inline-block;
    margin: 0;
    @include rem(margin-bottom, 5px);
}

.c-pagination__item {
    display: inline-block;

    @include mq($until: tablet) {
        @include rem(font-size, 12px);
    }

}

.c-pagination__control {
    display: inline-block;
    background-color: $color-off-white;
    @include rem(padding, 6px 10px);

    @include mq($until: tablet) {
        @include rem(padding, 6px);
    }

}

.c-pagination__control--left {
    @include rem(margin-right, 10px);

    @include mq($until: tablet) {
        @include rem(margin-right, 5px);
    }

}

.c-pagination__control--right {
    @include rem(margin-left, 10px);

    @include mq($until: tablet) {
        @include rem(margin-left, 5px);
    }

}

.c-pagination__icon {
    width: 12px;
    height: 8px;

    @include mq($from: tablet) {
        width: 16px;
        height: 10px;
    }
}
