//------------------------------------
// #MODAL EXTEND
//------------------------------------

.modal-popup {
    overflow-y: initial;
}

.modal-slide .modal-header {
    @include rem(padding-bottom, 15px);
    @include rem(padding-top, 15px);
}

.modal-popup .modal-header {
    @include rem(height, 65px);
}

@include mq($until: tablet) {
    .mobile-modal-active {
        //the hidden overflow + 100% height resets the body scroll position. To be tested on all devices
        overflow: inherit !important;
    }
}

.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
    // Override base theme defaults
    border: 0;
    clip: auto;
    height: auto;
    overflow: auto;
    padding: 0;
    position: relative;
    width: auto;
    margin: 0;

    &:last-child {
        // Re-implement defaults for last span (label)
        @include visually-hidden();
    }
}

.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
    // We now use an icon in the template
    content: none;
}
