//------------------------------------
// #REACT LISTINGS
//------------------------------------

.c-listings {
    position: relative;

    .js-react-sticky > div:not(.js-react-sticky-placeholder) {
        top: 0;
        z-index: zIndex('sticky-facet');
        transition: top 0.25s ease-out;
    }
}

.c-listings__item {
    @include rem(font-size, $font-size-zeta);

    border: rem(1px) solid $color-ui-grey-4;
    border: rem(1px) solid var(--color-ui-grey-4);
    height: 100%;
    display: flex;
    flex-direction: column;

    @include mq($from: desktop) {
        border: 0;
    }
}

.c-listings__item-image-link {
    position: relative;
}

.c-listings__item-image {
    width: 100%;
    display: block;
}

.c-listings__item-name,
.c-listings__item-reviews,
.c-listings__item-prices {
    @include rem(padding, $base-spacing-unit-small);

    display: block;
}

.c-listings__item-prices {
    margin-top: auto;
}

.c-listings__item-price {
    @include rem(font-size, $font-size-zeta);

    margin-bottom: 0;
    font-weight: bold;

    @include mq($from: desktop) {
        @include rem(font-size, $font-size-delta);
    }
}
