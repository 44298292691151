//------------------------------------
// #REACT LISTINGS CRUMB
//------------------------------------

.c-facets__crumb,
.c-listings__crumb {
    @include rem(margin-bottom, $base-spacing-unit);
    @include rem(padding, 0 15px);
}

.c-facets__crumb-list,
.c-listings__crumb-list {
    list-style-type: none;
    margin-left: 0;
}

.c-facets__crumb-item,
.c-listings__crumb-item {
    @include rem(margin-bottom, $base-spacing-unit-small);
}

.c-facets__crumb-icon,
.c-listings__crumb-icon {
    @include rem(font-size, $font-size-delta);
    @include rem(margin-right, 5px);

    display: inline-block;
    font-weight: bold;
}
