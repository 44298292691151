//------------------------------------
// #ACCORDION
//------------------------------------

.c-accordion__header {
    &:focus {
        outline: none;
    }

    @include mq($until: tablet) {
        float: none;
    }

    &.ui-accordion-header-active {
        .c-accordion__title .c-icon {
            transform: rotate(180deg);
        }
    }
}

.c-accordion__title {
    position: relative;
    color: $color-off-white;
    font-weight: bold;
    @include rem(padding-top, 10px);
    @include rem(padding-bottom, 10px);
    border-bottom: 1px solid $color-ui-grey-2;

    @include mq($from: tablet) {
        display: none;
    }

    .c-icon {
        position: absolute;
        top: 16px;
        width: 15px;
        height: 10px;
        right: 0;
    }

    & > div:focus {
        outline: none;
    }
}

.c-accordion__content {
    @include mq($until: tablet) {
        @include rem(margin-top, 15px);
        @include rem(margin-bottom, 15px);

        float: none;
    }
}

// Checkout accordion style
.c-accordion--secondary {
    .c-accordion__title {
        font-family: $font-family-secondary;
        color: $color-default-header;
        border-bottom: 0;

        .c-icon {
            position: relative;
            top: auto;
            left: auto;
        }
    }
}
