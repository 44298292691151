//------------------------------------
// #LOAD MORE
//------------------------------------

.c-load-more {
    // Clearfix is included here to keep the functionality modular as we cannot predict where the "load more" will be
    // placed. This ensures the button will be on a new line in most situations
    @include clearfix();

    width: 100%;
    text-align: center;
    display: block;
}

.c-load-more__item {
    animation: load-more-animate linear $base-transition;
    animation-iteration-count: 1;
    will-change: opacity, transform;
}

@keyframes load-more-animate {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
