//------------------------------------
// #TABLES
//------------------------------------

// Basic table styling

table {
    width: 100%;
}

th,
td {
    @include rem(padding, 5px 0);

    text-align: left;
    vertical-align: top;
}

td {
    @include rem(padding, 5px 0);
}
